import { Injectable } from '@angular/core';
import { IDelete } from '@core/interfaces/delete.interface';
import { Extra, IdsExtra, Option } from '@core/models';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { ProductService } from './product.service';
import { v4 as uuidv4 } from 'uuid';
import { ISort, Sort } from '@core/interfaces/sort.interface';

@Injectable({
  providedIn: 'root',
})
export class ExtraService implements IDelete, ISort {
  private _BUSINESS_ID = 'BUSINESS_ID';
  private _SEGMENT = 'businesses/BUSINESS_ID/extras';
  private _KEY = '__e';
  private _CURRENT_EXTRA = '__ecurrent';

  constructor(
    private afsvc: ApiFacadeService,
    private businessSvc: BusinessService,
    private ldsvc: LocalDataService,
    private productSvc: ProductService
  ) {}

  // Base methods
  delete(ids: IdsExtra): Observable<boolean> {
    const baseUrl = this.getBaseUrl(ids.businessId);
    return this.afsvc
      .delete({
        segment: `${baseUrl}/${ids.extraId}`,
        storageOptions: {
          delete: true,
          key: `${this._KEY}${ids.extraId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.productSvc.removeExtraFromProducts(ids.businessId, ids.extraId);
          this.businessSvc.removeExtraFromBusiness(ids.businessId, ids.extraId);
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  sort(extraId: string, options: Sort): Observable<Boolean> {
    const baseUrl = this.getBaseUrl(extraId);
    return this.afsvc
      .patch({
        segment: `${baseUrl}/sort`,
        data: options,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.businessSvc.updateOptionsSortInBusiness(extraId, options.sort);
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  get(extraId: string): Observable<Extra> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .get<Extra>({
        segment: `${baseUrl}/${extraId}`,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${extraId}`,
        },
        useGateway: true,
      })
      .pipe(
        map((extra) => extra),
        catchError((error) => throwError(error))
      );
  }

  save(extra: Extra): Observable<Extra> {
    if (extra.extraId) {
      return this.update(extra);
    } else {
      return this.create(extra);
    }
  }

  // Public methods
  addOptionToExtra(option: Option): void {
    const extra: Extra = this.ldsvc.get<Extra>(`${this._KEY}${option.extraId}`);
    if (!extra.options) {
      extra.options = [];
    }
    extra.options.push(option);
    if (!extra.sort) {
      extra.sort = [];
    }
    extra.sort.push(option.optionId);
    this.ldsvc.set<Extra>(`${this._KEY}${option.extraId}`, extra);
  }

  removeOptionFromExtra(extraId: string, optionId: string): void {
    const extra: Extra = this.ldsvc.get<Extra>(`${this._KEY}${extraId}`);
    const options: Option[] = extra.options.filter(
      (o) => o.optionId !== optionId
    );
    extra.options = [...options];
    const sort: string[] = extra.sort.filter((id) => id !== optionId);
    extra.sort = [...sort];
    this.ldsvc.set<Extra>(`${this._KEY}${extraId}`, extra);
  }

  updateOptionInExtra(option: Option): void {
    const extra: Extra = this.ldsvc.get<Extra>(`${this._KEY}${option.extraId}`);
    const options: Option[] = extra.options.filter(
      (o) => o.optionId !== option.optionId
    );
    extra.options = [...options, option];
    this.ldsvc.set<Extra>(`${this._KEY}${option.extraId}`, extra);
  }

  // Private methods
  private create(extra: Extra): Observable<Extra> {
    const baseUrl = this.getBaseUrl();
    extra.extraId = uuidv4();
    return this.afsvc
      .post<Extra>({ segment: baseUrl, data: extra, useGateway: true })
      .pipe(
        tap(() => {
          this.ldsvc.set<Extra>(`${this._KEY}${extra.extraId}`, extra);
          this.businessSvc.addExtraToBusiness(extra);
        }),
        mapTo(extra),
        catchError((error) => throwError(error))
      );
  }

  private update(extra: Extra): Observable<Extra> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .put<Extra>({
        segment: `${baseUrl}/${extra.extraId}`,
        data: extra,
        storageOptions: {
          search: true,
          save: false,
          key: `${this._KEY}${extra.extraId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.ldsvc.set<Extra>(`${this._KEY}${extra.extraId}`, extra);
          this.businessSvc.updateExtraInBusiness(extra);
        }),
        mapTo(extra),
        catchError((error) => throwError(error))
      );
  }

  private getBaseUrl(businessId?: string): string {
    if (!businessId) {
      businessId = this.businessSvc.getCurrent();
    }
    return this._SEGMENT.replace(this._BUSINESS_ID, businessId);
  }

  saveCurrent = (extraId: string): void =>
    this.ldsvc.set(this._CURRENT_EXTRA, extraId);
  getCurrent = (): string => this.ldsvc.get<string>(this._CURRENT_EXTRA);
}
