<h2 mat-dialog-title>¡Es momento de crear tu Negocio!</h2>
<div mat-dialog-content>
  <p>
    Los datos del negocio ayudan a que los clientes te identifiquen fácilmente.
  </p>
</div>
<div class="col-xs-12 col-md-offset-6 col-md-6 div_button__align">
  <button class="form__button button__start" (click)="closeDialog()">
    Comenzar
  </button>
</div>
