import { Menu } from '@core/models/menu.model';

export class MenuAction {
  static readonly type = '[Menu]';
  constructor(public menu: Menu) {}
}

export class MenuSuccessAction {
  static readonly type = '[Menu] Success';
  constructor(public menu: Menu) {}
}

export class MenuFailedAction {
  static readonly type = '[Menu] Failed';
  constructor(public message: string) {}
}
