import { Extra } from '@core/models/extra.model';

export class ExtraAction {
  static readonly type = '[Extra]';
  constructor(public extra: Extra) {}
}

export class ExtraSuccessAction {
  static readonly type = '[Extra] Success';
  constructor(public extra: Extra) {}
}

export class ExtraFailedAction {
  static readonly type = '[Extra] Failed';
  constructor(public message: string) {}
}
