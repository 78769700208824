export class CustomErrorHandler {
  static get(error: any): string {
    if (error === undefined) return '[ERROR]';

    const type = typeof error;
    if (type === 'string') return error;

    let message: string = '[ERROR]';
    if (error.hasOwnProperty('message')) message = error.message;

    return message;
  }
}
