import { Injectable } from '@angular/core';
import { SecurityService } from '@core/services/security.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  RecoverPassword,
  RecoverPasswordFailed,
  RecoverPasswordSuccess,
} from '../actions/recover-password.actions';

export class RecoverPasswordStateModel {
  public loading: boolean;
  public success: boolean;
  public error: boolean;
  public message: string;
}

const defaults = {
  loading: false,
  success: false,
  error: false,
  message: null,
};

@State<RecoverPasswordStateModel>({
  name: 'recoverPassword',
  defaults,
})
@Injectable()
export class RecoverPasswordState {
  constructor(private securitySvc: SecurityService) {}

  @Selector()
  public static recovering({ loading }) {
    return loading;
  }

  @Selector()
  public static recoveredPassword({ success }) {
    return success;
  }

  @Selector()
  public static recoverPasswordError({ message }) {
    return message;
  }

  @Action(RecoverPassword)
  recoverPassword(
    ctx: StateContext<RecoverPasswordStateModel>,
    { email }: RecoverPassword
  ) {
    this._recovering(ctx);

    this.securitySvc
      .recoverPassword(email)
      .then(() => {
        ctx.dispatch(new RecoverPasswordSuccess());
      })
      .catch((error) => {
        ctx.dispatch(new RecoverPasswordFailed(error));
      });
  }

  @Action(RecoverPasswordSuccess)
  recoverPasswordSuccess(ctx: StateContext<RecoverPasswordStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      success: true,
      error: false,
      message: null,
    });
  }

  @Action(RecoverPasswordFailed)
  recoverPasswordFailed(
    ctx: StateContext<RecoverPasswordStateModel>,
    { message }: RecoverPasswordFailed
  ) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      loading: false,
      success: false,
      error: true,
      message: message,
    });
  }

  private _recovering(ctx: StateContext<RecoverPasswordStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
