import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import {
  GetCategoryAction,
  GetCategoryInitializedAction,
} from '@store/admin/category/actions/get-category.actions';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent extends BreadcrumbComponent {
  categoryId: string;
  menuId: string;
  loading: boolean = false;
  showTabs: boolean = false;
  titles: string[] = ['Categoría', 'Productos'];

  constructor(public activatedRoute: ActivatedRoute, public store: Store) {
    super(activatedRoute, store);
  }

  onInit(): void {
    this.store.dispatch(new GetCategoryInitializedAction());

    if (this.paramMenu) {
      this.menuId = this.paramMenu;
    }

    if (this.paramCategory) {
      this.categoryId = this.paramCategory;
      this.showTabs = true;

      this.store.dispatch(new GetCategoryAction(this.categoryId));
    }
  }

  initializeBreadcrumb(): void {
    if (this.paramCategory) {
      this.breadcrumb = {
        name: 'Categoría',
        icon: 'category',
        url: `b/${this.paramBusiness}/m/${this.menuId}/c/${this.categoryId}`,
        position: 2,
      } as Breadcrumb;
    } else {
      this.breadcrumb = {
        name: 'Categoría',
        icon: 'category',
        url: `b/${this.paramBusiness}/m/${this.menuId}/category`,
        position: 2,
      } as Breadcrumb;
    }
  }
}
