<div class="row middle-xs">
  <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10">
    <mat-form-field>
      <mat-label>Templates</mat-label>
      <mat-select #messaje (selectionChange)="select($event.value)">
        <mat-option *ngFor="let m of messages" [value]="m.messageId">
          {{ m.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
    <button
      mat-raised-button
      class="form__button"
      color="primary"
      (click)="showDialogToCreateTemplate()"
    >
      Crear template
    </button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12" *ngIf="messaje.value === '#createMessage'">
    <mat-form-field>
      <mat-label>Título del mensaje</mat-label>
      <input
        matInput
        (input)="updateTitle($event.target.value)"
        placeholder="Título del mensaje"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12">
    <mat-form-field>
      <mat-label>Mensaje</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        readonly
        placeholder="Increibles promociones..."
        [value]="currentMessage?.message"
        (input)="updateMessage($event.target.value)"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>
