import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { environment } from 'environments/environment';

import { SaveBusinessState } from './admin/business/states/save-business.state';
import { GetBusinessState } from './admin/business/states/get-business.state';
import { CategoryState } from './admin/category/states/category.state';
import { GetCategoryState } from './admin/category/states/get-category.state';
import { ExtraState } from './admin/extra/states/extra.state';
import { GetExtraState } from './admin/extra/states/get-extra.state';
import { GetMenuState } from './admin/menu/states/get-menu.state';
import { MenuState } from './admin/menu/states/menu.state';
import { GetOptionState } from './admin/option/states/get-option.state';
import { OptionState } from './admin/option/states/option.state';
import { AddExtraState } from './admin/product/states/add-extra.state';
import { GetProductState } from './admin/product/states/get-product.state';
import { ProductState } from './admin/product/states/product.state';
import { GetProfileState } from './admin/profile/states/get-profile.state';
import { SaveProfileState } from './admin/profile/states/save-profile.state';
import { UserState } from './admin/users/states/user.state';
import { UsersState } from './admin/users/states/users.state';
import { RecoverPasswordState } from './authentication/states/recover-password.state';
import { SignInState } from './authentication/states/sign-in.state';
import { SignUpState } from './authentication/states/sign-up.state';
import { ContactState } from './public/states/contact.state';
import { BreadcrumbState } from './admin/breadcrumb/states/breadcrumb.state';
import { EraserState } from './admin/eraser/states/eraser.state';
import { StripeRelationshipState } from './admin/stripe/states/relationship-stripe.state';
import { LoginLinkStripeState } from './admin/stripe/states/login-link-stripe.state';
import { CustomerState } from './admin/customer/customer.state';
import { MessageTemplateState } from './admin/message-template/message-template.state';
import { AnalyticsState } from './admin/analytics/states/analytics.states';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        BreadcrumbState,
        AddExtraState,
        EraserState,
        SaveBusinessState,
        CategoryState,
        ExtraState,
        GetBusinessState,
        GetCategoryState,
        GetExtraState,
        GetMenuState,
        GetOptionState,
        GetProductState,
        GetProfileState,
        MenuState,
        OptionState,
        SaveProfileState,
        ProductState,
        RecoverPasswordState,
        SignInState,
        SignUpState,
        ContactState,
        UsersState,
        UserState,
        StripeRelationshipState,
        LoginLinkStripeState,
        CustomerState,
        MessageTemplateState,
        AnalyticsState
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsResetPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
})
export class StateModule {}
