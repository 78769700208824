<app-auth-container>
    <div class="content-form__wrapper" content>
        <h2>Registrate</h2>
        <ng-container *ngIf="!loading; then signUpForm else signUpLoading"></ng-container>
    </div>
</app-auth-container>

<ng-template #signUpForm>
    <form [formGroup]="formGroup" (ngSubmit)="onSignUp()">
        <div class="row center-xs">
            <div class="content-form__form">
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" name="email" placeholder="email@dominio.com" required>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Contraseña</mat-label>
                        <input matInput type="password" formControlName="password" name="password" placeholder="******" required>
                    </mat-form-field>
                </div>
                <div class="content-form__col-link col-xs-12">
                    <a [routerLink]="" (click)="onOpenTermsAndConditions()">Leer términos y condiciones</a>
                </div>
                <div class="content-form__col-link col-xs-12">
                    <mat-checkbox formControlName="termsAndConditions" color="primary" required>
                        Acepto términos y condiciones.
                    </mat-checkbox>
                </div>
                <div class="col-xs-12">
                    <button type="submit" class="form__button" mat-raised-button [disabled]="formGroup.invalid">Registrate</button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #signUpLoading>
    <app-loading message="Registrando usuario..."></app-loading>
</ng-template>
