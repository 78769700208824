import { Component, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Business } from '@core/models/business.model';
import { StepItem } from '@core/models/step-item.model';
import { Waiter } from '@core/models/waiter.model';
import { Select, Store } from '@ngxs/store';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { GetBusinessAction } from '@store/admin/business/actions/get-business.actions';
import { GetBusinessState } from '@store/admin/business/states/get-business.state';
import { GetProfileAction } from '@store/admin/profile/actions/get-profile.actions';
import { GetProfileState } from '@store/admin/profile/states/get-profile.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  @Select(GetBusinessState.inProgress) loading$: Observable<boolean>;
  @Select(GetProfileState.getBusiness) business$: Observable<Business>;
  @Select(GetBusinessState.getUsers) users$: Observable<Waiter[]>;

  private loadingSubs: Subscription;
  private businessSubs: Subscription;
  private resultSubs: Subscription;

  public businessId: string;
  public breadcrumb: Breadcrumb;
  buttonUrl: string;
  loading: boolean;
  users: Waiter[];

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.loadingSubs.unsubscribe();
    this.businessSubs.unsubscribe();
    this.resultSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.breadcrumb = {
      name: 'Usuarios',
      icon: 'groups',
      url: 'users',
      position: 0,
    } as Breadcrumb;

    this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));
    this.store.dispatch(new GetProfileAction());
    this.loadingSubs = this.loading$.subscribe((loading) => {
      this.loading = loading ?? false;
    });
    this.businessSubs = this.business$.subscribe((business) => {
      if (!business || !business.businessId) return;
      this.businessId = business.businessId;

      if (!business.active) {
        this.businessId = undefined;
        return;
      }

      this.buttonUrl = `${this.businessId}/user`;
      this.store.dispatch(new GetBusinessAction(this.businessId));
    });
    this.resultSubs = this.users$.subscribe((result) => {
      if (!result) return;
      this.users = result;
    });
  }
}
