import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { CustomerActions } from './customer.actions';
import { CustomerService } from '@core/https/admin/customer.service';
import { BusinessService } from '@core/https/admin/business.service';
import { catchError, tap } from 'rxjs/operators';
import { Customer } from '@core/models/customer.model';
import { of } from 'rxjs';

export class CustomerStateModel {
  public customers: Customer[];
  public message: string;
}

const defaults = {
  customers: null,
  message: null,
};

@State<CustomerStateModel>({
  name: 'customer',
  defaults,
})
@Injectable()
export class CustomerState {
  constructor(
    private customerService: CustomerService,
    private businessService: BusinessService
  ) {}

  @Selector()
  public static getCustomers({ customers }) {
    return customers;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(CustomerActions.Get)
  getCustomers({ dispatch }: StateContext<CustomerStateModel>) {
    const businessId = this.businessService.getCurrent();
    return this.customerService.get(businessId).pipe(
      tap((customers) => {
        dispatch(new CustomerActions.Success(customers));
      }),
      catchError((error) => {
        dispatch(new CustomerActions.Failure(error.message));
        return of();
      })
    );
  }

  @Action(CustomerActions.Success)
  getCustomersSuccess(
    { patchState }: StateContext<CustomerStateModel>,
    { customers }: CustomerActions.Success
  ) {
    patchState({
      customers,
      message: null,
    });
  }

  @Action(CustomerActions.Failure)
  getCustomersFailed(
    { patchState }: StateContext<CustomerStateModel>,
    { message }: CustomerActions.Failure
  ) {
    patchState({
      customers: null,
      message: message,
    });
  }
}
