import { Injectable } from '@angular/core';
import { MenuService } from '@core/https/admin/menu.service';
import { FormResultMenu } from '@core/models/menu.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  MenuAction,
  MenuFailedAction,
  MenuSuccessAction,
} from '../actions/menu.actions';

export class MenuStateModel {
  public loading: boolean;
  public result: FormResultMenu;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    menu: null,
  } as FormResultMenu,
};

@State<MenuStateModel>({
  name: 'menu',
  defaults,
})
@Injectable()
export class MenuState {
  private _successMessage = 'Tu menú fue guardado con éxito.';

  constructor(private menuSvc: MenuService) {}

  @Selector()
  public static saving({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(MenuAction)
  save(ctx: StateContext<MenuStateModel>, { menu }: MenuAction) {
    this._saving(ctx);
    return this.menuSvc.save(menu).pipe(
      tap((newMenu) => {
        ctx.dispatch(new MenuSuccessAction(newMenu));
      }),
      catchError((error) => {
        ctx.dispatch(new MenuFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(MenuSuccessAction)
  saveSuccess(ctx: StateContext<MenuStateModel>, { menu }: MenuSuccessAction) {
    const state = ctx.getState();

    const result: FormResultMenu = {
      error: false,
      success: true,
      message: this._successMessage,
      menu: menu,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(MenuFailedAction)
  saveFailed(ctx: StateContext<MenuStateModel>, { message }: MenuFailedAction) {
    const state = ctx.getState();

    const result: FormResultMenu = {
      error: true,
      success: false,
      message: message,
      menu: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private _saving(ctx: StateContext<MenuStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
