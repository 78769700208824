import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import {
  Router,
  UrlSegment,
  CanLoad,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SecurityService } from '@core/services/security.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private securitySvc: SecurityService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.securitySvc.isAuthenticated().pipe(
      map((isAuth) => {
        if (isAuth && segments[0].path === 'admin') {
          return true;
        } else if (isAuth && segments[0].path === 'auth') {
          this.router.navigate(['admin/profile']);
          return true;
        } else if (!isAuth && segments[0].path === 'auth') {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
      first()
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const segments: UrlSegment[] = route.url;
    return this.securitySvc.isAuthenticated().pipe(
      map((isAuth) => {
        if (isAuth && segments[0].path === 'admin') {
          return true;
        } else if (isAuth && segments[0].path === 'auth') {
          this.router.navigate(['admin/profile']);
          return true;
        } else if (!isAuth && segments[0].path === 'auth') {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
      first()
    );
  }
}
