import { Pipe, PipeTransform } from '@angular/core';
import { Extra } from '@core/models/extra.model';
import { ListItem } from '@core/models/list-item.model';
import { ParamsModel } from '../core/params.model';

@Pipe({
  name: 'fromExtrasToListItem',
})
export class FromExtrasToListItemPipe implements PipeTransform {
  transform(extras: Extra[], params: ParamsModel): ListItem[] {
    if (extras) {
      return extras.map((extra) => {
        return {
          id: extra.extraId,
          text: extra.name,
          url: params.productId
            ? `b/${params.businessId}/m/${params.menuId}/c/${params.categoryId}/p/${params.productId}/e/${extra.extraId}`
            : `b/${params.businessId}/e/${extra.extraId}`,
          active: extra.active,
        } as ListItem;
      });
    }

    return null;
  }
}
