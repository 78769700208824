<section content1>
    <div class="content-form__wrapper">
        <ng-container *ngIf="!loading; then productForm else productLoading"></ng-container>
    </div>
</section>

<ng-template #productForm>
    <div class="row--width">
        <app-eraser
          buttonText="Eliminar producto"
          question="¿Estas seguro que quieres eliminar este producto?"
          eraserType="PRODUCT"
          [implDelete]="productSvc"
          [show]="showEraserComponent">
        </app-eraser>
        <form [formGroup]="formGroup" (submit)="onSave()">
            <div class="row center-xs">
                <div class="col-xs-12 col-md-12 col-lg-4 product__content--margin">
                    <app-upload-image
                        [image]="product ? product.urlImage : image"
                        (loadImage)="onLoadImage($event)">
                    </app-upload-image>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-8 product__content--margin">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input matInput maxlength="100" formControlName="name" name="name" placeholder="Nombre del producto" #inputName required>
                        <mat-hint align="end">{{inputName.value?.length || 0}}/100</mat-hint>
                    </mat-form-field>
                    <mat-checkbox class="form__checkbox" formControlName="active" name="active" color="primary">Activo</mat-checkbox>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Código</mat-label>
                        <input matInput maxlength="20" formControlName="code" name="code" placeholder="Código del producto"#inputCode required>
                        <mat-hint align="end">{{inputCode.value?.length || 0}}/20</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput maxlength="300" formControlName="description" name="description" placeholder="Descripción del producto" #inputDescription></textarea>
                        <mat-hint align="end">{{inputDescription.value?.length || 0}}/300</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input type="number" matInput formControlName="price" name="price" placeholder="Precio" required #inputPrice>
                        <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-xs-12 product__content--margin">
                    Este producto cuenta con alguna promoción
                    <mat-checkbox class="form__checkbox" formControlName="discount" name="discount" color="primary" #checkDiscount>Activar Promoción</mat-checkbox>
                </div>
                <div class="col-xs-12 product__content--margin" [hidden]="!checkDiscount.checked">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Tipo de promoción</mat-label>
                        <mat-select [formControl]="promotionTypesControl" name="promotionType" #selectPromotionType>
                            <mat-option *ngFor="let promotionType of promotionTypes" [value]="promotionType.id">
                              {{promotionType.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-xs-12 product__content--margin" [hidden]="selectPromotionType.value !== 'DATE'">
                        <mat-form-field class="form__field" appearance="outline">
                            <mat-label>Fecha Inicio de Vigencia</mat-label>
                            <input readonly matInput formControlName="dateStart" name="dateStart" [matDatepicker]="picker_start_date">
                            <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_start_date></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="form__field" appearance="outline">
                            <mat-label>Fecha Fin de Vigencia</mat-label>
                            <input readonly matInput formControlName="dateEnd" name="dateEnd" [matDatepicker]="picker_end_date">
                            <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_end_date></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-xs-12 product__content--margin" [hidden]="selectPromotionType.value !== 'DAYS'">
                        <mat-form-field class="form__field" appearance="outline">
                            <mat-label>Selecciona los días validos</mat-label>
                            <mat-select [formControl]="discountDaysControl" name="days" multiple>
                                <mat-option *ngFor="let discountDay of discountDays" [value]="discountDay.id">
                                    {{discountDay.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Tipo de descuento</mat-label>
                        <mat-select [formControl]="discountTypesControl" name="discountType" #selectDiscountType>
                            <mat-option *ngFor="let discountType of discountTypes" [value]="discountType.id">
                              {{discountType.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline"
                        [hidden]="selectDiscountType.value !== 'PERCENTAGE' && selectDiscountType.value !== 'PRICE'">
                        <mat-label>Porcentaje de descuento</mat-label>
                        <input type="number" matInput min="0" [max]="selectDiscountType.value === 'PERCENTAGE' ? 100 : 99999" formControlName="discountValue" name="discountValue" placeholder="Porcentaje" #inputDiscount>
                        <span matSuffix [hidden]="selectDiscountType.value !== 'PERCENTAGE'">&nbsp;%</span>
                        <span matPrefix [hidden]="selectDiscountType.value !== 'PRICE'">$&nbsp;</span>
                    </mat-form-field>
                    <br >
                    <p [hidden]="selectDiscountType.value !== 'PERCENTAGE'">
                        El precio con descuento es: <b>
                            {{ (inputPrice.value) - (inputPrice.value * inputDiscount.value / 100) | currency}}</b>
                    </p>
                </div>
                <div class="col-xs-12">
                    <button type="submit" class="form__button" mat-raised-button [disabled]="formGroup.invalid">Guardar</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #productLoading>
    <app-loading message="Guardando..."></app-loading>
</ng-template>
