<app-admin-conteiner>
    <section content>
        <app-admin-title *ngIf="breadcrumb"></app-admin-title>
        <div class="content-form__wrapper">
            <ng-container *ngIf="!loading; then reportsForm else reportsLoading"></ng-container>
        </div>
    </section>
</app-admin-conteiner>

<ng-template #reportsForm>
    <div class="row--width">
        <div class="row center-xs">
            <div class="col-xs-12 col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Elige una fecha</mat-label>
                    <input matInput [matDatepicker]="date">
                    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                  </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
                <button type="submit" class="form__button" mat-raised-button>Descargar</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reportsLoading>
    <app-loading message="Guardando..."></app-loading>
</ng-template>