import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarHelper {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.show(message, ['success-snackbar']);
  }

  showError(message: string): void {
    this.show(message, ['error-snackbar']);
  }

  private show(message: string, classes: string[]): void {
    this.snackBar.open(message, 'X', {
      duration: 3500,
      verticalPosition: 'top',
      panelClass: classes,
    });
  }
}
