<app-admin-list
  buttonText="Agregar producto"
  [buttonUrl]="buttonUrl"
  description="Selecciona uno de tus productos para acceder a sus detalles."
  icon="lunch_dining"
  [list]="products | fromProductsToListItem : sort : params"
  noDataText="Todavía no tienes productos agregados."
  [implSort]="categoryService"
  [id]="categoryId"
>
</app-admin-list>
