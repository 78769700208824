import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PublicRoutingModule } from './public-routing.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { PublicContainerComponent } from './components/public-container/public-container.component';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';

@NgModule({
  declarations: [
    WelcomeComponent,
    PublicContainerComponent,
    AboutUsComponent,
    ContactComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PublicRoutingModule,
    MaterialModule,
    SharedModule,
  ],
})
export class PublicModule {}
