import { AddExtraDto } from '@core/models/extra.model';
import { Product } from '@core/models/product.model';

export class AddExtraAction {
    static readonly type = '[AddExtra]';
    constructor(public extra: AddExtraDto){}
}

export class AddExtraSuccessAction {
    static readonly type = '[AddExtra] Success';
    constructor(public product: Product){}
}

export class AddExtraFailedAction {
    static readonly type = '[AddExtra] Failed';
    constructor(public message: string) {}
}