<section content1>
  <div class="content-form__wrapper">
    <ng-container
      *ngIf="!loading; then businessPage; else businessLoading"
    ></ng-container>
  </div>
  <br />
</section>

<ng-template #businessPage>
  <ng-container
    *ngIf="errorMessage; then businessError; else businessForm"
  ></ng-container>
</ng-template>

<ng-template #businessError>
  <app-error-card [errorMesage]="errorMessage"></app-error-card>
</ng-template>

<ng-template #businessForm>
  <div class="row--width">
    <form [formGroup]="formGroup" (ngSubmit)="onSave()">
      <div class="row center-xs">
        <div class="col-xs-12 col-md-4" *ngIf="business?.active">
          <div class="row center-xs">
            <div class="col-xs-12 business-form__col-qrcode">
              <qrcode
                [qrdata]="qrData"
                [width]="256"
                [errorCorrectionLevel]="'M'"
                [elementType]="'svg'"
              ></qrcode>
            </div>
            <div class="col-xs-12 business-form__col-qr" *ngIf="business.qr">
              <a
                class="business-form__link"
                [href]="business.qr"
                target="_blank"
                rel="noopener"
                >Descargar QR</a
              >
            </div>
            <div class="col-xs-12 business-form__col-qr">
              <div class="row center-xs">
                <div class="col-xs-12">
                  <span
                    >Esta es la URL de tu tienda en línea, copia y comparte este
                    enlace con tus clientes para que puedan ver tus
                    productos.</span
                  >
                  <p>
                    <a
                      class="business-form__link"
                      [href]="qrData"
                      target="_blank"
                      rel="noopener"
                      >{{ qrData }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" [ngClass]="business?.active ? 'col-md-8' : ''">
          <p>
            <mat-slide-toggle
              class="form__field"
              formControlName="isOpen"
              name="isOpen"
              color="primary"
              *ngIf="business?.active"
              appearance="outline"
            >
              El negocio está abierto
            </mat-slide-toggle>
          </p>
          <p *ngIf="business?.stripeUserId">
            <mat-checkbox
              class="form__field"
              formControlName="allowPayments"
              name="allowPayments"
              color="primary"
              *ngIf="business?.stripeUserId"
              appearance="outline"
            >
              Permitir pago con tarjeta
            </mat-checkbox>
          </p>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Nombre del negocio</mat-label>
            <input
              matInput
              formControlName="name"
              name="name"
              placeholder="Nombre del negocio"
              required
            />
          </mat-form-field>
          <div
            class="nickname__rules"
            *ngIf="business === undefined ? true : false"
          >
            <b>Selección del Nickname para la URL</b>
            <dl>
              <dt class="dt__title_first"><b>Importante:</b></dt>
              <dd>
                - Elige con atención el Nickname, ya que este será el nombre que
                llevará la página web que crearemos para ti y donde tus clientes
                accederán para ver todos tus menús.
              </dd>
              <dt class="dt__title"><b>Consejos:</b></dt>
              <dd>- Escoge algo fácil de escribir y recordar.</dd>
              <dd>
                - Sólo se permiten números y letras, sin espacios ni caracteres
                especiales.
              </dd>

              <dt class="dt__title"><b>Ejemplos:</b></dt>
              <dd>- LaPizzeria</dd>
              <dd>- DonTaco</dd>
              <dd>- CafeLatte123</dd>
              <dd>- SushiMaster</dd>
              <dd>- GrillHouse</dd>
            </dl>
          </div>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Nickname de la URL</mat-label>
            <input
              matInput
              formControlName="key"
              name="key"
              #key
              placeholder="Coloca un nickname para tu negocio, este será utilizado para tu URL"
              [errorStateMatcher]="matcher"
              autocomplete="off"
            />
            <mat-hint *ngIf="!business?.active"
              >Ejemplo de tu URL: {{ urlQr }}/<strong>{{
                key.value
              }}</strong></mat-hint
            >
            <mat-error *ngIf="formGroup.controls['key'].hasError('pattern')">
              Sólo se permiten números y letras, sin espacios o caracteres
              especiales.
            </mat-error>
            <mat-error *ngIf="formGroup.controls['key'].hasError('minlength')">
              El tamaño mínimo es de 3.
            </mat-error>
            <mat-error *ngIf="formGroup.controls['key'].hasError('maxlength')">
              El tamaño máximo es de 20.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>País</mat-label>
            <mat-select [formControl]="countryControl" name="country">
              <mat-option
                *ngFor="let country of countries"
                [value]="country.code"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Estado</mat-label>
            <input
              matInput
              formControlName="state"
              name="state"
              placeholder="Estado"
              required
            />
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Delegación o Municipio</mat-label>
            <input
              matInput
              formControlName="city"
              name="city"
              placeholder="Delegación/Municipio"
              required
            />
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Colonia</mat-label>
            <input
              matInput
              formControlName="neighborhood"
              name="neighborhood"
              placeholder="Colonia/Fraccionamineto"
              required
            />
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Calle/Numero</mat-label>
            <input
              matInput
              formControlName="street"
              name="street"
              placeholder="Calle/#"
            />
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Código Postal</mat-label>
            <input matInput formControlName="zip" name="zip" placeholder="CP" />
          </mat-form-field>
          <div class="col-xs-12" style="padding: 10px"></div>
          <div *ngIf="!business">
            <button
              type="submit"
              class="form__button"
              mat-raised-button
              [disabled]="formGroup.invalid"
            >
              Guardar
            </button>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="business">
          <ng-container
            *ngIf="
              business?.active;
              then businessButtonSave;
              else businessValidating
            "
          ></ng-container>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #businessValidating>
  <app-business-validating></app-business-validating>
</ng-template>

<ng-template #businessButtonSave>
  <button
    type="submit"
    class="form__button"
    mat-raised-button
    [disabled]="formGroup.invalid"
  >
    Guardar
  </button>
</ng-template>

<ng-template #businessLoading>
  <app-loading [message]="loadingMessage"></app-loading>
</ng-template>
