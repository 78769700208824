<app-admin-conteiner>
  <section content>
    <app-admin-title *ngIf="breadcrumb"></app-admin-title>
    <ng-container
      *ngIf="!loading; then usersContainer; else usersLoading"
    ></ng-container>
  </section>
</app-admin-conteiner>

<ng-template #usersContainer>
  <div class="row--width">
    <ng-container
      *ngIf="businessId; then usersList; else withoutBusiness"
    ></ng-container>
  </div>
</ng-template>

<ng-template #withoutBusiness>
  <h3>Antes de agregar usuarios, debes de dar de alta tu negocio.</h3>
</ng-template>

<ng-template #usersList>
  <app-admin-list
    buttonText="Agregar usuario"
    description="Selecciona un usuario"
    icon="account_circle"
    [list]="users | fromWaitersToListItem : this.businessId"
    noDataText="Todavía no tienes usuarios registrados."
    [buttonUrl]="buttonUrl"
  >
  </app-admin-list>
</ng-template>

<ng-template #usersLoading>
  <app-loading message="Cargando..."></app-loading>
</ng-template>
