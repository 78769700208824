import { Injectable } from '@angular/core';
import { ProductService } from '@core/https/admin/product.service';
import { FormResultProduct } from '@core/models/product.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ProductAction, ProductFailedAction, ProductSuccessAction } from '../actions/product.actions';

export class ProductStateModel {
  public loading: boolean;
  public result: FormResultProduct;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    product: null,
  } as FormResultProduct,
};

@State<ProductStateModel>({
  name: 'product',
  defaults,
})
@Injectable()
export class ProductState {
  private _successMessage = 'Tu producto fue guardado con éxito.';

  constructor(private productSvc: ProductService) {}

  @Selector()
  public static saving({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(ProductAction)
  save(ctx: StateContext<ProductStateModel>, { product }: ProductAction) {
    this._saving(ctx);

    return this.productSvc.save(product).pipe(
      tap((newProduct) => {
        ctx.dispatch(new ProductSuccessAction(newProduct));
      }),
      catchError((error) => {
        ctx.dispatch(new ProductFailedAction(error));
        return of();
      })
    );
  }

  @Action(ProductSuccessAction)
  saveSuccess(
    ctx: StateContext<ProductStateModel>,
    { product }: ProductSuccessAction
  ) {
    const state = ctx.getState();

    const result: FormResultProduct = {
      error: false,
      success: true,
      message: this._successMessage,
      product: product,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(ProductFailedAction)
  saveFailed(
    ctx: StateContext<ProductStateModel>,
    { message }: ProductFailedAction
  ) {
    const state = ctx.getState();

    const result: FormResultProduct = {
      error: true,
      success: false,
      message: message,
      product: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private _saving(ctx: StateContext<ProductStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true, result: null });
  }
}