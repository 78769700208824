import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';
import { AnalyticsState } from '@store/admin/analytics/states/analytics.states';
import { AnalyticsActions } from '@store/admin/analytics/actions/analytics.actions';
import {
  Breadcrumb,
  OrdersRank,
  ProductRank,
  Rank,
  RankGraphData,
  RankWithCount,
} from '@core/models';
import { AnalyticsService, ChartData } from './analytics.service';
import { PieChartData } from 'app/admin/components/pie-chart/pie-chart.component';
import { ChartPoint } from 'app/admin/components/line-chart/line-chart.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent extends BreadcrumbComponent {
  @Select(AnalyticsState.getData) getAccesses$: Observable<{
    accesses: Rank[];
    orders: RankWithCount[];
    productsAdded: ProductRank[];
    productsViewed: ProductRank[];
    productsRequested: ProductRank[];
    ordersPerDay: OrdersRank;
  }>;

  public loading: boolean = true;
  public accessesGraphData = new RankGraphData();
  public ordersPerDayGraphData = new RankGraphData();
  public ordersChartData: ChartData;
  public productsAddedData: PieChartData;
  public productsViewedData: PieChartData;
  public productsRequestedData: PieChartData;
  public ordersPerDay: OrdersRank;

  constructor(
    public route: ActivatedRoute,
    public store: Store,
    private readonly analyticsService: AnalyticsService
  ) {
    super(route, store);
  }

  onInit(): void {
    this.getAccesses$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (!data) {
        return;
      }
      const {
        accesses,
        orders,
        productsAdded,
        productsViewed,
        productsRequested,
        ordersPerDay,
      } = data;
      this.ordersPerDay = ordersPerDay;

      if (
        accesses &&
        orders &&
        productsAdded &&
        productsRequested &&
        productsViewed
      ) {
        this.accessesGraphData = this.formatRankDataForGraph(accesses);
        this.ordersChartData = this.analyticsService.getOrdersData(orders);
        this.productsAddedData =
          this.analyticsService.getProductsData(productsAdded);
        this.productsViewedData =
          this.analyticsService.getProductsData(productsViewed);
        this.productsRequestedData =
          this.analyticsService.getProductsData(productsRequested);
        this.ordersPerDayGraphData = this.analyticsService.getOrdersPerDayData(
          ordersPerDay?.orders || []
        );
      }
      this.loading = false;
    });

    this.store.dispatch(new AnalyticsActions.GetData());
  }

  onSelectPoint(point: ChartPoint) {
    console.info(point);
  }

  initializeBreadcrumb(): void {
    this.breadcrumb = {
      name: 'Analytics',
      icon: 'bar_chart',
      url: 'analytics',
      position: 0,
    } as Breadcrumb;
  }

  private formatRankDataForGraph(ranks: Rank[]) {
    const ranksCopy = [...ranks];
    ranksCopy.sort((a, b) => {
      const dateA = new Date(a.lapse + '-01').getTime();
      const dateB = new Date(b.lapse + '-01').getTime();
      return dateA - dateB;
    });

    const lapses: string[] = [];
    const totals: number[] = [];
    ranksCopy.forEach((rank) => {
      lapses.push(rank.lapse);
      totals.push(rank.total);
    });
    return { lapses, totals };
  }
}
