import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() isExtendedToolbar: boolean = false;

  constructor(private router: Router) {}

  onGoToWelcome(): void {
    this.router.navigate(['']);
  }

  onGoToAboutUs(): void {
    location.href = environment.landing;
  }

  onGoToContact(): void {
    this.router.navigate(['contact']);
  }

  onGoToSignIn(): void {
    this.router.navigate(['auth/signin']);
  }
}
