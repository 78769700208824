import { Injectable } from '@angular/core';import { CategoryService } from '@core/https/admin/category.service';
import { FormResultCategory } from '@core/models/category.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CategoryAction, CategoryFailedAction, CategorySuccessAction } from '../actions/category.actions';

export class CategoryStateModel {
  public loading: boolean;
  public result: FormResultCategory;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    category: null,
  } as FormResultCategory,
};

@State<CategoryStateModel>({
  name: 'category',
  defaults,
})
@Injectable()
export class CategoryState {
  private _successMessage = 'Tu categoría fue guardada con éxito.';

  constructor(private categorySvc: CategoryService) {}

  @Selector()
  public static saving({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(CategoryAction)
  save(ctx: StateContext<CategoryStateModel>, { category }: CategoryAction) {
    this._saving(ctx);

    return this.categorySvc.save(category).pipe(
      tap((newCategory) => {
        ctx.dispatch(new CategorySuccessAction(newCategory));
      }),
      catchError((error) => {
        ctx.dispatch(new CategoryFailedAction(error));
        return of();
      })
    );
  }

  @Action(CategorySuccessAction)
  saveSuccess(
    ctx: StateContext<CategoryStateModel>,
    { category }: CategorySuccessAction
  ) {
    const state = ctx.getState();

    const result: FormResultCategory = {
      error: false,
      success: true,
      message: this._successMessage,
      category: category,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(CategoryFailedAction)
  saveFailed(
    ctx: StateContext<CategoryStateModel>,
    { message }: CategoryFailedAction
  ) {
    const state = ctx.getState();

    const result: FormResultCategory = {
      error: true,
      success: false,
      message: message,
      category: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private _saving(ctx: StateContext<CategoryStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true, result: null });
  }
}