import { Injectable } from '@angular/core';
import { CallApiService } from '@core/services';

import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private _SEGMENT = 'stripe';

  constructor(private callApiSvc: CallApiService) {}

  addStripeCodeAndBusinessIdRelationship({
    businessId,
    stripeCode,
  }: {
    businessId: string;
    stripeCode: string;
  }): Observable<boolean> {
    return this.callApiSvc
      .post<boolean>({
        urlSegment: `${this._SEGMENT}/account`,
        data: { businessId, stripeCode },
        useGateway: true,
      })
      .pipe(
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  getLoginLink(stripeUserId: string): Observable<string> {
    return this.callApiSvc
      .getText({
        urlSegment: `${this._SEGMENT}/link/${stripeUserId}`,
        useGateway: true,
      })
      .pipe(
        map((url) => url),
        catchError((error) => throwError(error))
      );
  }
}
