<div class="chart-container">
  <canvas
    [id]="yAxisDataLabel"
    baseChart
    [datasets]="lineChartData"
    [labels]="xAxisLabels"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
    [colors]="barChartColors"
    (chartClick)="onChartClick($event)"
  >
  </canvas>
</div>
