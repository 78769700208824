<section content1>
    <div class="content-form__wrapper">
        <ng-container *ngIf="loading; then contactLoading else contactForm"></ng-container>
    </div>
</section>

<ng-template #contactForm>
    <div class="row--width">
    <form [formGroup]="formGroup" (ngSubmit)="onSend()">
        <div class="row center-xs">
            <div class="content-form__form">
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" name="name" maxlength="30" placeholder="Mi nombre completo" #inputName required>
                        <mat-hint align="end">{{ inputName.value?.length || 0 }}/30</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" maxlength="50" name="email" placeholder="email@dominio.com" #inputEmail required>
                        <mat-hint align="end">{{ inputEmail.value?.length || 0 }}/50</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Teléfono</mat-label>
                        <input matInput type="tel" maxlength="10" formControlName="phone" name="phone" #inputPhone>
                        <mat-hint align="end">{{ inputPhone.value?.length || 0 }}/10</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>¿Por que nos contactas?</mat-label>
                        <mat-select [formControl]="subjectControl" name="subject" required>
                            <mat-option *ngFor="let option of options" [value]="option.value">
                                {{ option.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput formControlName="description" name="description" maxlength="300" placeholder="Comentario" #inputDescription required></textarea>
                        <mat-hint align="end">{{ inputDescription.value?.length || 0 }}/300</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-xs-12">
                    <button type="submit" class="form__button" mat-raised-button [disabled]="formGroup.invalid">Enviar</button>
                </div>
                <div class="spacer"></div>
            </div>
        </div>
    </form>
</div>
</ng-template>

<ng-template #contactLoading>
    <app-loading message="Guardando..."></app-loading>
</ng-template>