import { Waiter } from '@core/models/waiter.model';


export class UsersLoadAction {
    static readonly type = '[Users] Load';
    constructor(public idBusiness: string) {}
}

export class UsersLoadSuccessAction {
    static readonly type = '[Users] Load success';
    constructor(public waiters: Waiter[]) {}
}

export class UsersLoadFailureAction {
    static readonly type = '[Users] Load failure';
    constructor(public message: string) {}
}