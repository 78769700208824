<h1 mat-dialog-title>Productos de la orden</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="tableData" class="full-width">
    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="center-text">Producto</th>
      <td mat-cell *matCellDef="let element" class="center-text">
        {{ element.name }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="center-text">
        <strong>Total Orden ($)</strong>
      </td>
    </ng-container>

    <!-- Quantity -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef class="center-text">
        Total Unidades
      </th>
      <td mat-cell *matCellDef="let element" class="center-text">
        {{ element.units }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Price -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="center-text">Total ($)</th>
      <td mat-cell *matCellDef="let element" class="center-text">
        {{ element.total | currency }}
      </td>
      <td colspan="2" mat-footer-cell *matFooterCellDef class="center-text">
        <strong>{{ total | currency }}</strong>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Cerrar</button>
</div>
