import { Injectable } from '@angular/core';
import { Waiter } from '@core/models/waiter.model';
import { ApiFacadeService } from '@core/services/api-facade.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BusinessService } from './business.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _SEGMENT = 'admin/api/waiters';

  constructor(
    private afsvc: ApiFacadeService,
    private businessSvc: BusinessService
  ) {}

  // Base methods

  getMany(businessId: string): Observable<Waiter[]> {
    return this.afsvc
      .getMany<Waiter>({ segment: `${this._SEGMENT}/${businessId}` })
      .pipe(
        map((waiters) => waiters),
        catchError((error) => throwError(error))
      );
  }

  save(waiter: Waiter): Observable<Waiter> {
    if (waiter.exists) return this.update(waiter);
    return this.create(waiter);
  }

  // Private methods

  private create(waiter: Waiter): Observable<Waiter> {
    return this.afsvc
      .post<Waiter>({ segment: this._SEGMENT, data: waiter })
      .pipe(
        tap((_waiter) => this.businessSvc.addUserToBusiness(_waiter)),
        map((_waiter) => _waiter),
        catchError((error) => throwError(error))
      );
  }

  private update(waiter: Waiter): Observable<Waiter> {
    return this.afsvc
      .put<Waiter>({
        segment: `${this._SEGMENT}/${waiter.idUser}`,
        data: waiter,
      })
      .pipe(
        tap((_waiter) => this.businessSvc.updateUserInBusiness(_waiter)),
        map((_waiter) => _waiter),
        catchError((error) => throwError(error))
      );
  }
}
