export class Stack<T> {
  private _stack: T[] = [];

  constructor(private capacity: number = Infinity) {}

  public push(item: T): void {
    if (this.size() === this.capacity) {
      throw Error('Stack has reached max capacity, you cannot add more items');
    }

    this._stack = Object.assign([], this._stack);
    this._stack.push(item);
  }

  public pop(): T | undefined {
    this._stack = Object.assign([], this._stack);
    return this._stack.pop();
  }

  public peek(): T | undefined {
    return this._stack[this.size() - 1];
  }

  public size(): number {
    return this._stack.length;
  }

  public isEmpty(): boolean {
    return this.size() === 0;
  }

  public clear(): void {
    this._stack = [];
  }

  public get(): T[] {
    return this._stack;
  }
}
