<ng-container *ngIf="loading; then deleteLoading"></ng-container>
<h1 mat-dialog-title>Eliminar</h1>
    <div mat-dialog-content>
        <p>{{ question }}</p>
    </div>
    <div mat-dialog-actions>
        <div class="row">
            <div class="col-xs-6">
                <button mat-raised-button class="form__button form__button--color" (click)="onCancel()">Cancelar</button>
            </div>
            <div class="col-xs-6">
                <button mat-raised-button class="form__button" color="primary" (click)="onDelete()">Eliminar</button>
            </div>
        </div>
    </div>

<ng-template #deleteLoading>
    <div class="loading">
        <app-loading message="Eliminando..." ></app-loading>
    </div>
</ng-template>
