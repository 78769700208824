<h2 mat-dialog-title>Términos y condiciones</h2>
<mat-dialog-content>
  <ol class="main-ol">
    <li>El respeto a tu privacidad está totalmente garantizado.</li>
    <li>
      A partir de este momento www.notmenu.com será llamado como aplicación en
      el resto del documento.
    </li>
    <li>
      A través de esta aplicación se solicitan datos personales del usuario los
      cuales están abajo mencionados:
      <ol>
        <li>Dirección de correo electrónico.</li>
        <li>Nombre completo.</li>
        <li>Edad.</li>
        <li>Sexo.</li>
        <li>Código postal.</li>
        <li>Estado.</li>
        <li>Delegación ó Municipio.</li>
      </ol>
    </li>
    <li>No se accede a las cuentas de correo de los usuarios.</li>
    <li>La aplicación no recopila información sobre tu ubicación real.</li>
    <li>La aplicación no obtiene ingresos por medio de publicidad.</li>
    <li>
      No NotMenu, no google, el usuario es el único responsable de su contenido.
    </li>
    <li>
      Mantenimiento y soporte: NotMenu, no Google, estará obligado a
      proporcionar dicho mantenimiento o soporte.
    </li>
    <li>
      Cargos y cuotas
      <ol>
        <li>El uso de esta aplicación puede ser gratiuto.</li>
        <li>
          Dependiendo de la demanda del menú del usuario se le contactara para
          acordar un plan de cobro.
        </li>
      </ol>
    </li>
    <li>
      Cambios en nuestros términos y condiciones.
      <ol>
        <li>
          Términos y condiciones puede cambiar en cualquier momento sin previo
          aviso.
        </li>
        <li>
          Publicaremos cualquier cambio de términos y condiciones en esta
          página, por lo que debe revisarla periódicamente.
        </li>
      </ol>
    </li>
    <li>
      Contacto
      <ol>
        <li>
          Si tiene alguna pregunta sobre los términos y condiciones o para
          informar de cualquier violación de los términos y condiciones, envíe
          un correo electrónico a:
          <a href="mailto:info@notmenu.com?subject=Términos y condiciones"
            >info@notmenu.com</a
          >
        </li>
      </ol>
    </li>
  </ol>
  <h5>Última modificación: 23 de Julio de 2020.</h5>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
