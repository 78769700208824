export namespace LoginLinkStripe {
  export class Get {
    static readonly type = '[LoginLinkStripe] Get';
    constructor(public stripeUserId: string) {}
  }
  export class Success {
    static readonly type = '[LoginLinkStripe] Success';
    constructor(public url: string) {}
  }

  export class Failure {
    static readonly type = '[LoginLinkStripe] Failure';
    constructor(public message: string) {}
  }
}
