import { Injectable } from '@angular/core';
import { IDelete } from '@core/interfaces/delete.interface';
import { ISort, Sort } from '@core/interfaces/sort.interface';
import { Category, IdsMenu, Menu } from '@core/models';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements IDelete, ISort {
  private _SEGMENT = 'menus';
  private _KEY = '__m';

  constructor(
    private afsvc: ApiFacadeService,
    private businessSvc: BusinessService,
    private ldsvc: LocalDataService
  ) {}

  // Base methods
  delete(ids: IdsMenu): Observable<boolean> {
    return this.afsvc
      .delete({
        segment: `${this._SEGMENT}/${ids.menuId}`,
        storageOptions: {
          delete: true,
          key: `${this._KEY}${ids.menuId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() =>
          this.businessSvc.removeMenuFromBusiness(ids.businessId, ids.menuId)
        ),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  get(menuId: string): Observable<Menu> {
    return this.afsvc
      .get<Menu>({
        segment: `${this._SEGMENT}/${menuId}`,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${menuId}`,
        },
        useGateway: true,
      })
      .pipe(
        map((menu) => menu),
        catchError((error) => throwError(error))
      );
  }

  getCurrent = (): string => this.ldsvc.get<string>('__mcurrent');

  save(menu: Menu): Observable<Menu> {
    if (menu.menuId) {
      return this.update(menu);
    } else {
      return this.create(menu);
    }
  }

  saveCurrent = (menuId: string): void => this.ldsvc.set('__mcurrent', menuId);

  sort(menuId: string, categories: Sort): Observable<Boolean> {
    return this.afsvc
      .patch<Sort>({
        segment: `${this._SEGMENT}/${menuId}/categories/sort`,
        data: categories,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          const menu: Menu = this.ldsvc.get<Menu>(`${this._KEY}${menuId}`);
          menu.sort = [...categories.sort];
          this.ldsvc.set<Menu>(`${this._KEY}${menuId}`, menu);
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  // Public methods
  addCategoryToMenu(category: Category): void {
    const menu: Menu = this.ldsvc.get<Menu>(`${this._KEY}${category.menuId}`);
    if (!menu.categories) menu.categories = [category];
    else menu.categories.push(category);
    if (!menu.sort) {
      menu.sort = [];
    }
    if (!menu.sort.includes(category.categoryId)) {
      menu.sort.push(category.categoryId);
    }
    this.ldsvc.set<Menu>(`${this._KEY}${category.menuId}`, menu);
  }

  removeCategoryFromMenu(menuId: string, categoryId: string): void {
    const menu: Menu = this.ldsvc.get<Menu>(`${this._KEY}${menuId}`);
    const categories: Category[] = menu.categories.filter(
      (c) => c.categoryId !== categoryId
    );
    menu.categories = [...categories];
    const sort: string[] = menu.sort.filter((o) => o !== categoryId);
    menu.sort = [...sort];
    this.ldsvc.set<Menu>(`${this._KEY}${menuId}`, menu);
  }

  updateCategoryInMenu(category: Category): void {
    const menu: Menu = this.ldsvc.get<Menu>(`${this._KEY}${category.menuId}`);
    const categories: Category[] = menu.categories.filter(
      (c) => c.categoryId !== category.categoryId
    );
    menu.categories = [...categories, category];
    this.ldsvc.set<Menu>(`${this._KEY}${category.menuId}`, menu);
  }

  // Private methods

  private create(menu: Menu): Observable<Menu> {
    menu.menuId = uuidv4();
    return this.afsvc
      .post<Menu>({ segment: this._SEGMENT, data: menu, useGateway: true })
      .pipe(
        tap(() => {
          this.ldsvc.set<Menu>(`${this._KEY}${menu.menuId}`, menu);
          this.businessSvc.addMenuToBusiness(menu);
        }),
        mapTo(menu),
        catchError((error) => throwError(error))
      );
  }

  private update(menu: Menu): Observable<Menu> {
    return this.afsvc
      .put<Menu>({
        segment: `${this._SEGMENT}/${menu.menuId}`,
        data: menu,
        storageOptions: {
          search: true,
          save: false,
          key: `__m${menu.menuId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.updateInLocalData(menu);
          this.businessSvc.updateMenuInBusiness(menu);
        }),
        mapTo(menu),
        catchError((error) => throwError(error))
      );
  }

  private updateInLocalData(menuUpdated: Menu): void {
    const currentMenu: Menu = this.ldsvc.get<Menu>(
      `${this._KEY}${menuUpdated.menuId}`
    );

    currentMenu.active = menuUpdated.active;
    currentMenu.allowOrderHere = menuUpdated.allowOrderHere;
    currentMenu.allowPickUp = menuUpdated.allowPickUp;
    currentMenu.allowDelivery = menuUpdated.allowDelivery;
    currentMenu.backgroundUrl = menuUpdated.backgroundUrl;
    currentMenu.name = menuUpdated.name;
    currentMenu.phones = menuUpdated.phones;
    currentMenu.phoneForOrders = menuUpdated.phoneForOrders;
    currentMenu.showName = menuUpdated.showName;
    currentMenu.deliveryFee = menuUpdated.deliveryFee;

    this.ldsvc.set<Menu>(`${this._KEY}${menuUpdated.menuId}`, currentMenu);
  }
}
