import { Business } from '@core/models/business.model';

export class GetBusinessAction {
  static readonly type = '[GetBusiness]';
  constructor(public businessId: string, public force: boolean = false) {}
}

export class GetBusinessSuccessAction {
  static readonly type = '[GetBusiness] Success';
  constructor(public business: Business) {}
}

export class GetBusinessFailAction {
  static readonly type = '[GetBusiness] Fail';
  constructor(public message: string) {}
}
