import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Business, FormResultBusiness } from '@core/models/business.model';
import {
  GetBusinessState,
  SaveBusinessAction,
  SaveBusinessState,
} from '@store/admin/business';
import { SnackbarHelper } from '@shared/helpers/snackbar.helper';
import { FastErrorStateMatcherHelper } from '@shared/helpers/fast-error-state-matcher.helper';

@Component({
  selector: 'app-business-form',
  templateUrl: './business-form.component.html',
  styleUrls: ['./business-form.component.scss'],
})
export class BusinessFormComponent implements OnInit {
  @Select(GetBusinessState.getBusiness) business$: Observable<Business>;
  @Select(GetBusinessState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetBusinessState.inProgress)
  getBusinessInProgress$: Observable<boolean>;
  @Select(SaveBusinessState.getResult) result$: Observable<FormResultBusiness>;
  @Select(SaveBusinessState.inProgress) saveInProgress$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();
  private sent: boolean = false;

  business: Business;
  countries = [{ code: 'MX', name: 'México' }];
  countryControl: FormControl;
  errorMessage: string;
  formGroup: FormGroup;
  isGetting: boolean = true;
  isSaving: boolean = false;
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';
  qrData: string = '';
  urlQr: string = environment.urlQr;
  matcher = new FastErrorStateMatcherHelper();

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackbarHelper,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business) {
        this.business = business;
        this.setQr();
      }

      this.createForm();
    });

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.getBusinessInProgress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        if (!this.isSaving) {
          this.loading = loading;
          this.isGetting = loading;
          if (loading) this.loadingMessage = 'Cargando...';
        }
      });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (this.sent && result.error) {
        this.snackBar.showError(result.message);
      } else if (this.sent && result.success) {
        this.snackBar.showSuccess(result.message);
        this.sent = false;
        if (!this.business) {
          location.reload();
        }
        this.business = result.business;

        this.createForm();
      }
    });

    this.saveInProgress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        if (!this.isGetting) {
          this.loading = loading;
          this.isSaving = loading;
          if (loading) this.loadingMessage = 'Guardando...';
        }
      });
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const business: Business = {
      ...this.business,
      key: this.formGroup.get('key').value,
      name: this.formGroup.get('name').value,
      countryCode: this.countryControl.value,
      state: this.formGroup.get('state').value,
      city: this.formGroup.get('city').value,
      neighborhood: this.formGroup.get('neighborhood').value,
      street: this.formGroup.get('street').value,
      zip: this.formGroup.get('zip').value,
      isOpen: this.formGroup.get('isOpen').value ?? false,
      allowPayments: this.formGroup.get('allowPayments').value ?? false,
    } as Business;

    this.save(business);
  }

  private createForm(): void {
    this.countryControl = new FormControl(
      this.business ? this.business.countryCode : this.countries[0].code
    );

    const nameFormControl: FormControl = new FormControl(
      {
        value: this.business ? this.business.name : '',
        disabled: this.business ? 'disabled' : '',
      },
      [Validators.required, Validators.minLength(5)]
    );

    const keyFormControl: FormControl = new FormControl(
      {
        value: this.business
          ? this.business.key
            ? this.business.key
            : ''
          : '',
        disabled: this.business ? 'disabled' : '',
      },
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]
    );

    const stateFormControl: FormControl = new FormControl(
      {
        value: this.business ? this.business.state : '',
        disabled: this.business
          ? !this.business.active
            ? 'disabled'
            : ''
          : '',
      },
      [Validators.required, Validators.minLength(4)]
    );

    const cityFormControl: FormControl = new FormControl(
      {
        value: this.business ? this.business.city : '',
        disabled: this.business
          ? !this.business.active
            ? 'disabled'
            : ''
          : '',
      },
      [Validators.required, Validators.minLength(5)]
    );

    const neighborhoodFormControl: FormControl = new FormControl(
      {
        value: this.business ? this.business.neighborhood : '',
        disabled: this.business
          ? !this.business.active
            ? 'disabled'
            : ''
          : '',
      },
      [Validators.required, Validators.minLength(5)]
    );

    const streetFormControl: FormControl = new FormControl(
      {
        value: this.business ? this.business.street : '',
        disabled: this.business
          ? !this.business.active
            ? 'disabled'
            : ''
          : '',
      },
      [Validators.required, Validators.minLength(5)]
    );

    const zipFormControl: FormControl = new FormControl(
      {
        value: this.business ? this.business.zip : '',
        disabled: this.business
          ? !this.business.active
            ? 'disabled'
            : ''
          : '',
      },
      [Validators.required]
    );

    const isOpenControl: FormControl = new FormControl({
      value: this.business ? this.business.isOpen : true,
      disabled: this.business ? (!this.business.active ? 'disabled' : '') : '',
    });

    const allowPaymentsControl: FormControl = new FormControl({
      value: this.business ? this.business.allowPayments : true,
      disabled: this.business ? (!this.business.active ? 'disabled' : '') : '',
    });

    this.formGroup = this.formBuilder.group({
      allowPayments: allowPaymentsControl,
      city: cityFormControl,
      country: this.countryControl,
      isOpen: isOpenControl,
      key: keyFormControl,
      name: nameFormControl,
      neighborhood: neighborhoodFormControl,
      state: stateFormControl,
      street: streetFormControl,
      zip: zipFormControl,
    });
  }

  private setQr(): void {
    this.qrData = `${this.urlQr}/${this.business.key}`;
  }

  private save(business: Business): void {
    this.sent = true;
    this.store.dispatch(new SaveBusinessAction(business));
  }
}
