import { Pipe, PipeTransform } from '@angular/core';
import { Extra } from '@core/models/extra.model';
import { ListItem } from '@core/models/list-item.model';
import { ParamsModel } from '../core/params.model';

@Pipe({
  name: 'fromProductsExtrasToListItem',
})
export class FromProductsExtrasToListItemPipe implements PipeTransform {
  transform(extras: Extra[], sort: string[], params: ParamsModel): ListItem[] {
    if (!extras?.length || !sort?.length) {
      return null;
    }
    let extrasObject = extras.reduce(
      (prev, current) => ({
        ...prev,
        [current.extraId]: current,
      }),
      {}
    );
    return sort.map((extra) => {
      let current = extrasObject[extra];
      return {
        id: current.extraId,
        text: current.name,
        url: params.productId
          ? `b/${params.businessId}/m/${params.menuId}/c/${params.categoryId}/p/${params.productId}/e/${current.extraId}`
          : `b/${params.businessId}/e/${current.extraId}`,
        active: current.active,
      } as ListItem;
    });
  }
}
