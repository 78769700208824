import { Component } from '@angular/core';
import { Business, FormResultBusiness } from '@core/models/business.model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GetBusinessAction,
  GetBusinessState,
  SaveBusinessState,
} from '@store/admin/business';
import { GetProfileAction, GetProfileState } from '@store/admin/profile';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';
import { BusinessDialogComponent } from 'app/admin/components/business-dialog/business-dialog.component';
import { CreateMenuDialogComponent } from 'app/admin/components/create-menu-dialog/create-menu-dialog.component';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent extends BreadcrumbComponent {
  @Select(GetProfileState.inProgress)
  getProfileInProgress$: Observable<boolean>;
  @Select(GetProfileState.getBusiness) profileBusiness$: Observable<Business>;
  @Select(GetBusinessState.getBusiness) business$: Observable<Business>;

  businessId: string;
  loading: boolean;
  showTabs: boolean = false;
  titles: string[] = ['Negocio', 'Menús', 'Extras'];
  tabIndex: number = 0;
  isFirstTime: boolean = true;
  isFirstTimeShowed: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public store: Store,
    public dialog: MatDialog
  ) {
    super(route, store);
  }

  onInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params.index) this.tabIndex = params.index;
      });

    this.store.dispatch(new GetProfileAction());
    this.profileBusiness$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        if (business) {
          this.isFirstTime = false;
          this.businessId = business.businessId;
          this.showTabs = business.active;
          this.store.dispatch(new GetBusinessAction(business.businessId));
          return;
        }
        if (this.isFirstTime) {
          this.showDialogFirstTime();
        }
      });

    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business) {
        this.isFirstTime = false;
        this.businessId = business.businessId;
        this.showTabs = business.active;

        if (!business?.menus?.length) {
          this.showDialogCreateMenu();
        }
        return;
      }
    });

    this.getProfileInProgress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  initializeBreadcrumb(): void {
    this.breadcrumb = {
      name: 'Negocio',
      icon: 'business',
      url: 'business',
      position: 0,
    } as Breadcrumb;
  }

  private showDialogFirstTime() {
    if (this.isFirstTimeShowed) return;

    this.isFirstTimeShowed = true;
    this.dialog.open(BusinessDialogComponent, {
      autoFocus: false,
    });
  }

  private showDialogCreateMenu() {
    this.dialog.open(CreateMenuDialogComponent, {
      autoFocus: false,
    });
  }
}
