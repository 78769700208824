export class SignIn {
  static readonly type = '[SignIn]';
  constructor(public email: string, public password: string) {}
}

export class SignInSuccess {
  static readonly type = '[SignIn] Success';
  constructor() {}
}

export class SignInFailed {
  static readonly type = '[SignIn] Failed';
  constructor(public message: string) {}
}
