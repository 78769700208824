import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SecurityService } from '@core/services/security.service';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private securitySvc: SecurityService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get('skip')) {
      return next.handle(req);
    }

    return this.securitySvc.getToken().pipe(
      mergeMap((token: any) => {
        if (token) {
          req = req.clone({ setHeaders: { Authorization: token } });
        }
        return next.handle(req);
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string = '';
        if (Array.isArray(error.error.message)) {
          errorMessage = error.error.message.join();
        } else {
          errorMessage = error.error.message;
        }

        return throwError(errorMessage);
      })
    );
  }
}
