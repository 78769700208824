<ng-container *ngIf="loading; then sortLoading"></ng-container>


    <h1 mat-dialog-title>Ordenar</h1>
    <div mat-dialog-content>
        <p>Arrastra los elementos para ordenarlos.</p>
        <div cdkDropList class="example-list" (cdkDropListDropped)="onDrop($event)">
            <div class="example-box" *ngFor="let listItem of listItems" cdkDrag>{{listItem.text}}</div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="row">
            <div class="col-xs-6">
                <button mat-raised-button class="form__button form__button--color" (click)="onCancel()">Cancelar</button>
            </div>
            <div class="col-xs-6">
                <button mat-raised-button class="form__button" color="primary" (click)="onSave()">Guardar</button>
            </div>
        </div>
    </div>

<ng-template #sortLoading>
    <div class="loading">
        <app-loading message="Ordenando..." ></app-loading>
    </div>    
</ng-template>

