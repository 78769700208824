export class SignUp {
  static readonly type = '[SignUp]';
  constructor(public email: string, public password: string) {}
}

export class SignUpSuccess {
  static readonly type = '[SignUp] Success';
  constructor() {}
}

export class SignUpFailed {
  static readonly type = '[SignUp] Failed';
  constructor(public message: string) {}
}
