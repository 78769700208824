import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '@core/models/list-item.model';
import { Option } from '@core/models/option.model';
import { ParamsModel } from '../core/params.model';

@Pipe({
  name: 'fromOptionsToListItem',
})
export class FromOptionsToListItemPipe implements PipeTransform {
  transform(
    options: Option[],
    sort: string[],
    params: ParamsModel
  ): ListItem[] {
    if (!options?.length || !sort?.length) {
      return null;
    }
    let optionsObject = options.reduce(
      (prev, next) => ({
        ...prev,
        [next.optionId]: next,
      }),
      {}
    );
    return sort.map((optionId) => {
      let current = optionsObject[optionId];
      return {
        id: current.optionId,
        text: current.name,
        url: params.productId
          ? `b/${params.businessId}/m/${params.menuId}/c/${params.categoryId}/p/${params.productId}/e/${params.extraId}/o/${current.optionId}`
          : `b/${params.businessId}/e/${params.extraId}/o/${current.optionId}`,
        active: current.active,
      } as ListItem;
    });
  }
}
