import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '@core/https/admin/category.service';
import { Category } from '@core/models/category.model';
import { Product } from '@core/models/product.model';
import { Select } from '@ngxs/store';
import { GetCategoryState } from '@store/admin/category/states/get-category.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent extends ParamsComponent {
  @Input() categoryId: string;
  @Select(GetCategoryState.getCategory) category$: Observable<Category>;

  buttonUrl: string;
  products: Product[];
  sort: string[];

  constructor(
    public categoryService: CategoryService,
    public activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    this.buttonUrl = `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.categoryId}/product`;
    this.category$.pipe(takeUntil(this.unsubscribe$)).subscribe((category) => {
      if (category && category.products) {
        this.products = category.products;
        this.sort = category.sort;
      }
    });
  }
}
