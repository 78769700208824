import { Injectable } from '@angular/core';
import { ContactService } from '@core/https/public/contact.service';
import { FormResult } from '@core/models/form-result.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  ContactAction,
  ContactSuccessAction,
  ContactFailedAction,
} from '../actions/contact.actions';

export class ContactStateModel {
  public loading: boolean;
  public result: FormResult;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
  } as FormResult,
};

@State<ContactStateModel>({
  name: 'contact',
  defaults,
})
@Injectable()
export class ContactState {
  private _successMessage = 'Tu mensaje fue enviado con éxito.';

  constructor(private contactSvc: ContactService) {}

  @Selector()
  public static sending({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(ContactAction)
  send(ctx: StateContext<ContactStateModel>, { contact }: ContactAction) {
    this._sending(ctx);

    return this.contactSvc.send(contact).pipe(
      tap(() => {
        ctx.dispatch(new ContactSuccessAction());
      }),
      catchError((error) => {
        ctx.dispatch(new ContactFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(ContactSuccessAction)
  contactSuccess(ctx: StateContext<ContactStateModel>) {
    const state = ctx.getState();

    const result: FormResult = {
      error: false,
      success: true,
      message: this._successMessage,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(ContactFailedAction)
  contactFailed(
    ctx: StateContext<ContactStateModel>,
    { message }: ContactFailedAction
  ) {
    const state = ctx.getState();

    const result: FormResult = {
      error: true,
      success: false,
      message: message,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private _sending(ctx: StateContext<ContactStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
