import { Injectable } from '@angular/core';
import { Customer } from '@core/models/customer.model';
import { ApiFacadeService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private _SEGMENT = 'customers';
  private _KEY = '__customers';

  constructor(private apiFacadeService: ApiFacadeService) {}

  get(businessId: string): Observable<Customer[]> {
    return this.apiFacadeService
      .get<Customer[]>({
        segment: `${this._SEGMENT}/${businessId}`,
        storageOptions: {
          search: false,
          save: false,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((customers: Customer[]) => customers),
        catchError((error) => throwError(error))
      );
  }
}
