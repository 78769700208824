<section content1>
    <div class="content-form__wrapper">
        <ng-container *ngIf="!loading; then categoryForm else categoryLoading"></ng-container>
    </div>
</section>

<ng-template #categoryForm>
    <div class="row--width">
        <app-eraser
          buttonText="Eliminar categoría"
          question="¿Estas seguro que quieres eliminar esta categoría?"
          eraserType="CATEGORY"
          [implDelete]="categorySvc"
          [show]="showEraserComponent">
        </app-eraser>
        <form [formGroup]="formGroup" (submit)="onSave()">
            <div class="row center-xs">
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input matInput maxlength="30" formControlName="name" name="name" placeholder="Nombre de la categoría" #inputName required>
                        <mat-hint align="end">{{ inputName.value?.length || 0 }}/30</mat-hint>
                    </mat-form-field>
                    <mat-checkbox class="form__checkbox" formControlName="active" name="active" color="primary">Activo</mat-checkbox>
                </div>
                <button type="submit" class="form__button" mat-raised-button [disabled]="formGroup.invalid">Guardar</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #categoryLoading>
    <app-loading message="Guardando..."></app-loading>
</ng-template>
