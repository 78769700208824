import { OrdersRank, ProductRank, Rank, RankWithCount } from '@core/models';

export namespace AnalyticsActions {
  export class GetData {
    static readonly type = '[Analytics] GetData';
    constructor() {}
  }

  export class SuccessGetData {
    static readonly type = '[Analytics] SuccessGetData';
    constructor(
      public accesses: Rank[],
      public orders: RankWithCount[],
      public productsAdded: ProductRank[],
      public productsViewed: ProductRank[],
      public productsRequested: ProductRank[],
      public ordersPerDay: OrdersRank | null
    ) {}
  }

  export class FailureGetData {
    static readonly type = '[Analytics] FailureGetData';
    constructor(public message: string) {}
  }
}
