import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@core/guards/admin.guard';
import { AuthGuard } from '@core/guards/auth.guard';

import { BusinessComponent } from './pages/business/business.component';
import { CategoryComponent } from './pages/category/category.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ExtraComponent } from './pages/extra/extra.component';
import { MenuComponent } from './pages/menu/menu.component';
import { OptionComponent } from './pages/option/option.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ProductComponent } from './pages/product/product.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { UserComponent } from './pages/user/user.component';
import { UsersComponent } from './pages/users/users.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { AdminPromotionsComponent } from './pages/promotion/admin-promotions/admin-promotions.component';
import { PointOfSaleComponent } from './pages/point-of-sale/point-of-sale.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    canActivateChild: [AdminGuard],
    path: 'admin',
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'business',
        component: BusinessComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu',
        component: MenuComponent,
      },
      {
        path: 'b/:idBusiness/menu',
        component: MenuComponent,
      },
      {
        path: 'b/:idBusiness/e/:idExtra',
        component: ExtraComponent,
      },
      {
        path: 'b/:idBusiness/extra',
        component: ExtraComponent,
      },
      {
        path: 'b/:idBusiness/e/:idExtra/option',
        component: OptionComponent,
      },
      {
        path: 'b/:idBusiness/e/:idExtra/o/:idOption',
        component: OptionComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/category',
        component: CategoryComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory',
        component: CategoryComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory/product',
        component: ProductComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory/p/:idProduct',
        component: ProductComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory/p/:idProduct/e/:idExtra',
        component: ExtraComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory/p/:idProduct/extra',
        component: ExtraComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory/p/:idProduct/e/:idExtra/option',
        component: OptionComponent,
      },
      {
        path: 'b/:idBusiness/m/:idMenu/c/:idCategory/p/:idProduct/e/:idExtra/o/:idOption',
        component: OptionComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: ':idBusiness/user/:idUser',
        component: UserComponent,
      },
      {
        path: ':idBusiness/user',
        component: UserComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'payment',
        component: PaymentComponent,
      },
      {
        path: 'point-of-sale',
        component: PointOfSaleComponent,
      },
      {
        path: 'customer',
        component: CustomerComponent,
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
      },
      {
        path: 'promotions/admin',
        component: AdminPromotionsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
