import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent {
  @Input() content: TemplateRef<any>;
}
