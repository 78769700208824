import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from '@core/https/admin/menu.service';
import { Category } from '@core/models/category.model';
import { Menu } from '@core/models/menu.model';
import { Select } from '@ngxs/store';
import { GetMenuState } from '@store/admin/menu/states/get-menu.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent extends ParamsComponent {
  @Select(GetMenuState.getMenu) menu$: Observable<Menu>;

  buttonUrl: string;
  categories: Category[];
  order: string[];

  constructor(
    public activedRouter: ActivatedRoute,
    public menuService: MenuService
  ) {
    super(activedRouter);
  }

  onInit(): void {
    this.buttonUrl = `b/${this.paramBusiness}/m/${this.paramMenu}/category`;
    this.menu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu) => {
      if (!menu) return;
      this.categories = menu.categories;
      this.order = menu.sort;
    });
  }
}
