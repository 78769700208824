import { Injectable } from '@angular/core';
import { Business } from '@core/models/business.model';
import { Profile } from '@core/models/profile.model';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _SEGMENT = 'profiles';
  private _KEY = '__p';

  constructor(
    private afsvc: ApiFacadeService,
    private ldsvc: LocalDataService
  ) {}

  // Base methods
  get(): Observable<Profile> {
    return this.afsvc
      .get<Profile>({
        segment: this._SEGMENT,
        storageOptions: {
          search: true,
          save: true,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((_profile: Profile) => _profile),
        catchError((error) => throwError(error))
      );
  }

  save(profile: Profile): Observable<Profile> {
    return this.afsvc
      .put<Profile>({
        segment: this._SEGMENT,
        data: profile,
        storageOptions: {
          search: false,
          save: true,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        mapTo(profile),
        catchError((error) => throwError(error))
      );
  }

  // Public methods
  addBusinessToProfile(business: Business): void {
    const profile: Profile = this.ldsvc.get<Profile>(this._KEY);
    if (profile.businesses) profile.businesses.push(business);
    else profile.businesses = [business];
    this.ldsvc.set<Profile>(this._KEY, profile);
  }

  updateBusinessToProfile(business: Business): void {
    const profile: Profile = this.ldsvc.get<Profile>(this._KEY);
    profile.businesses = [business];
    this.ldsvc.set<Profile>(this._KEY, profile);
  }

  getFirstBusiness(): Business {
    const profile = this.ldsvc.get<Profile>(this._KEY);
    return profile?.businesses[0];
  }
}
