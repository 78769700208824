<mat-toolbar
  [ngClass]="isExtendedToolbar ? 'mat-toolbar-extend' : ''"
  role="toolbar"
>
  <mat-toolbar-row>
    <div class="row">
      <div class="col-xs-10 col-md-10 col-lg-4">
        <div class="start-xs mat-toolbar__logo">
          <img
            (click)="onGoToWelcome()"
            src="assets/images/logo-notfondo-white.png"
            alt="NotMenu"
          />
        </div>
      </div>
      <div class="col-xs-2 col-md-2 col-lg-8">
        <nav class="end-xs">
          <div class="toolbar-nav--mobile">
            <button mat-button [matMenuTriggerFor]="menu">
              <mat-icon aria-hidden="false" aria-label="Menu">menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onGoToAboutUs()">
                Acerca de nosotros
              </button>
              <button mat-menu-item (click)="onGoToContact()">Contacto</button>
              <button
                mat-menu-item
                (click)="onGoToSignIn()"
                appShowLoading
                appBehaivorMenu
              >
                Iniciar sesión
              </button>
            </mat-menu>
          </div>
          <div class="toolbar-nav--desktop">
            <a mat-button (click)="onGoToAboutUs()">Acerca de nosotros</a>
            <a mat-button routerLink="/contact" routerLinkActive="active"
              >Contacto</a
            >
            <a
              mat-button
              routerLink="/auth/signin"
              routerLinkActive="active"
              appShowLoading
              appBehaivorMenu
              >Iniciar sesión</a
            >
          </div>
        </nav>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
