import { Component } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, Business } from '@core/models';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { GetBusinessAction, GetBusinessState } from '@store/admin/business';
import { GetProfileAction, GetProfileState } from '@store/admin/profile';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss'],
})
export class PointOfSaleComponent extends BreadcrumbComponent {
  @Select(GetProfileState.getBusiness) profileBusiness$: Observable<Business>;
  @Select(GetBusinessState.getBusiness) business$: Observable<Business>;

  tabIndex: number = 0;
  loading: boolean = true;
  apikey = uuidv4();
  safeUrl: SafeResourceUrl;
  business: Business;

  constructor(
    public store: Store,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    super(route, store);
  }

  onInit(): void {
    this.store.dispatch(new GetProfileAction());

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params.index) this.tabIndex = params.index;
      });
    this.profileBusiness$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        if (business) {
          this.store.dispatch(new GetBusinessAction(business.businessId));
        }
      });

    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business) {
        this.business = business;
        this.loading = false;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://${environment.production ? '' : 'dev.'}notmenu.app/${
            this.business.key
          }?apikey=${this.apikey}`
        );
      }
    });
  }

  initializeBreadcrumb(): void {
    this.breadcrumb = {
      name: 'Punto de venta',
      icon: 'point_of_sale',
      url: 'point-of-sale',
      position: 0,
    } as Breadcrumb;
  }
}
