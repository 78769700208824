<app-auth-container>
    <div class="content-form__wrapper" content>
        <h2>Recuperar contraseña</h2>
        <ng-container *ngIf="!loading; then recoverPasswordForm else recoverPasswordLoading"></ng-container>
    </div>
</app-auth-container>

<ng-template #recoverPasswordForm>
    <form [formGroup]="formGroup" (ngSubmit)="onRecoverPassword()">
        <div class="row center-xs">
            <div class="content-form__form">
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" name="email" placeholder="email@dominio.com" required>
                    </mat-form-field>
                </div>
                <div class="col-xs-12">
                    <button type="submit" class="form__button" mat-raised-button [disabled]="formGroup.invalid">Recuperar</button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #recoverPasswordLoading>
    <app-loading message="Enviando enlace..."></app-loading>
</ng-template>
