import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '@core/https/admin/menu.service';
import { FileItem } from '@core/models/file-item.model';
import { FormResultMenu, Menu } from '@core/models/menu.model';
import { StorageService } from '@core/services/storage.service';
import { Select, Store } from '@ngxs/store';
import { PhoneValidation } from '@shared/validators/phone.validation';
import { PopBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { MenuAction } from '@store/admin/menu/actions/menu.actions';
import { GetMenuState } from '@store/admin/menu/states/get-menu.state';
import { MenuState } from '@store/admin/menu/states/menu.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss'],
})
export class MenuFormComponent extends ParamsComponent {
  @Input() businessId: string;
  @Select(GetMenuState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetMenuState.getting) getting$: Observable<boolean>;
  @Select(GetMenuState.getMenu) menu$: Observable<Menu>;
  @Select(MenuState.result) result$: Observable<FormResultMenu>;
  @Select(MenuState.saving) saving$: Observable<boolean>;

  private sent: boolean = false;

  errorMessage: string;
  file: File;
  formGroup: FormGroup;
  getting: boolean = false;
  image: string = 'assets/images/no-menu-image.png';
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';
  menu: Menu;
  saving: boolean = false;
  showEraserComponent: boolean = false;
  uploadImageFirstTime: boolean = false;
  deliveryOptions: boolean = false;
  enableDeliveryFee: boolean = false;
  deliveryFee: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store,
    private storageSvc: StorageService,
    public activatedRoute: ActivatedRoute,
    public menuSvc: MenuService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    if (this.paramMenu) this.showEraserComponent = true;

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.getting$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.saving && !this.uploadImageFirstTime) {
        this.loading = loading;
        this.getting = loading;
        if (loading) this.loadingMessage = 'Cargando...';
      }
    });

    this.menu$.pipe(takeUntil(this.unsubscribe$)).subscribe((menu) => {
      if (menu) {
        this.menu = menu;
        this.deliveryOptions = this.menu.allowDelivery;
        this.enableDeliveryFee = this.menu.deliveryFee ? true : false;
      }
      this.createForm();
    });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });

        this.sent = false;
      } else if (result.success && this.sent) {
        this.menu = { ...result.menu };

        if (this.uploadImageFirstTime) {
          this._uploadAndSave(this.menu);
          return;
        }

        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });

        this.sent = false;
        this.menu = result.menu;

        this.createForm();
        this.store.dispatch(new PopBreadcrumbAction(1));
        this.router.navigate([`admin/business`], {
          queryParams: { index: '1' },
        });
      }
    });

    this.saving$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.getting && !this.uploadImageFirstTime) {
        this._showSaving(loading);
      }
    });
  }

  onLoadImage(file: File) {
    this.file = file;
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const phones1 = this.formGroup.get('phones1')?.value;
    const phones2 = this.formGroup.get('phones2')?.value;
    const phones = [];
    if (phones1) {
      phones.push(`52${phones1}`);
    }
    if (phones2) {
      phones.push(`52${phones2}`);
    }
    const menu: Menu = {
      ...this.menu,
      active: this.formGroup.get('active').value,
      allowDelivery: this.formGroup.get('allowDelivery').value,
      allowPickUp: this.formGroup.get('allowPickUp').value,
      allowOrderHere: false,
      backgroundUrl: this.menu?.backgroundUrl ?? undefined,
      deliveryFee: this.enableDeliveryFee
        ? this.formGroup.get('deliveryFee').value
        : undefined,
      businessId: this.businessId,
      name: this.formGroup.get('name').value,
      phones,
      phoneForOrders: this.formGroup.get('phoneForOrders').value,
      showName: this.formGroup.get('showName').value,
    } as Menu;

    if (menu.menuId && this.file) {
      this._uploadAndSave(menu);
    } else {
      if (this.file && !menu.menuId && !menu.backgroundUrl) {
        this.uploadImageFirstTime = true;
        this._showSaving(true);
      }
      this._save(menu);
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      active: [this.menu ? this.menu.active : false],
      allowDelivery: [this.menu ? this.menu.allowDelivery : false],
      allowPickUp: [this.menu ? this.menu.allowPickUp : false],
      deliveryFee: [
        this?.menu?.deliveryFee ? this.menu.deliveryFee : null,
        [Validators.min(1), Validators.max(9999), Validators.pattern('[0-9]*')],
      ],
      enableDeliveryFee: this?.menu?.deliveryFee ? true : false,
      name: [
        this.menu ? this.menu.name : '',
        [Validators.required, Validators.minLength(2)],
      ],
      phones1: [
        this.menu?.phones?.length ? this.menu.phones[0].slice(2, 12) : '',
        PhoneValidation.validations,
      ],
      phones2: [
        this.menu?.phones?.length > 1 ? this.menu.phones[1].slice(2, 12) : '',
        PhoneValidation.validations,
      ],
      phoneForOrders: [
        this.menu ? this.menu.phoneForOrders : '',
        [...PhoneValidation.validations, Validators.required],
      ],
      showName: [this.menu ? this.menu.showName : true],
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((input) => {
        if (!input.allowDelivery && !input.allowPickUp)
          this.formGroup.setErrors({ required: true });

        this.enableDeliveryFee = input.enableDeliveryFee;

        if (!input.enableDeliveryFee) {
          let valid: boolean = this.formGroup.get('deliveryFee').valid;
          if (!valid) {
            this.formGroup.get('deliveryFee').setValue(null);
          }
        }

        if (input.enableDeliveryFee && !input.deliveryFee)
          this.formGroup.setErrors({ required: true });

        this.deliveryOptions = input.allowDelivery;
        if (!this.deliveryOptions) this.enableDeliveryFee = false;
      });
  }

  private _uploadAndSave(menu: Menu): void {
    this.loading = true;
    this.loadingMessage = 'Subiendo Imagen...';

    const fileItem: FileItem = new FileItem(this.file);
    fileItem.name = `banner_menu_${menu.menuId}.${this.file.name
      .split('.')
      .pop()}`;

    this.storageSvc.mediaStoragePath = this.storageSvc.getPath();
    this.storageSvc.uploadFile(fileItem);

    fileItem.downloadUrl
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((backgroundUrl) => {
        if (backgroundUrl) {
          menu.backgroundUrl = backgroundUrl;
          this._save(menu);
          this.uploadImageFirstTime = false;
        }
      });
  }

  private _save(menu: Menu): void {
    this.sent = true;
    this.store.dispatch(new MenuAction(menu));
  }

  private _showSaving(loading: boolean) {
    this.loading = loading;
    this.saving = loading;
    if (loading) this.loadingMessage = 'Guardando...';
  }
}
