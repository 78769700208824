import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import {
  GetMenuAction,
  GetMenuInitializedAction,
} from '@store/admin/menu/actions/get-menu.actions';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends BreadcrumbComponent {
  businessId: string;
  menuId: string;
  loading: boolean = false;
  showTabs: boolean = false;
  titles: string[] = ['Menú', 'Categorías'];
  tabIndex: number = 0;

  constructor(public activatedRoute: ActivatedRoute, public store: Store) {
    super(activatedRoute, store);
  }

  onInit(): void {
    this.store.dispatch(new GetMenuInitializedAction());

    if (this.paramBusiness) {
      this.businessId = this.paramBusiness;
    }

    if (this.paramMenu) {
      this.menuId = this.paramMenu;
      this.showTabs = true;

      this.store.dispatch(new GetMenuAction(this.menuId));
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.index) this.tabIndex = params.index;
    });
  }

  initializeBreadcrumb() {
    if (this.paramMenu) {
      this.breadcrumb = {
        name: 'Menú',
        icon: 'menu_book',
        url: `b/${this.businessId}/m/${this.menuId}`,
        position: 1,
      } as Breadcrumb;
    } else {
      this.breadcrumb = {
        name: 'Menú',
        icon: 'menu_book',
        url: `b/${this.businessId}/menu`,
        position: 1,
      } as Breadcrumb;
    }
  }
}
