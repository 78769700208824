import { Injectable } from '@angular/core';
import { IDelete } from '@core/interfaces/delete.interface';
import { ISort, Sort } from '@core/interfaces/sort.interface';
import { Category, IdsCategory, Product } from '@core/models';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, mapTo } from 'rxjs/operators';
import { MenuService } from './menu.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CategoryService implements IDelete, ISort {
  private _MENU_ID = 'MENU_ID';
  private _SEGMENT = 'menus/MENU_ID/categories';
  private _KEY = '__c';
  private _CURRENT_CATEGORY = '__ccurrent';

  constructor(
    private afsvc: ApiFacadeService,
    private ldsvc: LocalDataService,
    private menuSvc: MenuService
  ) {}

  // Base methods
  delete(ids: IdsCategory): Observable<boolean> {
    const baseUrl: string = this.getBaseUrl(ids.menuId);
    return this.afsvc
      .delete({
        segment: `${baseUrl}/${ids.categoryId}`,
        storageOptions: {
          delete: true,
          key: `${this._KEY}${ids.categoryId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() =>
          this.menuSvc.removeCategoryFromMenu(ids.menuId, ids.categoryId)
        ),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  get(categoryId: string): Observable<Category> {
    const baseUrl: string = this.getBaseUrl();
    return this.afsvc
      .get<Category>({
        segment: `${baseUrl}/${categoryId}`,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${categoryId}`,
        },
        useGateway: true,
      })
      .pipe(
        map((category) => category),
        catchError((error) => throwError(error))
      );
  }

  save(category: Category): Observable<Category> {
    if (category.categoryId) {
      return this.update(category);
    } else {
      return this.create(category);
    }
  }

  sort(categoryId: string, products: Sort): Observable<Boolean> {
    const baseUrl: string = this.getBaseUrl();
    return this.afsvc
      .patch<Sort>({
        segment: `${baseUrl}/${categoryId}/products/sort`,
        data: products,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          const category: Category = this.ldsvc.get<Category>(
            `${this._KEY}${categoryId}`
          );
          category.sort = [...products.sort];
          this.ldsvc.set<Category>(`${this._KEY}${categoryId}`, category);
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  // Public methods

  addProductToCategory(product: Product): void {
    const category: Category = this.ldsvc.get<Category>(
      `${this._KEY}${product.categoryId}`
    );
    if (!category.products) category.products = [product];
    else category.products.push(product);
    if (!category.sort) category.sort = [];
    if (!category.sort.includes(product.productId)) {
      category.sort.push(product.productId);
    }
    this.ldsvc.set<Category>(`${this._KEY}${product.categoryId}`, category);
  }

  removeProductFromCategory(categoryId: string, productId: string): void {
    const category: Category = this.ldsvc.get<Category>(
      `${this._KEY}${categoryId}`
    );
    const products: Product[] = category.products.filter(
      (p) => p.productId !== productId
    );
    category.products = [...products];
    const sort: string[] = category.sort.filter((o) => o !== productId);
    category.sort = [...sort];
    this.ldsvc.set<Category>(`${this._KEY}${categoryId}`, category);
  }

  updateProductInMenu(product: Product): void {
    const category: Category = this.ldsvc.get<Category>(
      `${this._KEY}${product.categoryId}`
    );
    const products: Product[] = category.products.filter(
      (p) => p.productId !== product.productId
    );
    category.products = [...products, product];
    this.ldsvc.set<Category>(`${this._KEY}${product.categoryId}`, category);
  }

  // Private methods
  private create(category: Category): Observable<Category> {
    category.categoryId = uuidv4();
    const baseUrl: string = this.getBaseUrl();
    return this.afsvc
      .post<Category>({ segment: baseUrl, data: category, useGateway: true })
      .pipe(
        tap(() => {
          this.ldsvc.set<Category>(
            `${this._KEY}${category.categoryId}`,
            category
          );
          this.saveCurrent(category.categoryId);
          this.menuSvc.addCategoryToMenu(category);
        }),
        mapTo(category),
        catchError((error) => throwError(error))
      );
  }

  private update(category: Category): Observable<Category> {
    const baseUrl: string = this.getBaseUrl();
    return this.afsvc
      .put<Category>({
        segment: `${baseUrl}/${category.categoryId}`,
        data: category,
        storageOptions: {
          search: true,
          save: false,
          key: `${this._KEY}${category.categoryId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => {
          const currentCategory: Category = this.ldsvc.get<Category>(
            `${this._KEY}${category.categoryId}`
          );
          currentCategory.name = category.name;
          currentCategory.active = category.active;

          this.ldsvc.set<Category>(
            `${this._KEY}${category.categoryId}`,
            currentCategory
          );

          this.menuSvc.updateCategoryInMenu(category);
        }),
        mapTo(category),
        catchError((error) => throwError(error))
      );
  }

  saveCurrent = (categoryId: string): void =>
    this.ldsvc.set(this._CURRENT_CATEGORY, categoryId);
  getCurrent = (): string => this.ldsvc.get<string>(this._CURRENT_CATEGORY);

  private getBaseUrl(menuId?: string): string {
    if (!menuId) {
      menuId = this.menuSvc.getCurrent();
    }
    return this._SEGMENT.replace(this._MENU_ID, menuId);
  }
}
