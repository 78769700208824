import { Injectable } from '@angular/core';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { BreadcrumbService } from '@core/services/breadcrumb.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ClearBreadcrumbAction,
  PopBreadcrumbAction,
  PushBreadcrumbAction,
} from '../actions/breadcrumb.actions';

export class BreadcrumbStateModel {
  public currentBreadcrumb: Breadcrumb;
  public breadcrumbs: Breadcrumb[];
}

const defaults = {
  currentBreadcrumb: null,
  breadcrumbs: null,
};

@State<BreadcrumbStateModel>({
  name: 'Breadcrumb',
  defaults,
})
@Injectable()
export class BreadcrumbState {
  constructor(private breadcrumbSvc: BreadcrumbService) {}

  @Selector()
  public static get({ currentBreadcrumb }): Breadcrumb {
    return currentBreadcrumb;
  }

  @Selector()
  public static getAll({ breadcrumbs }): Breadcrumb[] {
    return breadcrumbs;
  }

  @Action(PushBreadcrumbAction)
  push(
    ctx: StateContext<BreadcrumbStateModel>,
    { breadcrumb }: PushBreadcrumbAction
  ) {
    const state = ctx.getState();
    const breadcrumbs: Breadcrumb[] = this.breadcrumbSvc.pushAndGet(breadcrumb);
    ctx.patchState({
      ...state,
      currentBreadcrumb: breadcrumb,
      breadcrumbs: breadcrumbs,
    });
  }

  @Action(PopBreadcrumbAction)
  pop(
    ctx: StateContext<BreadcrumbStateModel>,
    { positions }: PopBreadcrumbAction
  ) {
    const state = ctx.getState();
    const currentBreadcrumb: Breadcrumb = this.breadcrumbSvc.pop(positions);
    ctx.patchState({
      ...state,
      currentBreadcrumb: currentBreadcrumb,
      breadcrumbs: this.breadcrumbSvc.get(),
    });
  }

  @Action(ClearBreadcrumbAction)
  clear(
    ctx: StateContext<BreadcrumbStateModel>,
    { path }: ClearBreadcrumbAction
  ) {
    const state = ctx.getState();
    const result: boolean = this.breadcrumbSvc.clear(path);

    if (result) {
      ctx.patchState({
        ...state,
        currentBreadcrumb: null,
        breadcrumbs: null,
      });
    }
  }
}
