import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '@core/models/user.model';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(private afa: AngularFireAuth) {}

  async signIn(email: string, password: string): Promise<User> {
    const user: User = {} as User;
    try {
      const firebaseUser = await this.afa.signInWithEmailAndPassword(
        email,
        password
      );
      user.email = firebaseUser.user.email;
      user.emailVerified = firebaseUser.user.emailVerified;
      user.id = firebaseUser.user.uid;
    } catch (error) {
      throw new Error(this._handlerErrors(error));
    }

    return user;
  }

  async signUp(email: string, password: string): Promise<void> {
    try {
      const firebaseUser = await this.afa.createUserWithEmailAndPassword(
        email,
        password
      );
      if (firebaseUser) {
        await firebaseUser.user.sendEmailVerification();
      }
    } catch (error) {
      throw new Error(this._handlerErrors(error));
    }
  }

  async recoverPassword(email: string): Promise<void> {
    try {
      await this.afa.sendPasswordResetEmail(email);
    } catch (error) {
      throw new Error(this._handlerErrors(error));
    }
  }

  getToken(): Observable<any> {
    return from(
      this.afa.currentUser
        .then((currentUser) => {
          return currentUser.getIdToken(true);
        })
        .catch((error) => {
          throw new Error(this._handlerErrors(error));
        })
    ).pipe(
      map((token: any) => `Bearer ${token}`),
      catchError((error) => throwError(error))
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.afa.authState.pipe(map((user) => user !== null));
  }

  signOut() {
    return this.afa.signOut();
  }

  private _handlerErrors(error: any): any {
    let message: string =
      _AuthenticationFirebaseMessage.AUTH_ERRORS[error.code];
    if (!message) {
      message = error;
    }

    return message;
  }
}

class _AuthenticationFirebaseMessage {
  static USER_SUCCESSFULLY_ADDED: string =
    'Usuario agregadó satisfactoriamente.';
  static USER_SUCCESSFULLY_PASSWORD_RESET: string =
    'Se ha enviado un email para resetear tu contraseña.';

  // Messages for code errors in firebase
  static AUTH_ERRORS = {
    'auth/account-exists-with-different-credential':
      'Ya existe una cuenta con esas credenciales.',
    'auth/invalid-credential': 'Las credenciales no son validas.',
    'auth/operation-not-allowed': 'Operación no permitida.',
    'auth/user-disabled': 'El usuario esta deshabilitado.',
    'auth/user-not-found': 'El usuario no se encontró.',
    'auth/wrong-password': 'El usuario o password no es valido.',
    'auth/invalid-verification-code': 'El código de verificación no es valido.',
    'auth/invalid-verification-id': 'El Id de verificación no es valido.',
    'auth/user-not-verificated': 'No se a verificado el email del usuario.', // this error isn't firebase
    'auth/email-already-in-use': 'El email ya esta en uso por otra cuenta.',
  };
}
