import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OptionService } from '@core/https/admin/option.service';
import { Extra } from '@core/models/extra.model';
import { Option } from '@core/models/option.model';
import { Select } from '@ngxs/store';
import { GetExtraState } from '@store/admin/extra/states/get-extra.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
})
export class OptionListComponent extends ParamsComponent {
  @Select(GetExtraState.getExtra) extra$: Observable<Extra>;
  @Select(GetExtraState.getOptions) options$: Observable<Option[]>;

  buttonUrl: string;
  options: Option[];
  showList: boolean = true;
  extraId: string;
  sort: string[];

  constructor(
    public activatedRoute: ActivatedRoute,
    public optionService: OptionService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    this.buttonUrl = this.paramProduct
      ? `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.paramCategory}/p/${this.paramProduct}/e/${this.paramExtra}/option`
      : `b/${this.paramBusiness}/e/${this.paramExtra}/option`;

    this.options$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((options) => (this.options = options));

    this.extra$.pipe(takeUntil(this.unsubscribe$)).subscribe((extra) => {
      if (extra) {
        this.showList = extra.type !== 'OPEN';
        this.extraId = extra.extraId;
        this.sort = extra.sort;
      }
    });
  }
}
