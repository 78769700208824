import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ISort } from '@core/interfaces/sort.interface';
import { ListItem } from '@core/models/list-item.model';
import { AdminSortDialogComponent } from '../admin-sort-dialog/admin-sort-dialog.component';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss'],
})
export class AdminListComponent implements OnInit {
  @Input() buttonText: string;
  @Input() buttonUrl: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() id: string;
  @Input() list: ListItem[];
  @Input() noDataText: string = 'No hay elementos en la lista.';
  @Input() implSort: ISort;

  buttonSortText = 'Ordenar';

  constructor(private router: Router, public dialog: MatDialog) {}

  ngOnInit(): void {}

  onAdd(): void {
    this.router.navigate([`admin/${this.buttonUrl}`]);
  }

  onGoTo(url: string): void {
    this.router.navigate([`admin/${url}`]);
  }

  onOpenSortDialog(): void {
    const dialogRef = this.dialog.open(AdminSortDialogComponent, {
      data: { implSort: this.implSort, listItems: this.list, id: this.id },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
