import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { StepItem } from '@core/models/step-item.model';
import { FormResultWaiter, Waiter } from '@core/models/waiter.model';
import { Select, Store } from '@ngxs/store';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { GetBusinessAction } from '@store/admin/business/actions/get-business.actions';
import { GetBusinessState } from '@store/admin/business/states/get-business.state';
import { UserSaveAction } from '@store/admin/users/actions/user.actions';
import { UserState } from '@store/admin/users/states/user.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  @Select(UserState.loadInProgress) loading$: Observable<boolean>;
  @Select(GetBusinessState.getUsers) users$: Observable<Waiter[]>;
  @Select(UserState.result) result$: Observable<FormResultWaiter>;

  public breadcrumb: Breadcrumb;
  private loadingSubs: Subscription;
  private usersSubs: Subscription;
  private resultSubs: Subscription;
  private valueChangesSubs: Subscription;
  loading: boolean = true;
  formGroup: FormGroup;
  idUser: string;
  waiter: Waiter;
  idBusiness: string;
  sent: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnDestroy(): void {
    this.loadingSubs.unsubscribe();
    this.usersSubs.unsubscribe();
    this.resultSubs.unsubscribe();
    this.valueChangesSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.loadingSubs = this.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.idBusiness) this.idBusiness = params.idBusiness;
      if (params.idUser) {
        this.idUser = params.idUser;

        this.breadcrumb = {
          name: 'Usuario',
          icon: 'account_circle',
          url: `${this.idBusiness}/user/${this.idUser}`,
          position: 1
        } as Breadcrumb;
      } else {
        this.breadcrumb = {
          name: 'Usuario',
          icon: 'account_circle',
          url: `${this.idBusiness}/user`,
          position: 1
        } as Breadcrumb;
      }
      this.store.dispatch(
        new GetBusinessAction(this.idBusiness)
      );
      this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));
    });

    this.usersSubs = this.users$.subscribe((waiters) => {
      if (waiters) {
        this.waiter = waiters.find((value) => value.idUser == this.idUser);
      };
      
      this.createForm();
    });

    this.resultSubs = this.result$.subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });

        this.sent = false;
      } else if (result.success && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });

        this.sent = false;
        this.router.navigate([`admin/users`]);
      }
    });
  }

  createForm(): void {
    var name: string;
    var active: boolean = true;
    var allowOrderHere: boolean;
    var allowDelivery: boolean;
    var allowPickUp: boolean;
    var isAdmin: boolean;
    var idUser: string;

    if (this.waiter) {
      name = this.waiter.name;
      active = this.waiter.active;
      allowOrderHere = this.waiter.allowOrderHere;
      allowDelivery = this.waiter.allowDelivery;
      allowPickUp = this.waiter.allowPickUp;
      isAdmin = this.waiter.isAdmin;
      idUser = this.waiter.idUser;
    }

    this.formGroup = this.formBuilder.group({
      name: [name, [Validators.required, Validators.minLength(5)]],
      active: [active],
      allowOrderHere: [allowOrderHere],
      allowDelivery: [allowDelivery],
      allowPickUp: [allowPickUp],
      isAdmin: [isAdmin],
      idUser: this._getFormControlIdUser(idUser),
    });

    this.valueChangesSubs = this.formGroup.valueChanges.subscribe((input) => {
      if (
        input.allowDelivery === true ||
        input.allowPickUp === true ||
        input.allowOrderHere === true
      ) {
        this.formGroup.setErrors(null);
      } else {
        this.formGroup.setErrors({ require: true });
      }
    });
  }

  onSave(): void {
    if (this.formGroup.invalid) return;
    const user: Waiter = this._getWaiter();
    this._save(user);
  }

  private _save(waiter: Waiter): void {
    this.loading = true;
    this.sent = true;
    this.store.dispatch(new UserSaveAction(waiter));
  }

  private _getWaiter(): Waiter {
    return {
      active: this.formGroup.get('active').value ?? false,
      allowDelivery: this.formGroup.get('allowDelivery').value ?? false,
      allowOrderHere: this.formGroup.get('allowOrderHere').value ?? false,
      allowPickUp: this.formGroup.get('allowPickUp').value ?? false,
      idBusiness: this.idBusiness,
      idUser: this.formGroup.get('idUser').value,
      isAdmin: this.formGroup.get('isAdmin').value ?? false,
      name: this.formGroup.get('name').value,
      exists: this.waiter?.idUser ? true : false,
    } as Waiter;
  }

  private _getFormControlIdUser(idUser: string): FormControl {
    return new FormControl(
      {
        value: idUser,
        disabled: idUser ? 'disabled' : '',
      },
      [Validators.required, Validators.minLength(5)]
    );
  }
}
