import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IDelete, Ids } from '@core/interfaces/delete.interface';
import { Select, Store } from '@ngxs/store';
import { PopBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import {
  EraserAction,
  EraserClearAction,
} from '@store/admin/eraser/actions/eraser.actions';
import { EraserState } from '@store/admin/eraser/states/eraser.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-eraser-dialog',
  templateUrl: './eraser-dialog.component.html',
  styleUrls: ['./eraser-dialog.component.scss'],
})
export class EraserDialogComponent implements OnInit, OnDestroy {
  @Select(EraserState) result$: Observable<any>;

  unsubscribe$ = new Subject<void>();

  private ids: Ids;
  private implDelete: IDelete;
  private url: string;
  loading: boolean = false;
  question: string;
  tabIndex: number;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store,
    public dialogRef: MatDialogRef<EraserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.ids = this.data.ids;
    this.implDelete = this.data.implDelete;
    this.question = this.data.question;
    this.url = this.data.url;
    this.tabIndex = this.data.tabIndex;

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (!response) return;

      if (response.result && response.result.error) {
        console.error(response.result.message);
        this.snackBar.open('No se pudó eliminar la opción!', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
        this.loading = false;
        return;
      }

      if (response.result && response.result.success) {
        this.loading = false;
        this.dialogRef.close();
        this.store.dispatch(EraserClearAction);

        this.store.dispatch(new PopBreadcrumbAction(1));
        this.router.navigate([`admin/${this.url}`], {
          queryParams: { index: this.tabIndex },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.loading = true;
    this.store.dispatch(new EraserAction(this.implDelete, this.ids));
  }
}
