import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { ParamsModel } from './params.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ParamsComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  params: ParamsModel;
  paramBusiness: string;
  paramMenu: string;
  paramCategory: string;
  paramProduct: string;
  paramExtra: string;
  paramOption: string;
  url: string;

  constructor(public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.paramBusiness = params?.idBusiness;
      this.paramMenu = params?.idMenu;
      this.paramCategory = params?.idCategory;
      this.paramProduct = params?.idProduct;
      this.paramExtra = params?.idExtra;
      this.paramOption = params?.idOption;

      if (this.paramBusiness) this.url = `b/${this.paramBusiness}`;

      if (this.paramMenu) this.url += `/m/${this.paramMenu}`;

      if (this.paramCategory) this.url += `/c/${this.paramCategory}`;

      if (this.paramProduct) this.url += `/p/${this.paramProduct}`;

      if (this.paramExtra) this.url += `/e/${this.paramExtra}`;
    });

    this.onInit();
    this.setModel();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  onDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  abstract onInit(): void;

  private setModel(): void {
    this.params = {
      businessId: this.paramBusiness,
      menuId: this.paramMenu,
      categoryId: this.paramCategory,
      productId: this.paramProduct,
      extraId: this.paramExtra,
      optionId: this.paramOption,
    } as ParamsModel;
  }
}
