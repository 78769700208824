<h2 mat-dialog-title>¡Es momento de iniciar con tu Menú!</h2>
<div mat-dialog-content>
  <p>
    En la parte superior encontrarás la pestaña titulada <b>'Menús'</b>, haz
    clic en ella para comenzar a crear tu primer menú.
  </p>
</div>
<div class="col-xs-12 col-md-offset-6 col-md-6 div_button__align">
  <button class="form__button button__start" (click)="closeDialog()">
    Comenzar
  </button>
</div>
