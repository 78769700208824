import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  EraserAction,
  EraserClearAction,
  EraserFailedAction,
  EraserSuccessAction,
} from '../actions/eraser.actions';

export class EraserStateModel {
  public loading: boolean;
  public result: any;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
  },
};

@State<EraserStateModel>({
  name: 'eraser',
  defaults,
})
@Injectable()
export class EraserState {
  private _successMessage = 'El objecto se elimino con éxito.';

  constructor() {}

  @Selector()
  public static saving({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(EraserAction)
  delete(
    ctx: StateContext<EraserStateModel>,
    { implDelete, ids }: EraserAction
  ) {
    this._saving(ctx);

    return implDelete.delete(ids).pipe(
      tap((result) => {
        ctx.dispatch(new EraserSuccessAction(result));
      }),
      catchError((error) => {
        ctx.dispatch(new EraserFailedAction(error));
        return of(false);
      })
    );
  }

  @Action(EraserSuccessAction)
  success(
    ctx: StateContext<EraserStateModel>,
    { response }: EraserSuccessAction
  ) {
    const state = ctx.getState();

    const result: any = {
      error: false,
      success: response,
      message: this._successMessage,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(EraserFailedAction)
  fail(ctx: StateContext<EraserStateModel>, { message }: EraserFailedAction) {
    const state = ctx.getState();

    const result: any = {
      error: true,
      success: false,
      message: message,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(EraserClearAction)
  clear(ctx: StateContext<EraserStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      result: null,
    });
  }

  private _saving(ctx: StateContext<EraserStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true, result: null });
  }
}
