import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '@core/https/admin/category.service';
import { Category, FormResultCategory } from '@core/models/category.model';
import { Select, Store } from '@ngxs/store';
import { PopBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { CategoryAction } from '@store/admin/category/actions/category.actions';
import { CategoryState } from '@store/admin/category/states/category.state';
import { GetCategoryState } from '@store/admin/category/states/get-category.state';
import { GetMenuAction } from '@store/admin/menu/actions/get-menu.actions';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
})
export class CategoryFormComponent extends ParamsComponent {
  @Input() menuId: string;
  @Select(GetCategoryState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetCategoryState.getting) getting$: Observable<boolean>;
  @Select(GetCategoryState.getCategory) category$: Observable<Category>;
  @Select(CategoryState.result) result$: Observable<FormResultCategory>;
  @Select(CategoryState.saving) saving$: Observable<boolean>;

  private sent: boolean = false;

  category: Category;
  errorMessage: string;
  formGroup: FormGroup;
  getting: boolean = false;
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';
  saving: boolean = false;
  showEraserComponent: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store,
    public activatedRoute: ActivatedRoute,
    public categorySvc: CategoryService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    if (this.paramCategory) this.showEraserComponent = true;

    this.category$.pipe(takeUntil(this.unsubscribe$)).subscribe((category) => {
      if (category) {
        this.category = category;
      }
      this.createForm();
    });

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.getting$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.saving) {
        this.loading = loading;
        this.getting = loading;
        if (loading) this.loadingMessage = 'Cargando...';
      }
    });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });

        this.sent = false;
      } else if (result.success && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });

        this.sent = false;
        this.category = result.category;

        this.createForm();
        this.store.dispatch(new PopBreadcrumbAction(1));
        this.store.dispatch(new GetMenuAction(this.menuId));
        this.router.navigate(
          [`admin/b/${this.paramBusiness}/m/${this.menuId}`],
          { queryParams: { index: '1' } }
        );
      }
    });

    this.saving$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.getting) {
        this.loading = loading;
        this.saving = loading;
        if (loading) this.loadingMessage = 'Guardando...';
      }
    });
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const category: Category = {
      ...this.category,
      active: this.formGroup.get('active').value,
      menuId: this.menuId,
      name: this.formGroup.get('name').value,
    } as Category;

    this._save(category);
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [
        this.category ? this.category.name : '',
        [Validators.required, Validators.minLength(2)],
      ],
      active: [this.category ? this.category.active : true],
    });
  }

  private _save(category: Category): void {
    this.sent = true;
    this.store.dispatch(new CategoryAction(category));
  }
}
