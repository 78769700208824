import { Product } from "@core/models/product.model";

export class ProductAction {
  static readonly type = '[Product]';
  constructor(public product: Product) {}
}

export class ProductSuccessAction {
  static readonly type = '[Product] Success';
  constructor(public product: Product) {}
}

export class ProductFailedAction {
  static readonly type = '[Product] Failed';
  constructor(public message: string) {}
}