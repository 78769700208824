import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appShowLoading]',
})
export class ShowLoadingDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  onChange(event: any) {
    document.querySelector('#public-loading').classList.add('public-loading');
  }
}
