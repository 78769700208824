import { Observable, Subject } from 'rxjs';

export class FileItem {
  public name: string;
  public uploading: boolean;
  public downloadUrl: Subject<string>;
  public uploadPercent: Observable<number>;

  constructor(public file: File = file) {
    this.name = file.name;
    this.uploading = false;

    this.downloadUrl = new Subject<string>();
  }
}
