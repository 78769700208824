import { Injectable } from '@angular/core';
import { CustomErrorHandler } from '@core/handlers/custom-error.handler';
import { ProfileService } from '@core/https/admin/profile.service';
import { FormResultProfile, Profile } from '@core/models/profile.model';
import { State, Action, StateContext, Selector, StateToken } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  SaveProfileAction,
  SaveProfileFailAction,
  SaveProfileSuccessAction,
} from '../actions/save-profile.actions';

export class ProfileStateModel {
  public loading: boolean;
  public result: FormResultProfile;
}

const SAVE_PROFILE_STATE_TOKEN = new StateToken<ProfileStateModel>(
  'saveProfile'
);

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    profile: null,
  } as FormResultProfile,
};

@State<ProfileStateModel>({
  name: SAVE_PROFILE_STATE_TOKEN,
  defaults,
})
@Injectable()
export class SaveProfileState {
  private _successMessage = 'Tu perfil fue actualizado con éxito.';

  constructor(private profileSvc: ProfileService) {}

  @Selector([SAVE_PROFILE_STATE_TOKEN])
  public static inProgress({ loading }) {
    return loading;
  }

  @Selector([SAVE_PROFILE_STATE_TOKEN])
  public static getResult({ result }) {
    return result;
  }

  @Action(SaveProfileAction)
  save(ctx: StateContext<ProfileStateModel>, { profile }: SaveProfileAction) {
    this.inProgress(ctx);

    return this.profileSvc.save(profile).pipe(
      tap((profile: Profile) => {
        ctx.dispatch(new SaveProfileSuccessAction(profile));
      }),
      catchError((error) => {
        ctx.dispatch(new SaveProfileFailAction(CustomErrorHandler.get(error)));
        return of();
      })
    );
  }

  @Action(SaveProfileSuccessAction)
  success(
    ctx: StateContext<ProfileStateModel>,
    { profile }: SaveProfileSuccessAction
  ) {
    const state = ctx.getState();

    const result: FormResultProfile = {
      error: false,
      success: true,
      message: this._successMessage,
      profile: profile,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(SaveProfileFailAction)
  fail(
    ctx: StateContext<ProfileStateModel>,
    { message }: SaveProfileFailAction
  ) {
    const state = ctx.getState();

    const result: FormResultProfile = {
      error: true,
      success: false,
      message: message,
      profile: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private inProgress(ctx: StateContext<ProfileStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
