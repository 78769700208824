import { Directive, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';

@Directive({
  selector: '[appBehaivorMenu]'
})
export class BehaivorMenuDirective {

  constructor(private store: Store) {}

  @HostListener('click', ['$event'])
  onClickMenu(){
    this.store.dispatch(new StateClear());
    localStorage.clear();
  }
}