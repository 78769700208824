import { Injectable } from '@angular/core';
import { ExtraService } from '@core/https/admin/extra.service';
import { Extra } from '@core/models/extra.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetExtraAction,
  GetExtraFailedAction,
  GetExtraInitializedAction,
  GetExtraSuccessAction,
} from '../actions/get-extra.actions';

export class GetExtraStateModel {
  public loading: boolean;
  public extra: Extra;
  public message: string;
}

const defaults = {
  loading: false,
  extra: null,
  message: null,
};

@State<GetExtraStateModel>({
  name: 'getExtra',
  defaults,
})
@Injectable()
export class GetExtraState {
  constructor(private extraSvc: ExtraService) {}

  @Selector()
  public static getOptions({ extra }) {
    if (extra.options && extra.options.length > 0) {
      return extra.options;
    }

    return null;
  }

  @Selector()
  public static getting({ loading }) {
    return loading;
  }

  @Selector()
  public static getExtra({ extra }) {
    return extra;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(GetExtraAction)
  get(ctx: StateContext<GetExtraStateModel>, { idExtra }: GetExtraAction) {
    this._getting(ctx);

    return this.extraSvc.get(idExtra).pipe(
      tap((extra) => {
        this.extraSvc.saveCurrent(extra.extraId);
        ctx.dispatch(new GetExtraSuccessAction(extra));
      }),
      catchError((error) => {
        ctx.dispatch(new GetExtraFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(GetExtraFailedAction)
  getFailed(
    ctx: StateContext<GetExtraStateModel>,
    { message }: GetExtraFailedAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      extra: null,
      message: message,
    });
  }

  @Action(GetExtraInitializedAction)
  initialize(ctx: StateContext<GetExtraStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      extra: null,
      message: null,
    });
  }

  @Action(GetExtraSuccessAction)
  getSuccess(
    ctx: StateContext<GetExtraStateModel>,
    { extra }: GetExtraSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      extra: extra,
    });
  }

  private _getting(ctx: StateContext<GetExtraStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
