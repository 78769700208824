<app-admin-conteiner>
    <section content>
        <app-admin-title *ngIf="breadcrumb"></app-admin-title>
        <div class="content-form__wrapper">
            <ng-container *ngIf="!loading; then userForm else userLoading"></ng-container>
        </div>
    </section>
</app-admin-conteiner>

<ng-template #userForm>
    <div class="row--width">
        <form [formGroup]="formGroup" (submit)="onSave()">
            <div class="row center-xs">
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Usuario</mat-label>
                        <input matInput maxlength="15" formControlName="idUser" name="idUser" placeholder="Usuario" #inputUser required>
                        <mat-hint align="end">{{ inputUser.value?.length || 0 }} / 15</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Nombre de usuario</mat-label>
                        <input matInput maxlength="30" formControlName="name" name="name" placeholder="Nombre de usuario" #inputName required>
                        <mat-hint align="end">{{ inputName.value?.length || 0 }} / 30</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-xs-12">
                    <mat-checkbox class="form__field" formControlName="active" name="active" color="primary">Activo</mat-checkbox>
                </div>
                <div class="col-xs-12">
                    <h4>Tipos de entregas permitidas</h4>
                </div>
                <div class="col-xs-12 col-md-4">
                    <mat-checkbox class="form__field" formControlName="allowOrderHere" name="allowOrderHere" color="primary">Ordenar aquí</mat-checkbox>
                </div>
                <div class="col-xs-12 col-md-4">
                    <mat-checkbox class="form__field" formControlName="allowPickUp" name="allowPickUp" color="primary">Recoger</mat-checkbox>
                </div>
                <div class="col-xs-12 col-md-4">
                    <mat-checkbox class="form__field" formControlName="allowDelivery" name="allowDelivery" color="primary">A domicilio</mat-checkbox>
                </div>
                <div class="col-xs-12 user__col user__description">
                    <span>Los usuarios administrador ven todos los pedidos solicitados, aceptados y completados sin importar el dispositivo del que fueron atendidos.</span>
                </div>
                <div class="col-xs-12">
                    <mat-checkbox class="form__field" formControlName="isAdmin" name="isAdmin" color="primary">Es administrador</mat-checkbox>
                </div>
                <div class="col-xs-12 user__col">
                    <button type="submit" class="form__button" mat-raised-button [disabled]="formGroup.invalid">Guardar</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #userLoading>
    <app-loading message="Guardando..."></app-loading>
</ng-template>