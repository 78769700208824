<div class="container--width">
    <app-toolbar isExtendedToolbar="true"></app-toolbar>
    <div class="row row--width center-xs">
        <div class="col-xs-12">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
    <app-footer></app-footer>
</div>

