import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import * as equal from 'fast-deep-equal';

@Injectable({
  providedIn: 'root',
})
export class LocalDataService {
  constructor() {}

  containsKey(key: string): boolean {
    if (localStorage.getItem(key) === null) return false;

    return true;
  }

  equals<T>(key: string, value: T): boolean {
    if (this.containsKey(key)) {
      const storageValue: T = JSON.parse(localStorage.getItem(key)) as T;
      return equal(value, storageValue);
    }

    return false;
  }

  equalsObs<T>(key: string, value: T): Observable<boolean> {
    if (this.containsKey(key)) {
      const storageValue: T = JSON.parse(localStorage.getItem(key)) as T;
      return of(equal(value, storageValue));
    }

    return of(false);
  }

  get<T>(key: string): T {
    let item: T;

    if (this.containsKey(key)) {
      item = JSON.parse(localStorage.getItem(key)) as T;
    }

    return item;
  }

  getMany<T>(key: string): T[] {
    let collection: T[] = [];

    if (this.containsKey(key)) {
      collection = JSON.parse(localStorage.getItem(key)) as T[];
    }

    return collection;
  }

  getObs<T>(key: string): Observable<T> {
    let item: T;

    if (this.containsKey(key)) {
      item = JSON.parse(localStorage.getItem(key)) as T;
    }

    return of(item);
  }

  getManyObs<T>(key: string): Observable<T[]> {
    let collection: T[] = [];

    if (this.containsKey(key)) {
      collection = JSON.parse(localStorage.getItem(key)) as T[];
    }

    return of(collection);
  }

  remove(key: string): void {
    if (this.containsKey(key)) {
      localStorage.removeItem(key);
    }
  }

  set<T>(key: string, value: T): void {
    if (this.containsKey(key)) {
      localStorage.removeItem(key);
    }

    localStorage.setItem(key, JSON.stringify(value));
  }

  setMany<T>(key: string, collection: T[]): void {
    if (this.containsKey(key)) {
      localStorage.removeItem(key);
    }

    localStorage.setItem(key, JSON.stringify(collection));
  }
}
