import { Contact } from '@core/models/contact.model';

export class ContactAction {
  static readonly type = '[Contact]';
  constructor(public contact: Contact) {}
}

export class ContactSuccessAction {
  static readonly type = '[Contact] Success';
  constructor() {}
}

export class ContactFailedAction {
  static readonly type = '[Contact] Failed';
  constructor(public message: string) {}
}
