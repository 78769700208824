import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, Customer, Message } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { SnackbarHelper } from '@shared/helpers/snackbar.helper';
import { CustomerActions } from '@store/admin/customer/customer.actions';
import { CustomerState } from '@store/admin/customer/customer.state';
import { MessageTemplateActions } from '@store/admin/message-template/message-template.actions';
import { MessageTemplateState } from '@store/admin/message-template/message-template.state';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-promotions',
  templateUrl: './admin-promotions.component.html',
  styleUrls: ['./admin-promotions.component.scss'],
})
export class AdminPromotionsComponent extends BreadcrumbComponent {
  @Select(CustomerState.getCustomers) getCustomers$: Observable<Customer[]>;
  @Select(CustomerState.getErrorMessage) getErrorMessage$: Observable<string>;
  @Select(MessageTemplateState.getCurrentMessage)
  getCurrentMessage$: Observable<Message>;
  tabIndex: number = 0;
  customers: Customer[];
  dataSource = new MatTableDataSource<Customer>([]);
  displayedColumns = [
    'register',
    'name',
    'phone',
    'email',
    'birthday',
    'lastMonthTotal',
    'currentMonthTotal',
    'actions',
  ];
  currentMessage: Message;
  loading = false;

  constructor(
    public route: ActivatedRoute,
    public store: Store,
    private snackBar: SnackbarHelper
  ) {
    super(route, store);
  }

  onInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params.index) this.tabIndex = params.index;
      });

    this.getCustomers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customers) => {
        if (!customers) {
          return;
        }
        this.customers = customers;
        this.dataSource.data = this.customers;
        this.loading = false;
      });

    this.getErrorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (!errorMessage) {
          return;
        }
        this.loading = false;
        console.error('errorMessage === >>>', errorMessage);
      });

    this.getCurrentMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentMessage) => {
        if (!currentMessage) {
          return;
        }
        this.currentMessage = currentMessage;
      });
    this.loading = true;
    this.store.dispatch(new CustomerActions.Get());
  }

  initializeBreadcrumb(): void {
    this.breadcrumb = {
      name: 'Administrador de promociones',
      icon: 'campaign',
      url: 'promotions/admin',
      position: 0,
    } as Breadcrumb;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  sendMessage(row) {
    const templateValid = this.validateTemplateMessage();
    if (!templateValid) {
      return;
    }
    let nombre: string = row.name.trim().toLowerCase().split(' ')[0];
    nombre =
      nombre.substring(0, 1).toUpperCase() + nombre.substring(1, nombre.length);
    let texturl: string = `https://api.whatsapp.com/send?phone=521${row.phone}&text=`;
    let text = this.currentMessage.message.replace('#nombre#', nombre);
    text += '\n\n_Este mensaje ha sido creado a través de_ 🚀 *_NotMenu._*';
    const message = text;
    text = encodeURI(text);
    window.open(texturl + text, '_blank');
    this.store.dispatch(
      new MessageTemplateActions.Send({
        ...this.currentMessage,
        message,
        customerPhone: row.phone,
      })
    );
  }

  validateTemplateMessage(): boolean {
    if (!this.currentMessage?.message) {
      this.snackBar.showError('Debe seleccionar un template');
      return false;
    }
    return true;
  }

  private static examples = [
    {
      created: '2023-05-20T00:00:00Z',
      name: 'María Pérez',
      phone: '5567890123',
      email: 'maria.perez@gmail.com',
      birthday: '1995-07-12',
      totalExpensePerMonth: 1650,
    },
    {
      created: '2023-11-07T00:00:00Z',
      name: 'Juan García',
      phone: '5598741236',
      email: 'juan.garcia@outlook.com',
      birthday: '1983-04-25',
      totalExpensePerMonth: 1225,
    },
    {
      created: '2023-09-15T00:00:00Z',
      name: 'Luisa Martínez',
      phone: '5567123458',
      email: 'luisa.martinez@gmail.com',
      birthday: '1990-10-30',
      totalExpensePerMonth: 750,
    },
    {
      created: '2024-03-04T00:00:00Z',
      name: 'Carlos López',
      phone: '5589012345',
      email: 'carlos.lopez@outlook.com',
      birthday: '1988-02-15',
      totalExpensePerMonth: 1300,
    },
    {
      created: '2023-12-19T00:00:00Z',
      name: 'Ana Sánchez',
      phone: '5578987654',
      email: 'ana.sanchez@gmail.com',
      birthday: '1992-09-03',
      totalExpensePerMonth: 1750,
    },
    {
      created: '2023-07-30T00:00:00Z',
      name: 'Pedro Rodríguez',
      phone: '5590654321',
      email: 'pedro.rodriguez@outlook.com',
      birthday: '1985-11-18',
      totalExpensePerMonth: 1850,
    },
    {
      created: '2023-03-12T00:00:00Z',
      name: 'Laura Gómez',
      phone: '5567123459',
      email: 'laura.gomez@gmail.com',
      birthday: '1989-06-22',
      totalExpensePerMonth: 1000,
    },
    {
      created: '2024-01-28T00:00:00Z',
      name: 'Javier Hernández',
      phone: '5578998765',
      email: 'javier.hernandez@outlook.com',
      birthday: '1977-12-10',
      totalExpensePerMonth: 1600,
    },
    {
      created: '2023-08-05T00:00:00Z',
      name: 'Alejandra Díaz',
      phone: '5590321564',
      email: 'alejandra.diaz@outlook.com',
      birthday: '1993-03-28',
      totalExpensePerMonth: 1450,
    },
    {
      created: '2023-10-21T00:00:00Z',
      name: 'Francisco Ruiz',
      phone: '5598091234',
      email: 'francisco.ruiz@gmail.com',
      birthday: '1980-08-14',
      totalExpensePerMonth: 950,
    },
  ];
}
