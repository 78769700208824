import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CallApiService {
  constructor(private http: HttpClient) {}

  get<T>({
    urlSegment,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    useGateway?: boolean;
  }): Observable<T> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (headers) {
      return this.http
        .get<T>(`${baseUrl}/${urlSegment}`, {
          headers: headers,
        })
        .pipe(catchError((error) => throwError(error)));
    }

    return this.http
      .get<T>(`${baseUrl}/${urlSegment}`)
      .pipe(catchError((error) => throwError(error)));
  }

  getText({
    urlSegment,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    useGateway?: boolean;
  }): Observable<string> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (headers) {
      return this.http
        .get(`${baseUrl}/${urlSegment}`, {
          headers: headers,
          responseType: 'text',
        })
        .pipe(catchError((error) => throwError(error)));
    }

    return this.http
      .get(`${baseUrl}/${urlSegment}`, { responseType: 'text' })
      .pipe(catchError((error) => throwError(error)));
  }

  getMany<T>({
    urlSegment,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    useGateway?: boolean;
  }): Observable<T[]> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (headers) {
      return this.http
        .get<T[]>(`${baseUrl}/${urlSegment}`, { headers: headers })
        .pipe(catchError((error) => throwError(error)));
    }

    return this.http
      .get<T[]>(`${baseUrl}/${urlSegment}`)
      .pipe(catchError((error) => throwError(error)));
  }

  post<T>({
    urlSegment,
    data,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    data: any;
    headers?: HttpHeaders;
    useGateway?: boolean;
  }): Observable<void | T> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (!headers) {
      headers = new HttpHeaders();
    }

    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post<T>(`${baseUrl}/${urlSegment}`, data, {
        headers: headers,
      })
      .pipe(
        mapTo(data),
        catchError((error) => throwError(error))
      );
  }

  put<T>({
    urlSegment,
    data,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    data: any;
    headers?: HttpHeaders;
    useGateway?: boolean;
  }): Observable<void | T> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    return this.http.put<T>(`${baseUrl}/${urlSegment}`, data, {
      headers: headers,
    });
  }

  patch({
    urlSegment,
    data,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    data: any;
    headers?: HttpHeaders;
    useGateway?: boolean;
  }): Observable<void> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (!headers) {
      headers = new HttpHeaders();
    }

    headers = headers.set('Content-Type', 'application/json');
    return this.http.patch<void>(`${baseUrl}/${urlSegment}`, data, {
      headers: headers,
    });
  }

  putBoolean({
    urlSegment,
    data,
    headers,
    useGateway = false,
  }: {
    urlSegment: string;
    data: any;
    headers?: HttpHeaders;
    useGateway?: boolean;
  }): Observable<void | boolean> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (!headers) {
      headers = new HttpHeaders();
    }

    headers = headers.set('Content-Type', 'application/json');
    return this.http.put<boolean>(`${baseUrl}/${urlSegment}`, data, {
      headers: headers,
    });
  }

  delete({
    urlSegement,
    headers,
    useGateway = false,
  }: {
    urlSegement: string;
    headers?: HttpHeaders;
    useGateway?: boolean;
  }): Observable<void | Object> {
    const baseUrl: string = this.getBaseUrl(useGateway);
    if (!headers) {
      headers = new HttpHeaders();
    }

    return this.http.delete(`${baseUrl}/${urlSegement}`, {
      headers: headers,
    });
  }

  private getBaseUrl(useGateway: boolean): string {
    return useGateway ? environment.gateway : environment.api;
  }
}
