import { Injectable } from '@angular/core';
import { MenuService } from '@core/https/admin/menu.service';
import { Menu } from '@core/models/menu.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetMenuAction,
  GetMenuFailedAction,
  GetMenuInitializedAction,
  GetMenuSuccessAction,
} from '../actions/get-menu.actions';

export class GetMenuStateModel {
  public loading: boolean;
  public menu: Menu;
  public message: string;
}

const defaults = {
  loading: false,
  menu: null,
  message: null,
};

@State<GetMenuStateModel>({
  name: 'getMenu',
  defaults,
})
@Injectable()
export class GetMenuState {
  constructor(private menuSvc: MenuService) {}

  @Selector()
  public static getCategories({ menu }) {
    if (menu.categories && menu.categories.length > 0) {
      return menu.categories;
    }

    return null;
  }

  @Selector()
  public static getting({ loading }) {
    return loading;
  }

  @Selector()
  public static getMenu({ menu }) {
    return menu;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(GetMenuAction)
  send(ctx: StateContext<GetMenuStateModel>, { menuId }: GetMenuAction) {
    this._getting(ctx);

    return this.menuSvc.get(menuId).pipe(
      tap((menu) => {
        this.menuSvc.saveCurrent(menu.menuId);
        ctx.dispatch(new GetMenuSuccessAction(menu));
      }),
      catchError((error) => {
        ctx.dispatch(new GetMenuFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(GetMenuFailedAction)
  getFailed(
    ctx: StateContext<GetMenuStateModel>,
    { message }: GetMenuFailedAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      menu: null,
      message: message,
    });
  }

  @Action(GetMenuInitializedAction)
  initialize(ctx: StateContext<GetMenuStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      menu: null,
      message: null,
    });
  }

  @Action(GetMenuSuccessAction)
  getSuccess(
    ctx: StateContext<GetMenuStateModel>,
    { menu }: GetMenuSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      menu: menu,
    });
  }

  private _getting(ctx: StateContext<GetMenuStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
