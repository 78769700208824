import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthContainerComponent } from './components/auth-container/auth-container.component';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { TermsAndConditionsDialogComponent } from './components/sign-up/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { SignedUpDialogComponent } from './components/sign-up/signed-up-dialog/signed-up-dialog.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';

@NgModule({
  declarations: [
    SignInComponent,
    AuthContainerComponent,
    SignUpComponent,
    TermsAndConditionsDialogComponent,
    SignedUpDialogComponent,
    RecoverPasswordComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    MaterialModule,
    SharedModule,
  ],
})
export class AuthModule {}
