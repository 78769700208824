import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Extra } from '@core/models/extra.model';
import { Select } from '@ngxs/store';
import { GetBusinessState } from '@store/admin/business/states/get-business.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-extra-list',
  templateUrl: './extra-list.component.html',
  styleUrls: ['./extra-list.component.scss'],
})
export class ExtraListComponent extends ParamsComponent {
  @Input() businessId: string;
  @Select(GetBusinessState.getExtras) extras$: Observable<Extra[]>;

  buttonUrl: string;
  extras: Extra[];

  constructor(public activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  onInit(): void {
    this.paramBusiness = this.businessId;

    this.buttonUrl = this.paramProduct
      ? `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.paramCategory}/p/${this.paramProduct}/extra`
      : `b/${this.paramBusiness}/extra`;

    this.extras$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((extras) => (this.extras = extras));
  }
}
