import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { ClearBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';

@Directive({
  selector: '[appBehaviorBreadcrumb]',
})
export class BehaviorBreadcrumbDirective {
  @Input() path: string;

  constructor(private store: Store) {}

  @HostListener('click', ['$event'])
  onClickMenu() {
    this.store.dispatch(new ClearBreadcrumbAction(this.path));
  }
}
