<div class="row--width">
  <mat-progress-bar mode="buffer"></mat-progress-bar>
  <div class="row center-xs">
    <div class="col-xs-10">
      <span
        >Tu negocio fue desactivado comunicate con NotMenu por medio del
        contacto en el menú de la izquierda o en
        <a href="https://notmenu.com" target="_blank" rel="noopener noreferrer"
          >notmenu.com</a
        ></span
      >
    </div>
  </div>
</div>
