<table mat-table [dataSource]="ordersData.orders" class="full-width">
  <!-- Customer name -->
  <ng-container matColumnDef="customerName">
    <th mat-header-cell *matHeaderCellDef class="center-text">Cliente</th>
    <td mat-cell *matCellDef="let element" class="center-text">
      {{ element.customerName }}
    </td>
  </ng-container>

  <!-- Customer phone -->
  <ng-container matColumnDef="customerPhone">
    <th mat-header-cell *matHeaderCellDef class="center-text">
      Teléfono del cliente
    </th>
    <td mat-cell *matCellDef="let element" class="center-text">
      {{ element.customerPhone }}
    </td>
  </ng-container>

  <!-- Total -->
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef class="center-text">Total</th>
    <td mat-cell *matCellDef="let element" class="center-text">
      {{ element.total | currency }}
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef class="center-text">Fecha y hora</th>
    <td mat-cell *matCellDef="let element" class="center-text">
      {{ element.createdAt | date : "dd/MM/yyyy hh:ss a" }}
    </td>
  </ng-container>

  <!-- Products Column -->
  <ng-container matColumnDef="products">
    <th mat-header-cell *matHeaderCellDef class="center-text">Productos</th>
    <td mat-cell *matCellDef="let element" class="center-text">
      <button
        mat-icon-button
        aria-label="Menu"
        style="color: rgb(80, 188, 133)"
        (click)="onSelectProducts(element.products)"
      >
        <mat-icon>visibility</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
