import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '@core/models/list-item.model';
import { Product } from '@core/models/product.model';
import { ParamsModel } from '../core/params.model';

@Pipe({
  name: 'fromProductsToListItem',
})
export class FromProductsToListItemPipe implements PipeTransform {
  transform(
    products: Product[],
    sort: string[],
    paramsModel: ParamsModel
  ): ListItem[] {
    if (!products?.length || !sort?.length) {
      return null;
    }
    let productsObject = products.reduce(
      (prev, next) => ({
        ...prev,
        [next.productId]: next,
      }),
      {}
    );
    return sort.map((productId) => {
      let current = productsObject[productId];
      return {
        id: current.productId,
        text: current.name,
        url: `b/${paramsModel.businessId}/m/${paramsModel.menuId}/c/${paramsModel.categoryId}/p/${current.productId}`,
        active: current.active,
      } as ListItem;
    });
  }
}
