import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ListItem } from '@core/models/list-item.model';
import { ISort, Sort } from '@core/interfaces/sort.interface';

@Component({
  selector: 'app-admin-sort-dialog',
  templateUrl: './admin-sort-dialog.component.html',
  styleUrls: ['./admin-sort-dialog.component.scss'],
})
export class AdminSortDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AdminSortDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  loading: boolean = false;
  private id: string;
  private implSort: ISort;
  listItems: ListItem[];

  ngOnInit(): void {
    this.listItems = this.data.listItems;
    this.implSort = this.data.implSort;
    this.id = this.data.id;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDrop(event: CdkDragDrop<ListItem[]>) {
    moveItemInArray(this.listItems, event.previousIndex, event.currentIndex);
  }

  onSave(): void {
    this.loading = true;
    const sort: Sort = { sort: this.listItems.map((p) => p.id) };
    this.implSort.sort(this.id, sort).subscribe((saved) => {
      if (saved) {
        this.dialogRef.close();
      }
    });
  }
}
