import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { MessageTemplateActions } from './message-template.actions';
import { MessageService } from '@core/https/admin/message.service';
import { BusinessService } from '@core/https/admin/business.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from '@core/models';

export class MessageTemplateStateModel {
  public messages: Message[];
  public message: string;
  public currentMessage: Message;
  public messageSaved: Message;
  public messageSavedFailure: string;
  public messageSend: Message;
  public messageSendFailure: string;
}

const defaults = {
  messages: null,
  message: null,
  currentMessage: null,
  messageSaved: null,
  messageSavedFailure: null,
  messageSend: null,
  messageSendFailure: null,
};

@State<MessageTemplateStateModel>({
  name: 'messageTemplate',
  defaults,
})
@Injectable()
export class MessageTemplateState {
  constructor(
    private messageService: MessageService,
    private businessService: BusinessService
  ) {}

  @Selector()
  public static getMessages({ messages }) {
    return messages;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Selector()
  public static getCurrentMessage({ currentMessage }) {
    return currentMessage;
  }

  @Selector()
  public static messageSaved({ messageSaved }) {
    return messageSaved;
  }

  @Selector()
  public static messageSavedFailure({ messageSavedFailure }) {
    return messageSavedFailure;
  }

  @Action(MessageTemplateActions.Get)
  get({ dispatch }: StateContext<MessageTemplateStateModel>) {
    const businessId = this.businessService.getCurrent();
    return this.messageService.getMessages(businessId).pipe(
      tap((messages) => {
        dispatch(new MessageTemplateActions.GetSuccess(messages));
      }),
      catchError((error) => {
        dispatch(new MessageTemplateActions.GetFailure(error.message));
        return of();
      })
    );
  }

  @Action(MessageTemplateActions.GetSuccess)
  getMessagesSuccess(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { messages }: MessageTemplateActions.GetSuccess
  ) {
    patchState({
      messages,
      message: null,
    });
  }

  @Action(MessageTemplateActions.GetFailure)
  getMessagesFailed(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.GetFailure
  ) {
    patchState({
      messages: null,
      message: message,
    });
  }

  @Action(MessageTemplateActions.SetCurrentMessage)
  setCurrentMessage(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.SetCurrentMessage
  ) {
    patchState({
      currentMessage: message,
    });
  }

  @Action(MessageTemplateActions.Save)
  saveMessage(
    { dispatch }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.Save
  ) {
    const businessId = this.businessService.getCurrent();
    return this.messageService.saveMessage(message, businessId).pipe(
      tap((message) => {
        dispatch(new MessageTemplateActions.SaveSuccess(message));
      }),
      catchError((error) => {
        dispatch(new MessageTemplateActions.SaveFailure(error));
        return of();
      })
    );
  }

  @Action(MessageTemplateActions.SaveSuccess)
  saveMessageSuccess(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.SaveSuccess
  ) {
    patchState({
      messageSaved: message,
      messageSavedFailure: null,
    });
  }

  @Action(MessageTemplateActions.SaveFailure)
  saveMessageFailed(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.SaveFailure
  ) {
    patchState({
      messageSaved: null,
      messageSavedFailure: null,
    });
    patchState({
      messageSavedFailure: message,
    });
  }

  @Action(MessageTemplateActions.SaveInit)
  saveInit({ patchState }: StateContext<MessageTemplateStateModel>) {
    patchState({
      messageSaved: null,
      messageSavedFailure: null,
    });
  }

  @Action(MessageTemplateActions.Send)
  sendMessage(
    { dispatch }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.Send
  ) {
    const businessId = this.businessService.getCurrent();
    message.businessId = businessId;
    return this.messageService.sendMessage(message).pipe(
      tap((message) => {
        dispatch(new MessageTemplateActions.SendSuccess(message));
      }),
      catchError((error) => {
        dispatch(new MessageTemplateActions.SendFailure(error));
        return of();
      })
    );
  }

  @Action(MessageTemplateActions.SendSuccess)
  sendMessageSuccess(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.SendSuccess
  ) {
    patchState({
      messageSend: message,
      messageSendFailure: null,
    });
  }

  @Action(MessageTemplateActions.SendFailure)
  sendMessageFailed(
    { patchState }: StateContext<MessageTemplateStateModel>,
    { message }: MessageTemplateActions.SendFailure
  ) {
    patchState({
      messageSend: null,
      messageSendFailure: null,
    });
    patchState({
      messageSendFailure: message,
    });
  }
}
