import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import {
  GetExtraAction,
  GetExtraInitializedAction,
} from '@store/admin/extra/actions/get-extra.actions';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss'],
})
export class ExtraComponent extends BreadcrumbComponent {
  businessId: string;
  idExtra: string;
  loading: boolean = false;
  showTabs: boolean = false;
  titles: string[] = ['Extra', 'Opciones'];
  tabIndex: number;

  constructor(public activatedRoute: ActivatedRoute, public store: Store) {
    super(activatedRoute, store);
  }

  onInit(): void {
    this.store.dispatch(new GetExtraInitializedAction());

    if (this.paramBusiness) this.businessId = this.paramBusiness;

    if (this.paramExtra) {
      this.idExtra = this.paramExtra;
      this.showTabs = true;

      this.store.dispatch(new GetExtraAction(this.idExtra));
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.index) this.tabIndex = params.index;
    });
  }

  initializeBreadcrumb(): void {
    const urlBase: string = this.paramProduct
      ? `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.paramCategory}/p/${this.paramProduct}`
      : `b/${this.paramBusiness}`;

    if (this.paramExtra) {
      this.breadcrumb = {
        name: 'Extra',
        icon: 'takeout_dining',
        url: `${urlBase}/e/${this.idExtra}`,
        position: this.paramProduct ? 4 : 1,
      } as Breadcrumb;
    } else {
      this.breadcrumb = {
        name: 'Extra',
        icon: 'takeout_dining',
        url: `${urlBase}/extra`,
        position: this.paramProduct ? 4 : 1,
      } as Breadcrumb;
    }
  }
}
