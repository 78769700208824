import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Business } from '@core/models';
import { SecurityService } from '@core/services/security.service';
import { Select, Store } from '@ngxs/store';
import { GetBusinessState } from '@store/admin/business';
import { ParamsComponent } from 'app/admin/core/params.component';
import { StateClear } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-conteiner',
  templateUrl: './admin-conteiner.component.html',
  styleUrls: ['./admin-conteiner.component.scss'],
})
export class AdminConteinerComponent extends ParamsComponent {
  @Select(GetBusinessState.getBusiness) business$: Observable<Business>;

  business: Business;
  businessId: string;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private securitySvc: SecurityService,
    private store: Store,
    public activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business) {
        this.business = business;
      }
    });
  }

  onGoToProfile(): void {
    this.router.navigate(['admin/profile']);
  }

  onSignOut(): void {
    this.securitySvc.signOut().then(() => {
      this.store.dispatch(new StateClear());
      localStorage.clear();
      this.router.navigate(['/']);
    });
  }
}
