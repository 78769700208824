import { Injectable } from '@angular/core';
import { IDelete } from '@core/interfaces/delete.interface';
import { ISort, Sort } from '@core/interfaces/sort.interface';
import {
  AddExtraDto,
  Business,
  Category,
  IdsProduct,
  Product,
  Menu,
} from '@core/models';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { CategoryService } from './category.service';
import { MenuService } from './menu.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements IDelete, ISort {
  private _MENU_ID = 'MENU_ID';
  private _CATEGORY_ID = 'CATEGORY_ID';
  private _SEGMENT = 'menus/MENU_ID/categories/CATEGORY_ID/products';
  private _KEY = '__p';

  constructor(
    private afsvc: ApiFacadeService,
    private categorySvc: CategoryService,
    private ldsvc: LocalDataService,
    private menuSvc: MenuService
  ) {}

  // Base methods
  addExtra(extra: AddExtraDto): Observable<Product> {
    extra.menuId = this.menuSvc.getCurrent();
    const extrasIds: string[] = extra.extras.map((v) => v.extraId);
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .patch({
        segment: `${baseUrl}/${extra.productId}/extras/save`,
        data: {
          ...extra,
          extras: extrasIds,
        },
        useGateway: true,
      })
      .pipe(
        map(() => {
          this.updateExtrasToProduct(extra);
          const product: Product = this.ldsvc.get<Product>(
            `${this._KEY}${extra.productId}`
          );
          return product;
        }),
        catchError((error) => throwError(error))
      );
  }

  delete(ids: IdsProduct): Observable<boolean> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .delete({
        segment: `${baseUrl}/${ids.productId}`,
        storageOptions: {
          delete: true,
          key: `${this._KEY}${ids.productId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.categorySvc.removeProductFromCategory(
            ids.categoryId,
            ids.productId
          );
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  get(productId: string): Observable<Product> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .get<Product>({
        segment: `${baseUrl}/${productId}`,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${productId}`,
        },
        useGateway: true,
      })
      .pipe(
        map((product) => product),
        catchError((error) => throwError(error))
      );
  }

  save(product: Product): Observable<Product> {
    product.menuId = <string>this.menuSvc.getCurrent();
    if (product.productId) {
      return this.update(product);
    } else {
      return this.create(product);
    }
  }

  sort(productId: string, extras: Sort): Observable<Boolean> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .patch({
        segment: `${baseUrl}/${productId}/extras/sort`,
        data: extras,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          const product: Product = this.ldsvc.get<Product>(
            `${this._KEY}${productId}`
          );
          product.sort = [...extras.sort];
          this.ldsvc.set<Product>(`${this._KEY}${productId}`, product);
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  // Public methods
  removeExtraFromProducts(businessId: string, extraId: string): void {
    try {
      const business: Business = this.ldsvc.get<Business>(`__b${businessId}`);
      if (!business?.menus?.length) {
        return;
      }

      const menus: Menu[] = business.menus ?? [];
      for (const m of menus) {
        const menu: Menu = this.ldsvc.get<Menu>(`__m${m.menuId}`);
        if (!menu?.categories?.length) {
          continue;
        }

        const categories: Category[] = menu.categories ?? [];
        for (const c of categories) {
          const category: Category = this.ldsvc.get<Category>(
            `__c${c.categoryId}`
          );
          if (!category?.products?.length) {
            continue;
          }

          const products: Product[] = category.products ?? [];
          for (const p of products) {
            const product: Product = this.ldsvc.get<Product>(
              `${this._KEY}${p.productId}`
            );
            if (!product?.extras?.length) {
              continue;
            }

            if (product.extras.some((e) => e.extraId === extraId)) {
              let index: number = product.extras.findIndex(
                (e) => e.extraId === extraId
              );
              product.extras.splice(index, 1);
              index = product.sort.findIndex((o) => o === extraId);
              product.sort.splice(index, 1);
              this.ldsvc.set<Product>(
                `${this._KEY}${product.productId}`,
                product
              );
            }
          }
        }
      }
    } catch {
      throw new Error(
        'No se pudo completar el proceso para eliminar el extra, intente reiniciando su sesión.'
      );
    }
  }

  // Private methods
  private create(product: Product): Observable<Product> {
    const baseUrl = this.getBaseUrl();
    product.productId = uuidv4();
    return this.afsvc
      .post<Product>({ segment: baseUrl, data: product, useGateway: true })
      .pipe(
        tap(() => {
          this.ldsvc.set<Product>(`${this._KEY}${product.productId}`, product);
          this.categorySvc.addProductToCategory(product);
        }),
        mapTo(product),
        catchError((error) => throwError(error))
      );
  }

  private update(product: Product): Observable<Product> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .put<Product>({
        segment: `${baseUrl}/${product.productId}`,
        data: product,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${product.productId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.categorySvc.updateProductInMenu(product);
        }),
        mapTo(product),
        catchError((error) => throwError(error))
      );
  }

  private updateExtrasToProduct(addExtraDto: AddExtraDto): void {
    const product: Product = this.ldsvc.get<Product>(
      `${this._KEY}${addExtraDto.productId}`
    );

    if (!addExtraDto.extras?.length) {
      product.extras = [];
      product.sort = [];
      this.ldsvc.set<Product>(`${this._KEY}${addExtraDto.productId}`, product);
      return;
    }

    product.extras = addExtraDto.extras;
    if (!product.sort?.length) {
      product.sort = addExtraDto.extras.map((e) => e.extraId);
      this.ldsvc.set<Product>(`${this._KEY}${addExtraDto.productId}`, product);
      return;
    }

    const extrasToRemove: string[] = [];
    for (const idExtra of product.sort) {
      if (!addExtraDto.extras.some((v) => v.extraId === idExtra)) {
        extrasToRemove.push(idExtra);
      }
    }

    const extrasToAdd: string[] = [];
    for (const extra of addExtraDto.extras) {
      if (!product.sort.some((extraId) => extraId === extra.extraId)) {
        extrasToAdd.push(extra.extraId);
      }
    }

    if (extrasToAdd.length) {
      product.sort = [...product.sort, ...extrasToAdd];
    }

    for (const idExtra of extrasToRemove) {
      const index = product.sort.indexOf(idExtra);
      product.sort.splice(index, 1);
    }

    this.ldsvc.set<Product>(`${this._KEY}${addExtraDto.productId}`, product);
  }

  private getBaseUrl(menuId?: string, categoryId?: string): string {
    let baseUrl = this._SEGMENT;
    if (!menuId) {
      menuId = this.menuSvc.getCurrent();
    }
    baseUrl = baseUrl.replace(this._MENU_ID, menuId);
    if (!categoryId) {
      categoryId = this.categorySvc.getCurrent();
    }
    baseUrl = baseUrl.replace(this._CATEGORY_ID, categoryId);
    return baseUrl;
  }
}
