import {
  Directive,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Directive({
  selector: '[appShowImage]',
})
export class ShowImageDirective {
  @Input() imgElement: any;
  @Input() errorElement: Element;
  @Output() loadFile: EventEmitter<File> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  private MAX_SIZE_KIB: number = 100;

  constructor(private imageCompress: NgxImageCompressService) {}
  
  @HostListener('change', ['$event'])
  onChange(event: any) {
    this.loading.emit(true);
    const orientation = -1;
    const file = event.target.files[0];
    if (!file) {
      this.loading.emit(false);
      return;
    }
      
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);

    if (this.isNecessaryToCompressFile(file.size)) {
      const percentage = this.getPercentageToCompress(file.size);
      reader.onload = (_event) => {
        this.errorElement.innerHTML = '';
        this.imageCompress
          .compressFile(_event.target.result.toString(), orientation, percentage, percentage)
          .then((result) => {
            const imageBlob = this.dataURItoBlob(result.split(',')[1]);
            const imageFile = new File([imageBlob], file['name'], {
              type: file['type'],
            });
            if (this.isNecessaryToCompressFile(imageFile.size)) {
              const _percentage = this.getPercentageToCompress(imageFile.size);
              this.compressAgain(result, file, _percentage);
              return;
            }
            this.imgElement.src = result;
            this.loadFile.emit(imageFile);
            this.loading.emit(false);
          })
          .catch((error) => {
            this.errorElement.innerHTML =
              '* ERROR: No se pudo subir la imagen.';
            this.loading.emit(false);
          });
      };
    } else {
      reader.onload = (_event) => {
        this.imgElement.src = _event.target.result;
        this.errorElement.innerHTML = '';
        this.loadFile.emit(file);
        this.loading.emit(false);
      };
    }
  }

  private dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  private getFileSize(fileSize: number) {
    const humanSize = this.humanFileSize(fileSize, false, 0);
    const arrayHumanSize: string[] = humanSize.split(' ');
    const size = arrayHumanSize[0];
    const unit = arrayHumanSize[1];
    return { size: parseInt(size), unit: unit }
  }

  private isNecessaryToCompressFile(fileSize: number): boolean {
    const {size, unit} = this.getFileSize(fileSize);
    if (unit === 'B')
      return false;
    if (unit === 'KiB' && size <= this.MAX_SIZE_KIB)
      return false;
    return true;
  }

  private getPercentageToCompress(fileSize: number): number {
    const {size, unit} = this.getFileSize(fileSize);
    if (unit === 'B' || unit === 'KiB')
      return 90;
    if (unit === 'MiB' && size < 20)
      return 90;
    return 50;
  }

  private compressAgain(resultFile: string, file, percentage: number): void {
    const orientation = -1;
    this.imageCompress
      .compressFile(resultFile, orientation, percentage, percentage)
      .then((result) => {
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        const imageFile = new File([imageBlob], file['name'], {
          type: file['type'],
        });
        if (this.isNecessaryToCompressFile(imageFile.size)) {
          const _percentage = this.getPercentageToCompress(imageFile.size);
          this.compressAgain(result, file, _percentage);
          return;
        }
        this.imgElement.src = result;
        this.loadFile.emit(imageFile);
        this.loading.emit(false);
      })
      .catch((error) => {
        this.errorElement.innerHTML =
          '* ERROR: No se pudo subir la imagen.';
        this.loading.emit(false);
      });
  }

  /**
   * Format bytes as human-readable text.
   * 
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use 
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   * 
   * @return Formatted string.
   */
  private humanFileSize(bytes:number, si=false, dp=1): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
  }
}
