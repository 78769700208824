import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { SignUp } from '@store/authentication/actions/sign-up.actions';
import { SignUpState } from '@store/authentication/states/sign-up.state';
import { TermsAndConditionsDialogComponent } from '@auth/components/sign-up/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { Observable } from 'rxjs';
import { SignedUpDialogComponent } from '@auth/components/sign-up/signed-up-dialog/signed-up-dialog.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  @Select(SignUpState.signingUp) loading$: Observable<boolean>;
  @Select(SignUpState.signedUp) signedUp$: Observable<boolean>;
  @Select(SignUpState.signUpError) signUpError$: Observable<string>;

  formGroup: FormGroup;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.signedUp$.subscribe((success) => {
      if (success) {
        this.formGroup.reset();
        this.matDialog.open(SignedUpDialogComponent);
      }
    });

    this.signUpError$.subscribe((message: string) => {
      if (message) {
        this.snackBar.open(message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
        this.formGroup.reset();
      }
    });
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      termsAndConditions: ['', [Validators.required]],
    });
  }

  onSignUp(): void {
    if (this.formGroup.invalid) return;

    const email: string = this.formGroup.get('email').value;
    const password: string = this.formGroup.get('password').value;

    this._signUp(email, password);
  }

  onOpenTermsAndConditions(): void {
    this.matDialog.open(TermsAndConditionsDialogComponent);
  }

  private _signUp(email: string, password: string): void {
    this.store.dispatch(new SignUp(email, password));
  }
}
