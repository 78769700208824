import { Injectable } from '@angular/core';
import { UserService } from '@core/https/admin/user.service';
import { FormResultWaiter } from '@core/models/waiter.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { UserSaveAction, UserSaveFailureAction, UserSaveSuccessAction } from '../actions/user.actions';


export class UserStateModel {
    public loading: boolean;
    public result: FormResultWaiter;
}

const defaults = {
    loading: false,
    result: {
      success: false,
      error: false,
      message: null,
      waiter: null,
    } as FormResultWaiter,
  };

  @State<UserStateModel>({
      name: 'user',
      defaults,
  })
  @Injectable()
  export class UserState {

    constructor(private userSvc: UserService) {}

    @Selector()
    public static loadInProgress({ loading }){
        return loading;
    }

    @Selector()
    public static result({ result }){
        return result;
    }

    @Action(UserSaveAction)
    save(ctx: StateContext<UserStateModel>, { waiter }: UserSaveAction){
        this._loadInProgress(ctx);
        
        return this.userSvc.save(waiter).pipe(
            tap((newWaiter) => {
                ctx.dispatch(new UserSaveSuccessAction(newWaiter));
            }),
            catchError((error) => {
                ctx.dispatch(new UserSaveFailureAction(error));
                return of();
            })
        );
    }

    @Action(UserSaveSuccessAction)
    saveSuccess(ctx: StateContext<UserStateModel>, { waiter }: UserSaveSuccessAction){
        const state = ctx.getState();

        const result: FormResultWaiter = {
            error: false,
            success: true,
            message: 'Se ha guardado con éxito',
            waiter: waiter,
        };

        ctx.patchState({
            ...state,
            loading: false,
            result: result,
        });
    }

    @Action(UserSaveFailureAction)
    saveFailure(ctx: StateContext<UserStateModel>, { message }: UserSaveFailureAction){
        const state = ctx.getState();

        const result: FormResultWaiter = {
            error: true,
            success: false,
            message: message,
            waiter: null,
        };

        ctx.patchState({
            ...state,
            loading: false,
            result: result,
        });
    }

    private _loadInProgress(ctx: StateContext<UserStateModel>) {
        const state = ctx.getState();
        ctx.patchState({ ...state, loading: true });
    }

  }