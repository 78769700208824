<div class="public-container">
    <div class="container--width container--height">  
        <app-toolbar></app-toolbar>
        <div class="row--not-margin">
            <div class="col-xs-12">
                <div class="box">
                    <ng-content select="[content]"></ng-content>
                </div>
            </div>
        </div>
        <app-footer></app-footer>    
    </div>   
</div>
<div id="public-loading">
    <mat-spinner color="primary"></mat-spinner>
</div>

