<ng-container *ngIf="saving; then creating; else main"></ng-container>

<ng-template #main>
  <h1 mat-dialog-title>Crear nuevo template</h1>
  <div class="row">
    <div class="col-xs-12">
      <mat-form-field>
        <mat-label>Titulo</mat-label>
        <input matInput placeholder="Titulo" [(ngModel)]="title" />
      </mat-form-field>
    </div>
    <div class="col-xs-12">
      <p>
        Incluye <b>#nombre#</b> en tu mensaje para personalizar automáticamente
        tus Whatsapps, asegurando que cada cliente reciba un mensaje
        individualizado.
      </p>
    </div>
    <div class="col-xs-12">
      <mat-form-field>
        <mat-label>Mensaje</mat-label>
        <textarea
          matInput
          placeholder="¡Hola, #nombre#!, nos encata..."
          [(ngModel)]="message"
        >
        </textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row end-xs">
    <div class="col-xs-12">
      <button mat-button (click)="dismiss()">Cancelar</button>
      <button
        mat-button
        color="primary"
        (click)="saveMessage()"
        cdkFocusInitial
      >
        Crear
      </button>
    </div>
  </div>
</ng-template>
<ng-template #creating>
  <app-loading message="Creando template"></app-loading>
</ng-template>
