<app-admin-conteiner>
  <section content>
    <app-admin-title *ngIf="breadcrumb"></app-admin-title>
    <ng-container
      *ngIf="!loading; then optionPage; else optionLoading"
    ></ng-container>
  </section>
</app-admin-conteiner>

<ng-template #optionPage>
  <app-option-form [extraId]="extraId"></app-option-form>
</ng-template>

<ng-template #optionLoading>
  <app-loading message="Cargando..."></app-loading>
</ng-template>
