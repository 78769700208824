import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './components/loading/loading.component';
import { MaterialModule } from '@material/material.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContainerComponent } from './components/container/container.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { ShowImageDirective } from './directives/show-image.directive';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { ContactFormComponent } from './components/contact/contact-form/contact-form.component';
import { ShowLoadingDirective } from './directives/show-loading.directive';
import { BehaivorMenuDirective } from './directives/behaivor-menu.directive';

@NgModule({
  declarations: [
    LoadingComponent,
    ToolbarComponent,
    FooterComponent,
    ContactFormComponent,
    ContainerComponent,
    UploadImageComponent,
    ShowImageDirective,
    ErrorCardComponent,
    ShowLoadingDirective,
    BehaivorMenuDirective,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, RouterModule],
  providers: [NgxImageCompressService],
  exports: [
    ErrorCardComponent,
    FooterComponent,
    ContactFormComponent,
    ContainerComponent,
    LoadingComponent,
    ToolbarComponent,
    UploadImageComponent,
  ],
})
export class SharedModule {}
