import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtraService } from '@core/https/admin/extra.service';
import { Extra, FormResultExtra } from '@core/models/extra.model';
import { Select, Store } from '@ngxs/store';
import { FastErrorStateMatcherHelper } from '@shared/helpers/fast-error-state-matcher.helper';
import { ExtraAction } from '@store/admin/extra/actions/extra.actions';
import { ExtraState } from '@store/admin/extra/states/extra.state';
import { GetExtraState } from '@store/admin/extra/states/get-extra.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-extra-form',
  templateUrl: './extra-form.component.html',
  styleUrls: ['./extra-form.component.scss'],
})
export class ExtraFormComponent extends ParamsComponent {
  @Input() businessId: string;
  @Select(GetExtraState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetExtraState.getting) getting$: Observable<boolean>;
  @Select(GetExtraState.getExtra) extra$: Observable<Extra>;
  @Select(ExtraState.result) result$: Observable<FormResultExtra>;
  @Select(ExtraState.saving) saving$: Observable<boolean>;

  private sent: boolean = false;
  public extraTypeControl: FormControl;
  public maxOptionControl: FormControl;

  errorMessage: string;
  extra: Extra;
  extraTypes = [
    { id: 'UNIQUE', name: 'Única opción' },
    { id: 'MULTIPLE', name: 'Múltiple opción' },
    { id: 'OPEN', name: 'Abierto' },
  ];
  formGroup: FormGroup;
  getting: boolean = false;
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';
  saving: boolean = false;
  showEraserComponent: boolean = false;
  matcher = new FastErrorStateMatcherHelper();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store,
    public activatedRoute: ActivatedRoute,
    public extraSvc: ExtraService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    if (this.paramExtra) this.showEraserComponent = true;

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.extra$.pipe(takeUntil(this.unsubscribe$)).subscribe((extra) => {
      if (extra) {
        this.extra = extra;
      }

      this.createForm();
    });

    this.getting$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.saving) {
        this.loading = loading;
        this.getting = loading;
        if (loading) this.loadingMessage = 'Cargando...';
      }
    });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });

        this.sent = false;
      } else if (result.success && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });

        this.sent = false;
        this.extra = result.extra;

        this.createForm();
        this.router.navigate(
          [`admin/b/${this.businessId}/e/${this.extra.extraId}`],
          { replaceUrl: true }
        );
      }
    });

    this.saving$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.getting) {
        this.loading = loading;
        this.saving = loading;
        if (loading) this.loadingMessage = 'Guardando...';
      }
    });

    this._subscribeTypeValueChanges();
  }

  private createForm(): void {
    this.extraTypeControl = new FormControl(
      this.extra ? this.extra.type : this.extraTypes[0].id
    );

    this.formGroup = this.formBuilder.group({
      active: [this.extra ? this.extra.active : true],
      optional: {
        value:
          this.extraTypeControl.value === 'OPEN'
            ? true
            : this.extra?.optional ?? false,
        disabled: this.extraTypeControl.value === 'OPEN' ? true : false,
      },
      name: [
        this.extra ? this.extra.name : '',
        [Validators.required, Validators.minLength(2)],
      ],
      max: [
        this.extra ? this.extra.max : 2,
        this.extraTypeControl.value === 'MULTIPLE'
          ? [Validators.required, Validators.min(2)]
          : null,
      ],
      type: this.extraTypeControl,
    });
    this._subscribeTypeValueChanges();
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const extraTypeValue: string = this.extraTypeControl.value;

    const extra: Extra = {
      ...this.extra,
      active: this.formGroup.get('active').value,
      optional:
        extraTypeValue === 'OPEN' ? true : this.formGroup.get('optional').value,
      businessId: this.businessId,
      name: this.formGroup.get('name').value,
      max:
        extraTypeValue === 'MULTIPLE'
          ? this.formGroup.get('max').value
          : undefined,
      type: this.extraTypeControl.value,
    } as Extra;

    this._save(extra);
  }

  private _save(extra: Extra): void {
    this.sent = true;
    this.store.dispatch(new ExtraAction(extra));
  }

  private _subscribeTypeValueChanges(): void {
    this.formGroup
      .get('type')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === 'MULTIPLE') {
          this.formGroup
            .get('max')
            .setValidators([Validators.required, Validators.min(2)]);
          this.formGroup.get('max').updateValueAndValidity();
        } else {
          this.formGroup.get('max').clearValidators();
          this.formGroup.get('max').updateValueAndValidity();
        }
        if (value === 'OPEN') {
          this.formGroup.get('optional').disable();
          this.formGroup.get('optional').setValue(true);
        } else {
          this.formGroup.get('optional').enable();
          this.formGroup
            .get('optional')
            .setValue(this?.extra?.optional ?? false);
        }
      });
  }
}
