import { Injectable } from '@angular/core';
import { CustomErrorHandler } from '@core/handlers/custom-error.handler';
import { BusinessService } from '@core/https/admin/business.service';
import { FormResultBusiness } from '@core/models/business.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  SaveBusinessAction,
  SaveBusinessFailAction,
  SaveBusinessSuccessAction,
} from '../actions/save-business.actions';

export class BusinessStateModel {
  public loading: boolean;
  public result: FormResultBusiness;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    business: null,
  } as FormResultBusiness,
};

@State<BusinessStateModel>({
  name: 'saveBusiness',
  defaults,
})
@Injectable()
export class SaveBusinessState {
  private _successMessage = 'Tu negocio fue guardado con éxito.';

  constructor(private businessSvc: BusinessService) {}

  @Selector()
  public static inProgress({ loading }) {
    return loading;
  }

  @Selector()
  public static getResult({ result }) {
    return result;
  }

  @Action(SaveBusinessAction)
  save(ctx: StateContext<BusinessStateModel>, { business }: SaveBusinessAction) {
    this.inProgress(ctx);

    return this.businessSvc.save(business).pipe(
      tap((_business) => {
        ctx.dispatch(new SaveBusinessSuccessAction(_business));
      }),
      catchError((error) => {
        ctx.dispatch(new SaveBusinessFailAction(
          CustomErrorHandler.get(error)
        ));
        return of();
      })
    );
  }

  @Action(SaveBusinessSuccessAction)
  saveSuccess(
    ctx: StateContext<BusinessStateModel>,
    { business }: SaveBusinessSuccessAction
  ) {
    const state = ctx.getState();

    const result: FormResultBusiness = {
      error: false,
      success: true,
      message: this._successMessage,
      business: business,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(SaveBusinessFailAction)
  saveFailed(
    ctx: StateContext<BusinessStateModel>,
    { message }: SaveBusinessFailAction
  ) {
    const state = ctx.getState();

    const result: FormResultBusiness = {
      error: true,
      success: false,
      message: message,
      business: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private inProgress(ctx: StateContext<BusinessStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
