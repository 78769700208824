import { Component } from '@angular/core';

@Component({
  selector: 'app-business-validating',
  templateUrl: './business-validating.component.html',
  styleUrls: ['./business-validating.component.scss']
})
export class BusinessValidatingComponent {
  constructor() { }
}
