import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionService } from '@core/https/admin/option.service';
import { FormResultOption, Option } from '@core/models/option.model';
import { Select, Store } from '@ngxs/store';
import { OptionAction } from '@store/admin/option/actions/option.actions';
import { GetOptionState } from '@store/admin/option/states/get-option.state';
import { OptionState } from '@store/admin/option/states/option.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-option-form',
  templateUrl: './option-form.component.html',
  styleUrls: ['./option-form.component.scss'],
})
export class OptionFormComponent extends ParamsComponent {
  @Input() extraId: string;
  @Select(GetOptionState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetOptionState.getting) getting$: Observable<boolean>;
  @Select(GetOptionState.getOption) option$: Observable<Option>;
  @Select(OptionState.result) result$: Observable<FormResultOption>;
  @Select(OptionState.saving) saving$: Observable<boolean>;

  private sent: boolean = false;

  errorMessage: string;
  formGroup: FormGroup;
  getting: boolean = false;
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';
  option: Option;
  saving: boolean = false;
  showEraserComponent: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store,
    public activatedRoute: ActivatedRoute,
    public optionSvc: OptionService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    if (this.paramOption) this.showEraserComponent = true;

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.getting$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.saving) {
        this.loading = loading;
        this.getting = loading;
        if (loading) this.loadingMessage = 'Cargando...';
      }
    });

    this.option$.pipe(takeUntil(this.unsubscribe$)).subscribe((option) => {
      if (option) {
        this.option = option;
      }
      this.createForm();
    });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });

        this.sent = false;
      } else if (result.success && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });

        this.sent = false;
        this.option = result.option;

        this.createForm();
        this.redirectToOptions();
      }
    });

    this.saving$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.getting) {
        this.loading = loading;
        this.saving = loading;
        if (loading) this.loadingMessage = 'Guardando...';
      }
    });
  }

  private redirectToOptions() {
    let url: string;
    if (this.paramProduct) {
      url = `admin/b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.paramCategory}/p/${this.paramProduct}/e/${this.extraId}`;
    } else {
      url = `admin/b/${this.paramBusiness}/e/${this.extraId}`;
    }
    this.router.navigate([url], { queryParams: { index: '1' } });
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const option: Option = {
      ...this.option,
      active: this.formGroup.get('active').value,
      extraId: this.extraId,
      name: this.formGroup.get('name').value,
      price: this.formGroup.get('price').value,
    } as Option;

    this._save(option);
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [
        this.option ? this.option.name : '',
        [Validators.required, Validators.minLength(2)],
      ],
      price: [
        this.option ? this.option.price : 0,
        [Validators.required, Validators.min(0)],
      ],
      active: [this.option ? this.option.active : true],
    });
  }

  private _save(option: Option): void {
    this.sent = true;
    this.store.dispatch(new OptionAction(option));
  }
}
