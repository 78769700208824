import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'profile.welcome.dialog',
    templateUrl: 'profile.welcome.dialog.html',
    styleUrls: ['./profile.welcome.dialog.scss'],
  })
  export class ProfileWelcomeDialog {
  
    constructor(public dialogRef: MatDialogRef<ProfileWelcomeDialog>){}
  
    closeDialog(): void{
      this.dialogRef.close();
    }
  
  }