import { Injectable } from '@angular/core';
import { OptionService } from '@core/https/admin/option.service';
import { Option } from '@core/models/option.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetOptionAction, GetOptionFailedAction, GetOptionInitializedAction, GetOptionSuccessAction } from '../actions/get-option.actions';

export class GetOptionStateModel {
  public loading: boolean;
  public option: Option;
  public message: string;
}

const defaults = {
  loading: false,
  option: null,
  message: null,
};

@State<GetOptionStateModel>({
  name: 'getOption',
  defaults,
})
@Injectable()
export class GetOptionState {
  constructor(private optionSvc: OptionService) {}

  @Selector()
  public static getting({ loading }) {
    return loading;
  }

  @Selector()
  public static getOption({ option }) {
    return option;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(GetOptionAction)
  get(ctx: StateContext<GetOptionStateModel>, { idExtra, idOption }: GetOptionAction) {
    this._getting(ctx);

    return this.optionSvc.get(idExtra, idOption).pipe(
      tap((option) => {
        ctx.dispatch(new GetOptionSuccessAction(option));
      }),
      catchError((error) => {
        ctx.dispatch(new GetOptionFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(GetOptionFailedAction)
  getFailed(
    ctx: StateContext<GetOptionStateModel>,
    { message }: GetOptionFailedAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      option: null,
      message: message,
    });
  }

  @Action(GetOptionInitializedAction)
  initialize(ctx: StateContext<GetOptionStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      option: null,
      message: null,
    });
  }

  @Action(GetOptionSuccessAction)
  getSuccess(
    ctx: StateContext<GetOptionStateModel>,
    { option }: GetOptionSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      option: option,
    });
  }

  private _getting(ctx: StateContext<GetOptionStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
