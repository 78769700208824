import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '@core/models/list-item.model';

@Pipe({
  name: 'sortListItem'
})
export class SortListItemPipe implements PipeTransform {

  transform(listItems: ListItem[], sort: string[]): ListItem[] {
    const listItemsSorted: ListItem[] = [];
    sort.forEach((value) => {
      const listItem: ListItem = listItems.find(l => l.id === value);
      listItemsSorted.push(listItem);
    });

    return listItemsSorted;
  }

}
