import { Injectable } from '@angular/core';
import { CustomErrorHandler } from '@core/handlers/custom-error.handler';
import { BusinessService } from '@core/https/admin/business.service';
import { Business } from '@core/models/business.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetBusinessAction,
  GetBusinessFailAction,
  GetBusinessSuccessAction,
} from '../actions/get-business.actions';

export class GetBusinessStateModel {
  public loading: boolean;
  public business: Business;
  public message: string;
}

const defaults = {
  loading: false,
  business: null,
  message: null,
};

@State<GetBusinessStateModel>({
  name: 'getBusiness',
  defaults,
})
@Injectable()
export class GetBusinessState {
  constructor(private businessSvc: BusinessService) {}

  @Selector()
  public static inProgress({ loading }) {
    return loading;
  }

  @Selector()
  public static getBusiness({ business }) {
    return business;
  }

  @Selector()
  public static getStripeUserId({ business }) {
    return business.stripeUserId;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Selector()
  public static getExtras({ business }) {
    if (business.extras && business.extras.length > 0) {
      return business.extras;
    }

    return null;
  }

  @Selector()
  public static getMenus({ business }) {
    if (business.menus && business.menus.length > 0) {
      return business.menus;
    }

    return null;
  }

  @Selector()
  public static getUsers({ business }) {
    if (business.users && business.users.length > 0) {
      return business.users;
    }

    return null;
  }

  @Action(GetBusinessAction)
  get(
    ctx: StateContext<GetBusinessStateModel>,
    { businessId, force }: GetBusinessAction
  ) {
    this.inProgress(ctx);

    return this.businessSvc.get(businessId, force).pipe(
      tap((business) => {
        ctx.dispatch(new GetBusinessSuccessAction(business));
        this.businessSvc.saveCurrent(business.businessId);
      }),
      catchError((error) => {
        ctx.dispatch(new GetBusinessFailAction(CustomErrorHandler.get(error)));
        return of();
      })
    );
  }

  @Action(GetBusinessSuccessAction)
  success(
    ctx: StateContext<GetBusinessStateModel>,
    { business }: GetBusinessSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      business: business,
    });
  }

  @Action(GetBusinessFailAction)
  contactFailed(
    ctx: StateContext<GetBusinessStateModel>,
    { message }: GetBusinessFailAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      business: null,
      message: message,
    });
  }

  private inProgress(ctx: StateContext<GetBusinessStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
