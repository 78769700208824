import { PromotionDiscountTypes } from '@core/types/discount.type';
import { PromotionTypes } from '@core/types/promotion.type';

export class Promotion {
  promotionId: string;
  active: boolean;
  dateEnd?: Date | undefined;
  dateStart?: Date | undefined;
  days?: string[] | undefined;
  discountType: PromotionDiscountTypes;
  discountValue: number;
  type: PromotionTypes;
}
