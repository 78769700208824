import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { ParamsComponent } from './params.component';

@Injectable({
  providedIn: 'root',
})
export abstract class BreadcrumbComponent extends ParamsComponent {
  public breadcrumb: Breadcrumb;

  constructor(public activatedRoute: ActivatedRoute, public store: Store) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createBreadcrumb();
  }

  abstract initializeBreadcrumb(): void;

  private createBreadcrumb(): void {
    this.initializeBreadcrumb();
    this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));
  }
}
