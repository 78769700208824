import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Message } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { MessageTemplateActions } from '@store/admin/message-template/message-template.actions';
import { MessageTemplateState } from '@store/admin/message-template/message-template.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CreateTemplateMessageDialogComponent } from '../create-template-message-dialog/create-template-message-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-template-message',
  templateUrl: './template-message.component.html',
  styleUrls: ['./template-message.component.scss'],
})
export class TemplateMessageComponent extends ParamsComponent {
  @Select(MessageTemplateState.getMessages) getMessages$: Observable<Message[]>;
  @Select(MessageTemplateState.getErrorMessage)
  getErrorMessage$: Observable<string>;

  messages: Message[];
  loading = false;
  currentMessage: Message;
  title: string = '';

  constructor(
    public route: ActivatedRoute,
    public store: Store,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    super(route);
  }

  onInit(): void {
    this.getMessages$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((messages) => {
        if (!messages) {
          return;
        }
        this.loading = false;
        this.messages = messages;
      });

    this.getErrorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (!errorMessage) {
          return;
        }
        this.loading = false;
        console.error('errorMessage === >>>', {
          errorMessage,
          loading: this.loading,
        });
      });

    this.store.dispatch(new MessageTemplateActions.Get());
    this.loading = true;
  }

  select(messageId: string): void {
    let selectedMessage = this.messages?.find((v) => v.messageId === messageId);
    if (selectedMessage) {
      this.currentMessage = selectedMessage;
    } else {
      this.title = '';
      this.currentMessage = { messageId: null, title: null, message: null };
    }
    this.title = this.currentMessage?.title;
    this.store.dispatch(
      new MessageTemplateActions.SetCurrentMessage(this.currentMessage)
    );
  }

  updateMessage(message: string): void {
    let newMessage: Message;
    if (this.currentMessage) {
      newMessage = { ...this.currentMessage, title: this.title, message };
    } else {
      newMessage = { messageId: uuidv4(), title: this.title, message };
    }
    this.currentMessage = newMessage;
    this.store.dispatch(
      new MessageTemplateActions.SetCurrentMessage(this.currentMessage)
    );
  }
  updateTitle(title: string): void {
    this.title = title;
    if (this.currentMessage) {
      this.currentMessage.title = title;
    } else {
      this.currentMessage = { messageId: uuidv4(), title, message: null };
    }
    this.store.dispatch(
      new MessageTemplateActions.SetCurrentMessage(this.currentMessage)
    );
  }

  showDialogToCreateTemplate() {
    this._bottomSheet.open(CreateTemplateMessageDialogComponent, {
      disableClose: true,
    });
  }
}
