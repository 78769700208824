import { Injectable } from '@angular/core';
import { ProductService } from '@core/https/admin/product.service';
import { Product } from '@core/models/product.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetProductAction, GetProductFailedAction, GetProductInitializedAction, GetProductSuccessAction } from '../actions/get-product.actions';

export class GetProductStateModel {
  public loading: boolean;
  public product: Product;
  public message: string;
}

const defaults = {
  loading: false,
  product: null,
  message: null,
};

@State<GetProductStateModel>({
  name: 'getProduct',
  defaults,
})
@Injectable()
export class GetProductState {
  constructor(private productSvc: ProductService) {}

  @Selector()
  public static getExtras({ product }) {
    if (product.extras && product.extras.length > 0) {
      return product.extras;
    }

    return null;
  }

  @Selector()
  public static getting({ loading }) {
    return loading;
  }

  @Selector()
  public static getProduct({ product }) {
    return product;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(GetProductAction)
  get(ctx: StateContext<GetProductStateModel>, { idProduct }: GetProductAction ) {
    this._getting(ctx);

    return this.productSvc.get(idProduct).pipe(
      tap((product) => {
        ctx.dispatch(new GetProductSuccessAction(product));
      }),
      catchError((error) => {
        ctx.dispatch(new GetProductFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(GetProductFailedAction)
  getFailed(
    ctx: StateContext<GetProductStateModel>,
    { message }: GetProductFailedAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      product: null,
      message: message,
    });
  }

  @Action(GetProductInitializedAction)
  initialize(ctx: StateContext<GetProductStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      product: null,
      message: null,
    });
  }

  @Action(GetProductSuccessAction)
  getSuccess(
    ctx: StateContext<GetProductStateModel>,
    { product }: GetProductSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      product: product,
    });
  }

  private _getting(ctx: StateContext<GetProductStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
