import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderProduct, OrdersRank } from '@core/models';
import { ProductsDialogComponent } from '../products-dialog/products-dialog.component';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
})
export class OrdersTableComponent {
  @Input() ordersData: OrdersRank;

  displayedColumns: string[] = [
    'customerName',
    'customerPhone',
    'total',
    'createdAt',
    'products',
  ];

  constructor(private readonly dialog: MatDialog) {}

  onSelectProducts(products: OrderProduct[]) {
    this.dialog.open(ProductsDialogComponent, {
      width: '800px',
      data: products,
    });
  }
}
