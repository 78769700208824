import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  @Output() loadImage: EventEmitter<File> = new EventEmitter();
  @Input() image: string;
  @Input() title: string;
  @Input() isBanner: boolean = false;

  loading: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.image) {
      this.image = 'assets/images/no-menu-image.png';
    }
  }

  loadFile(file: File): void {
    this.loadImage.emit(file);
  }

  loadingImage(loading: boolean): void {
    this.loading = loading;
  }
}
