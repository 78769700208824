<mat-toolbar>
    <mat-toolbar-row>
        <span *ngIf="breadcrumbs">
            <button mat-button *ngFor="let breadcrumb of breadcrumbs | slice:0: breadcrumbs.length-1; let position = index" 
                (click)="onGo(breadcrumb.url, position)">
                <mat-icon aria-hidden="false" aria-label="title">{{ breadcrumb.icon }}</mat-icon>
                <span class="menu-title">&nbsp;&nbsp;&nbsp;{{ breadcrumb.name }} </span>
            </button>
        </span>
        <span *ngIf="currentBreadcrumb" class="menu-current-title">
            <mat-icon aria-hidden="false" aria-label="title">{{ currentBreadcrumb.icon }}</mat-icon>
            <span class="menu-title">&nbsp;&nbsp;&nbsp;{{ currentBreadcrumb.name }}</span>
        </span>
    </mat-toolbar-row>
</mat-toolbar>