export namespace StripeRelationship {
  export class Add {
    static readonly type = '[StripeRelationship] Add';
    constructor(public businessId: string, public stripeCode: string) {}
  }

  export class Success {
    static readonly type = '[StripeRelationship] Success';
    constructor(public success: boolean) {}
  }

  export class Failure {
    static readonly type = '[StripeRelationship] Failure';
    constructor(public message: string) {}
  }
}
