import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from '@core/services/security.service';
import { Select, Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { SignIn } from '@store/authentication/actions/sign-in.actions';
import { SignInState } from '@store/authentication/states/sign-in.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  @Select(SignInState.signingIn) loading$: Observable<boolean>;
  @Select(SignInState.signInError) signInError$: Observable<string>;

  formGroup: FormGroup;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private store: Store,
    private securitySvc: SecurityService
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.signInError$.subscribe((message: string) => {
      if (message) {
        if (message === 'EmailNotVerified') {
          message = 'No haz verificado tu email.';
          this._logout();
        }

        this.snackBar.open(message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
        this.formGroup.reset();
      }
    });
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  onSignIn(): void {
    if (this.formGroup.invalid) return;

    const email: string = this.formGroup.get('email').value;
    const password: string = this.formGroup.get('password').value;

    this._signIn(email, password);
  }

  private _signIn(email: string, password: string): void {
    this.store.dispatch(new SignIn(email, password));
  }

  private _logout() {
    this.securitySvc.signOut().then(() => {
      this.store.dispatch(new StateClear());
      localStorage.clear();
    });
  }
}
