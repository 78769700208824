import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormResultProfile, Profile } from '@core/models/profile.model';
import {
  GetProfileAction,
  GetProfileState,
  SaveProfileAction,
  SaveProfileState,
} from '@store/admin/profile';
import { PhoneValidation } from '@shared/validators/phone.validation';
import { SnackbarHelper } from '@shared/helpers/snackbar.helper';
import { MatDialog } from '@angular/material/dialog';
import { ProfileWelcomeDialog } from 'app/admin/components/profile/profile.welcome.dialog';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Select(GetProfileState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetProfileState.inProgress)
  getProfileInProgress$: Observable<boolean>;
  @Select(GetProfileState.getProfile) profile$: Observable<Profile>;
  @Select(SaveProfileState.getResult) result$: Observable<FormResultProfile>;
  @Select(SaveProfileState.inProgress)
  saveProfileInProgress$: Observable<boolean>;

  private profile: Profile;
  private sent: boolean = false;
  private unsubscribe$ = new Subject<void>();
  public breadcrumb: Breadcrumb;
  private isFirstTime: boolean = false;

  errorMessage: string;
  formGroup: FormGroup;
  isGetting: boolean = false;
  isSaving: boolean = false;
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackbarHelper,
    private store: Store,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.breadcrumb = {
      name: 'Perfil',
      icon: 'account_circle',
      url: 'profile',
      position: 0,
    } as Breadcrumb;
    this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));
    this.store.dispatch(new GetProfileAction());

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.getProfileInProgress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        if (!this.isSaving) {
          this.isGetting = loading;
          this.loading = loading;
          if (loading) this.loadingMessage = 'Cargando...';
        }
      });

    this.profile$.pipe(takeUntil(this.unsubscribe$)).subscribe((profile) => {
      this.profile = profile;

      this.doWelcome(this.profile);

      this.createForm();
    });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (this.sent && result.error) {
        this.snackBar.showError(result.message);
      } else if (this.sent && result.success) {
        if (this.isFirstTime) {
          this.navigateToBusiness();
        }
        this.snackBar.showSuccess(result.message);
        this.sent = false;
        this.profile = result.profile;
        this.createForm();
      }
    });

    this.saveProfileInProgress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        if (!this.isGetting) {
          this.isSaving = loading;
          this.loading = loading;
          if (loading) this.loadingMessage = 'Guardando...';
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const profile: Profile = {
      ...this.profile,
      firstName: this.formGroup.get('firstName').value,
      lastName: this.formGroup.get('lastName').value,
      phone: this.formGroup.get('phone').value,
      tier: this.formGroup.get('tier').value,
    } as Profile;

    this.save(profile);
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      email: new FormControl({
        value: this.profile ? this.profile.email : '',
        disabled: 'disabled',
      }),
      firstName: [
        this.profile ? this.profile.firstName : '',
        [Validators.required, Validators.minLength(2)],
      ],
      lastName: [
        this.profile ? this.profile.lastName : '',
        [Validators.required, Validators.minLength(4)],
      ],
      phone: [
        this.profile ? this.profile.phone : '',
        PhoneValidation.validations,
      ],
      tier: [this.profile.tier || 'free', Validators.required],
    });
  }

  private save(profile: Profile): void {
    this.sent = true;
    this.store.dispatch(new SaveProfileAction(profile));
  }

  private doWelcome(profile: Profile): void {
    if (!profile || profile.phone) return;

    this.isFirstTime = true;
    this.dialog.open(ProfileWelcomeDialog, {
      autoFocus: false,
    });
  }

  private navigateToBusiness(): void {
    this.router.navigate(['admin/business']);
  }
}
