import { Breadcrumb } from '@core/models/breadcrumb.model';

export class PushBreadcrumbAction {
  static readonly type = '[Breadcrumb] Push';
  constructor(public breadcrumb: Breadcrumb) {}
}

export class PopBreadcrumbAction {
  static readonly type = '[Breadcrumb] Pop';
  constructor(public positions: number) {}
}

export class ClearBreadcrumbAction {
  static readonly type = '[Breadcrumb] clear';
  constructor(public path: string) {}
}
