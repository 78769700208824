import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

export interface ChartPoint {
  datasetIndex: number;
  dataIndex: number;
  dataValue: number;
  label: string;
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() yAxisData: number[] = [];
  @Input() secondYAxisData?: number[];
  @Input() yAxisDataLabel: string;
  @Input() secondYAxisDataLabel?: string;
  @Input() xAxisLabels: Label[] = [];
  @Input() formatCurrency = false;
  @Input() color: string = 'primary';

  @Output() selectedChartPoint = new EventEmitter<ChartPoint>();

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartPlugins = [];
  public lineChartData: ChartDataSets[] = [];

  private colorMap = {
    primary: {
      background: 'rgb(42, 53, 114)',
      border: 'rgb(42, 53, 114)',
    },
    secondary: {
      background: 'rgb(80, 188, 133)',
      border: 'rgb(80, 188, 133)',
    },
    tertiary: {
      background: 'rgb(40,180,223)',
      border: 'rgb(40,180,223)',
    },
  };

  public barChartColors: Color[] = [];

  constructor() {}

  ngOnInit(): void {
    this.barChartColors = [
      {
        backgroundColor: this.colorMap[this.color].background,
        borderColor: this.colorMap[this.color].border,
        borderWidth: 1,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['yAxisData'] && this.yAxisData) {
      if (this.secondYAxisData) {
        this.lineChartData = [
          { data: this.yAxisData, label: this.yAxisDataLabel },
          { data: this.secondYAxisData, label: this.secondYAxisDataLabel },
        ];
      } else {
        this.lineChartData = [
          { data: this.yAxisData, label: this.yAxisDataLabel },
        ];
      }
      if (this.formatCurrency) {
        this.lineChartOptions.scales.yAxes[0] = {
          ...this.lineChartOptions.scales.yAxes[0],
          ticks: {
            callback: function (value: number) {
              return '$' + value.toLocaleString('es-MX');
            },
          },
        };
      }
    }
  }

  onChartClick(event: any): void {
    if (event.active.length > 0) {
      const activePoint = event.active[0];
      const datasetIndex = activePoint._datasetIndex;
      const dataIndex = activePoint._index;
      const dataValue = this.lineChartData[datasetIndex].data[dataIndex];
      this.selectedChartPoint.emit({
        datasetIndex,
        dataIndex,
        dataValue: dataValue as number,
        label: this.xAxisLabels[dataIndex] as string,
      });
    }
  }
}
