<div class="row" *ngIf="show">
    <div class="col-xs-12">
        <div class="box">
            <h3><label for="button-delete" class="pointer">{{ buttonText }}</label></h3>&nbsp;
            <button mat-fab color="warn" class="button-delete" aria-label="delete" 
                (click)="onOpenDeleteDialog()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>
