<ng-container *ngIf="showList; then listOptions; else noOptions"></ng-container>

<ng-template #listOptions>
  <app-admin-list
    buttonText="Agregar opción"
    [buttonUrl]="buttonUrl"
    description="Selecciona una de tus opciones para acceder a sus detalles."
    icon="rice_bowl"
    [list]="options | fromOptionsToListItem : sort : params"
    noDataText="Todavía no tienes opciones agregadas."
    [implSort]="optionService"
    [id]="extraId"
  >
  </app-admin-list>
</ng-template>

<ng-template #noOptions>
  <p>Los extras de tipo <strong>"Abierto"</strong> no tienen opciones.</p>
</ng-template>
