import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '@core/services/security.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';

import {
  SignIn,
  SignInFailed,
  SignInSuccess,
} from '../actions/sign-in.actions';

export class SignInStateModel {
  public loading: boolean;
  public success: boolean;
  public error: boolean;
  public message: string;
}

const defaults = {
  loading: false,
  success: false,
  error: false,
  message: null,
};

@State<SignInStateModel>({
  name: 'signIn',
  defaults,
})
@Injectable()
export class SignInState {
  constructor(private router: Router, private securitySvc: SecurityService) {}

  @Selector()
  public static signingIn({ loading }) {
    return loading;
  }

  @Selector()
  public static signInError({ message }) {
    return message;
  }

  @Selector()
  public static signedIn({ success }) {
    return success;
  }

  @Action(SignIn)
  signIn(ctx: StateContext<SignInStateModel>, { email, password }: SignIn) {
    this._signingIn(ctx);

    this.securitySvc
      .signIn(email, password)
      .then(async (user) => {
        if (user.emailVerified) {
          await this.router.navigate(['admin/profile']);
          ctx.dispatch(new SignInSuccess());
        } else {
          ctx.dispatch(new SignInFailed('EmailNotVerified'));
        }
      })
      .catch((error) => {
        ctx.dispatch(new SignInFailed(error));
      });
  }

  @Action(SignInSuccess)
  signInSuccess(ctx: StateContext<SignInStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      success: true,
      error: false,
      message: null,
    });
  }

  @Action(SignInFailed)
  signInFailed(ctx: StateContext<SignInStateModel>, { message }: SignInFailed) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      success: false,
      error: true,
      message: message,
    });
  }

  private _signingIn(ctx: StateContext<SignInStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
