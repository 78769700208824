import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';

export interface PieChartData {
  labels: string[];
  data: number[];
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @Input() chartData: PieChartData;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left',
    },
  };
  public pieChartType: string = 'pie';
  public chartColors: any[] = [
    {
      backgroundColor: ['#50bc85', '#28b4df', '#f44336', '#2a3572'],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
