<app-admin-conteiner>
  <section content>
    <app-admin-title></app-admin-title>
    <ng-container *ngIf="loading; then isLoading; else main"></ng-container>

    <ng-template #main>
      <div class="container">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Visitas</mat-card-title>
            <mat-card-subtitle>Conoce tus visitas mensuales</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <app-line-chart
              [xAxisLabels]="accessesGraphData.lapses"
              [yAxisData]="accessesGraphData.totals"
              yAxisDataLabel="Cantidad de visitas"
            ></app-line-chart>
          </mat-card-content>
        </mat-card>

        <div class="pie-charts">
          <div>
            <mat-card *ngIf="productsViewedData">
              <mat-card-header>
                <mat-card-title>Productos más vistos</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <app-pie-chart [chartData]="productsViewedData"></app-pie-chart>
              </mat-card-content>
            </mat-card>
          </div>

          <div>
            <mat-card *ngIf="productsRequestedData">
              <mat-card-header>
                <mat-card-title>Productos más solicitados</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <app-pie-chart
                  [chartData]="productsRequestedData"
                ></app-pie-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Suma total ($) mensual</mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <app-bar-chart
              *ngIf="ordersChartData"
              [barChartData]="[ordersChartData.dataSets[0]]"
              [barChartLabels]="ordersChartData.labels"
              [formatCurrency]="true"
            ></app-bar-chart>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Cantidad de órdenes por mes</mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <app-bar-chart
              *ngIf="ordersChartData"
              [barChartData]="[ordersChartData.dataSets[1]]"
              [barChartLabels]="ordersChartData.labels"
              color="tertiary"
            ></app-bar-chart>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Órdenes de hoy</mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <app-line-chart
              [xAxisLabels]="ordersPerDayGraphData.lapses"
              [yAxisData]="ordersPerDayGraphData.totals"
              yAxisDataLabel="Total ($)"
              color="secondary"
              [formatCurrency]="true"
              (selectedChartPoint)="onSelectPoint($event)"
            ></app-line-chart>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="ordersPerDay">
          <mat-card-header>
            <mat-card-title>Detalle de las órdenes de hoy</mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <app-orders-table [ordersData]="ordersPerDay"></app-orders-table>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-template>

    <ng-template #isLoading>
      <app-loading message="Cargando dashboard"></app-loading>
    </ng-template>
  </section>
</app-admin-conteiner>
