import { Injectable } from '@angular/core';
import { UserService } from '@core/https/admin/user.service';
import { Waiter } from '@core/models/waiter.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { UsersLoadAction, UsersLoadFailureAction, UsersLoadSuccessAction } from '../actions/users.actions';

export class UsersStateModel {
  public loading: boolean;
  public waiters: Waiter[];
}

const defaults = {
  loading: false,
  waiters: null,
};

@State<UsersStateModel>({
  name: 'users',
  defaults,
})
@Injectable()
export class UsersState {
  constructor(private userSvc: UserService) {}

  @Selector()
  public static loadInProgress({ loading }) {
    return loading;
  }

  @Selector()
  public static users({ waiters }) {
    return waiters;
  }

  @Action(UsersLoadAction)
  send(ctx: StateContext<UsersStateModel>, { idBusiness } :UsersLoadAction) {
    this._loadInProgress(ctx);

    return this.userSvc.getMany(idBusiness).pipe(
      tap((waiters) => {
        ctx.dispatch(new UsersLoadSuccessAction(waiters));
      }),
      catchError((error) => {
        ctx.dispatch(new UsersLoadFailureAction(error.message));
        return of();
      })
    );
  }

  @Action(UsersLoadSuccessAction)
  usersLoadSuccess(
    ctx: StateContext<UsersStateModel>,
    { waiters }: UsersLoadSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      waiters: waiters,
    });
  }

  private _loadInProgress(ctx: StateContext<UsersStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ ...state, loading: true });
  }
}
