import { Injectable } from '@angular/core';
import { OptionService } from '@core/https/admin/option.service';
import { FormResultOption } from '@core/models/option.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { OptionAction, OptionFailedAction, OptionSuccessAction } from '../actions/option.actions';

export class OptionStateModel {
  public loading: boolean;
  public result: FormResultOption;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    option: null,
  } as FormResultOption,
};

@State<OptionStateModel>({
  name: 'option',
  defaults,
})
@Injectable()
export class OptionState {
  private _successMessage = 'Tu opción fue guardado con éxito.';

  constructor(private optionSvc: OptionService) {}

  @Selector()
  public static saving({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(OptionAction)
  save(ctx: StateContext<OptionStateModel>, { option }: OptionAction) {
    this._saving(ctx);

    return this.optionSvc.save(option).pipe(
      tap((newOption) => {
        ctx.dispatch(new OptionSuccessAction(newOption));
      }),
      catchError((error) => {
        ctx.dispatch(new OptionFailedAction(error));
        return of();
      })
    );
  }

  @Action(OptionSuccessAction)
  saveSuccess(
    ctx: StateContext<OptionStateModel>,
    { option }: OptionSuccessAction
  ) {
    const state = ctx.getState();

    const result: FormResultOption = {
      error: false,
      success: true,
      message: this._successMessage,
      option: option,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(OptionFailedAction)
  saveFailed(
    ctx: StateContext<OptionStateModel>,
    { message }: OptionFailedAction
  ) {
    const state = ctx.getState();

    const result: FormResultOption = {
      error: true,
      success: false,
      message: message,
      option: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private _saving(ctx: StateContext<OptionStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true, result: null });
  }
}
