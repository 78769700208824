export class RecoverPassword {
  static readonly type = '[RecoverPassword]';
  constructor(public email: string) {}
}

export class RecoverPasswordSuccess {
  static readonly type = '[RecoverPassword] Success';
  constructor() {}
}

export class RecoverPasswordFailed {
  static readonly type = '[RecoverPassword] Failed';
  constructor(public message: string) {}
}
