import { Category } from '@core/models/category.model';

export class GetCategoryAction {
  static readonly type = '[GetCategory]';
  constructor(public categoryId: string) {}
}

export class GetCategoryFailedAction {
  static readonly type = '[GetCategory] Failed';
  constructor(public message: string) {}
}

export class GetCategoryInitializedAction {
  static readonly type = '[GetCategory] Initialized';
  constructor() {}
}

export class GetCategorySuccessAction {
  static readonly type = '[GetCategory] Success';
  constructor(public category: Category) {}
}
