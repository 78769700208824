<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    mode="over"
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    #drawer
  >
    <mat-toolbar>
      <div class="row--not-margin">
        <div class="col-xs-12">
          <img
            class="sidenav__img"
            src="assets/images/shimenu_i-verde-sf.png"
            alt="NotMenu"
          />
        </div>
        <div class="col-xs-12">
          <span>¡Bienvenido a NotMenu!</span>
        </div>
      </div>
    </mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        routerLink="/admin/business"
        appBehaviorBreadcrumb
        path="business"
        routerLinkActive="active"
      >
        <mat-icon aria-hidden="false" aria-label="business">business</mat-icon
        >&nbsp;Negocio
      </a>
      <a
        mat-list-item
        routerLink="/admin/analytics"
        appBehaviorBreadcrumb
        path="analytics"
        routerLinkActive="active"
      >
        <mat-icon aria-hidden="false" aria-label="analytics"
          >bar_chart</mat-icon
        >&nbsp;Analytics
      </a>
      <mat-accordion>
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-hidden="false" aria-label="customers">
                groups
              </mat-icon>
              &nbsp;CRM
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            mat-list-item
            routerLink="/admin/promotions/admin"
            appBehaviorBreadcrumb
            path="promotions/admin"
            routerLinkActive="active"
          >
            <mat-icon aria-hidden="false" aria-label="customers">
              campaign
            </mat-icon>
            &nbsp;Administración de &nbsp;promociones
          </a>
          <div matTooltip="Próximamente">
            <a
              mat-list-item
              appBehaviorBreadcrumb
              path="customer"
              disabled
              routerLinkActive="active"
            >
              <mat-icon aria-hidden="false" aria-label="customers">
                bar_chart
              </mat-icon>
              &nbsp;Analytics
            </a>
          </div>
          <a
            mat-list-item
            routerLink="/admin/customer"
            appBehaviorBreadcrumb
            path="customer"
            routerLinkActive="active"
          >
            <mat-icon aria-hidden="false" aria-label="customers">
              person
            </mat-icon>
            &nbsp;Clientes
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <a
        mat-list-item
        routerLink="/admin/payment"
        appBehaviorBreadcrumb
        path="payment"
        *ngIf="this.business?.visiblePayments"
        routerLinkActive="active"
      >
        <mat-icon aria-hidden="false" aria-label="payments">payments</mat-icon
        >&nbsp;Pagos
      </a>
      <a
        mat-list-item
        routerLink="/admin/point-of-sale"
        appBehaviorBreadcrumb
        path="point-of-sale"
        routerLinkActive="active"
      >
        <mat-icon aria-hidden="false" aria-label="pointOfSale"
          >point_of_sale</mat-icon
        >&nbsp;Punto de venta
      </a>
      <a
        mat-list-item
        routerLink="/admin/contact"
        appBehaviorBreadcrumb
        path="contact"
        routerLinkActive="active"
      >
        <mat-icon aria-hidden="false" aria-label="contact">email</mat-icon
        >&nbsp;Contacto
      </a>
      <div class="mat-list-item--position">
        <mat-divider></mat-divider>
        <a mat-list-item (click)="onSignOut()" appBehaviorBreadcrumb>
          <mat-icon aria-hidden="false" aria-label="signout">logout</mat-icon
          >&nbsp;Cerrar sesión
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="mat-elevation-z6">
      <mat-toolbar-row>
        <div class="row row--100">
          <div class="col-xs-10">
            <button mat-icon-button aria-label="Menu" (click)="drawer.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div class="col-xs-2 end-xs">
            <button
              class="sidenav__mat-icon-profile"
              mat-icon-button
              aria-label="Profile"
              (click)="onGoToProfile()"
              appBehaviorBreadcrumb
              path="profile"
            >
              <mat-icon>account_circle</mat-icon>
            </button>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-content select="[content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
