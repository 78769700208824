import { Menu } from '@core/models/menu.model';

export class GetMenuAction {
  static readonly type = '[GetMenu]';
  constructor(public menuId: string) {}
}

export class GetMenuFailedAction {
  static readonly type = '[GetMenu] Failed';
  constructor(public message: string) {}
}

export class GetMenuInitializedAction {
  static readonly type = '[GetMenu] Initialized';
  constructor() {}
}

export class GetMenuSuccessAction {
  static readonly type = '[GetMenu] Success';
  constructor(public menu: Menu) {}
}
