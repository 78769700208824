import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import { ChartsModule } from 'ng2-charts';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { QRCodeModule } from 'angularx-qrcode';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminConteinerComponent } from './components/admin-conteiner/admin-conteiner.component';
import { AdminListComponent } from './components/admin-list/admin-list.component';
import { AdminSortDialogComponent } from './components/admin-sort-dialog/admin-sort-dialog.component';
import { AdminTabsComponent } from './components/admin-tabs/admin-tabs.component';
import { AdminTitleComponent } from './components/admin-title/admin-title.component';
import { BusinessDialogComponent } from './components/business-dialog/business-dialog.component';
import { BusinessFormComponent } from './components/business/business-form/business-form.component';
import { BusinessValidatingComponent } from './components/business/business-validating/business-validating.component';
import { ExtraListComponent } from './components/business/extra-list/extra-list.component';
import { MenuListComponent } from './components/business/menu-list/menu-list.component';
import { CategoryFormComponent } from './components/category/category-form/category-form.component';
import { ProductListComponent } from './components/category/product-list/product-list.component';
import { CreateMenuDialogComponent } from './components/create-menu-dialog/create-menu-dialog.component';
import { CreateTemplateMessageDialogComponent } from './components/customer/create-template-message-dialog/create-template-message-dialog.component';
import { TemplateMessageComponent } from './components/customer/template-message/template-message.component';
import { EraserDialogComponent } from './components/eraser-dialog/eraser-dialog.component';
import { EraserComponent } from './components/eraser/eraser.component';
import { ExtraFormComponent } from './components/extra/extra-form/extra-form.component';
import { OptionListComponent } from './components/extra/option-list/option-list.component';
import { CategoryListComponent } from './components/menu/category-list/category-list.component';
import { MenuFormComponent } from './components/menu/menu-form/menu-form.component';
import { OptionFormComponent } from './components/option/option-form/option-form.component';
import { ExtrasComponent } from './components/product/extras/extras.component';
import { ProductFormComponent } from './components/product/product-form/product-form.component';
import { BehaviorBreadcrumbDirective } from './directives/behavior-breadcrumb.directive';
import { BusinessComponent } from './pages/business/business.component';
import { CategoryComponent } from './pages/category/category.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { ExtraComponent } from './pages/extra/extra.component';
import { MenuComponent } from './pages/menu/menu.component';
import { OptionComponent } from './pages/option/option.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ProductComponent } from './pages/product/product.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { UserComponent } from './pages/user/user.component';
import { UsersComponent } from './pages/users/users.component';
import { CustomerBirthdayPipe } from './pipes/customer-birthday.pipe';
import { FromCategoriesToListItemPipe } from './pipes/from-categories-to-list-item.pipe';
import { FromExtrasToListItemPipe } from './pipes/from-extras-to-list-item.pipe';
import { FromMenusToListItemPipe } from './pipes/from-menus-to-list-item.pipe';
import { FromOptionsToListItemPipe } from './pipes/from-options-to-list-item.pipe';
import { FromProductsExtrasToListItemPipe } from './pipes/from-products-extras-to-list-item.pipe';
import { FromProductsToListItemPipe } from './pipes/from-products-to-list-item.pipe';
import { FromWaitersToListItemPipe } from './pipes/from-waiters-to-list-item.pipe';
import { SortExtrasPipe } from './pipes/sort-extras.pipe';
import { SortListItemPipe } from './pipes/sort-list-item.pipe';
import { FormsModule } from '@angular/forms';
import { AdminPromotionsComponent } from './pages/promotion/admin-promotions/admin-promotions.component';
import { PointOfSaleComponent } from './pages/point-of-sale/point-of-sale.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { ProductsDialogComponent } from './components/products-dialog/products-dialog.component';

@NgModule({
  declarations: [
    AdminConteinerComponent,
    ProfileComponent,
    AdminTitleComponent,
    BusinessComponent,
    AdminTabsComponent,
    BusinessFormComponent,
    AdminListComponent,
    MenuComponent,
    MenuFormComponent,
    CategoryComponent,
    CategoryFormComponent,
    UsersComponent,
    UserComponent,
    FromWaitersToListItemPipe,
    ProductComponent,
    ProductFormComponent,
    ExtrasComponent,
    ExtraComponent,
    ExtraFormComponent,
    OptionComponent,
    ReportsComponent,
    BusinessValidatingComponent,
    MenuListComponent,
    FromMenusToListItemPipe,
    FromExtrasToListItemPipe,
    ExtraListComponent,
    CategoryListComponent,
    FromCategoriesToListItemPipe,
    OptionListComponent,
    FromOptionsToListItemPipe,
    OptionFormComponent,
    ProductListComponent,
    FromProductsToListItemPipe,
    FromProductsExtrasToListItemPipe,
    ContactComponent,
    AdminSortDialogComponent,
    SortListItemPipe,
    BehaviorBreadcrumbDirective,
    EraserComponent,
    EraserDialogComponent,
    PaymentComponent,
    SortExtrasPipe,
    BusinessDialogComponent,
    CreateMenuDialogComponent,
    CustomerComponent,
    TemplateMessageComponent,
    CustomerBirthdayPipe,
    CreateTemplateMessageDialogComponent,
    AdminPromotionsComponent,
    PointOfSaleComponent,
    AnalyticsComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    OrdersTableComponent,
    ProductsDialogComponent,
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    QRCodeModule,
    FormsModule,
    ChartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AdminModule {}
