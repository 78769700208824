import { Injectable } from '@angular/core';
import { IDelete } from '@core/interfaces/delete.interface';
import { Extra, IdsOption, Option } from '@core/models';
import { ApiFacadeService, LocalDataService } from '@core/services';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { ExtraService } from './extra.service';
import { v4 as uuidv4 } from 'uuid';
import { BusinessService } from './business.service';
import { ISort, Sort } from '@core/interfaces/sort.interface';

@Injectable({
  providedIn: 'root',
})
export class OptionService implements IDelete, ISort {
  private _BUSINESS_ID = 'BUSINESS_ID';
  private _EXTRA_ID = 'EXTRA_ID';
  private _SEGMENT = 'businesses/BUSINESS_ID/extras/EXTRA_ID/options';
  private _KEY = '__o';

  constructor(
    private afsvc: ApiFacadeService,
    private extraSvc: ExtraService,
    private ldsvc: LocalDataService,
    private businessSvc: BusinessService
  ) {}

  // Base methods

  delete(ids: IdsOption): Observable<boolean> {
    const baseUrl = this.getBaseUrl(ids.extraId);
    return this.afsvc
      .delete({
        segment: `${baseUrl}/${ids.optionId}`,
        storageOptions: {
          delete: true,
          key: `${this._KEY}${ids.optionId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() =>
          this.extraSvc.removeOptionFromExtra(ids.extraId, ids.optionId)
        ),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  get(extraId: string, optionId: string): Observable<Option> {
    const extra: Extra = this.ldsvc.get<Extra>(`__e${extraId}`);
    const option: Option = extra.options.find((o) => o.optionId === optionId);
    return of(option);
  }

  save(option: Option): Observable<Option> {
    if (option.optionId) {
      return this.update(option);
    } else {
      return this.create(option);
    }
  }

  sort(extraId: string, options: Sort): Observable<Boolean> {
    const baseUrl = this.getBaseUrl(extraId);
    return this.afsvc
      .patch({
        segment: `${baseUrl}/sort`,
        data: options,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.businessSvc.updateOptionsSortInBusiness(extraId, options.sort);
        }),
        mapTo(true),
        catchError((error) => throwError(error))
      );
  }

  // Private methods

  private create(option: Option): Observable<Option> {
    const baseUrl = this.getBaseUrl();
    option.optionId = uuidv4();
    return this.afsvc
      .post<Option>({ segment: baseUrl, data: option, useGateway: true })
      .pipe(
        tap(() => {
          this.ldsvc.set<Option>(`${this._KEY}${option.optionId}`, option);
          this.extraSvc.addOptionToExtra(option);
        }),
        mapTo(option),
        catchError((error) => throwError(error))
      );
  }

  private update(option: Option): Observable<Option> {
    const baseUrl = this.getBaseUrl();
    return this.afsvc
      .put<Option>({
        segment: `${baseUrl}/${option.optionId}`,
        data: option,
        storageOptions: {
          search: true,
          save: true,
          key: `${this._KEY}${option.optionId}`,
        },
        useGateway: true,
      })
      .pipe(
        tap(() => this.extraSvc.updateOptionInExtra(option)),
        mapTo(option),
        catchError((error) => throwError(error))
      );
  }

  private getBaseUrl(extraId?: string): string {
    let baseUrl = this._SEGMENT.replace(
      this._BUSINESS_ID,
      this.businessSvc.getCurrent()
    );
    if (!extraId) {
      extraId = this.extraSvc.getCurrent();
    }
    baseUrl = baseUrl.replace(this._EXTRA_ID, extraId);
    return baseUrl;
  }
}
