<section>
  <div class="content-form__wrapper content-form__wrapper--margin-bottom">
    <ng-container
      *ngIf="!loading; then extrasForm; else extrasLoading"
    ></ng-container>
  </div>
  <div class="row" *ngIf="extrasAvailable && extrasAvailable.length">
    <div class="col-xs-12">
      <app-admin-list
        description="Estos son tus extras activos en el producto"
        icon="takeout_dining"
        noDataText="No tienes extras asignados."
        [list]="extras | fromProductsExtrasToListItem : sort : params"
        [implSort]="productService"
        [id]="productId"
      >
      </app-admin-list>
    </div>
  </div>
</section>

<ng-template #extrasForm>
  <form [formGroup]="formGroup" (submit)="onSave()">
    <div class="row center-xs">
      <div class="col-xs-12" *ngIf="extrasAvailable && extrasAvailable.length">
        <mat-form-field class="form__field" appearance="outline">
          <mat-label>Extras</mat-label>
          <mat-select [formControl]="extraControl" name="extras" multiple>
            <mat-option
              *ngFor="let extra of extrasAvailable"
              [value]="extra.extraId"
            >
              {{ extra.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          type="submit"
          class="form__button"
          mat-raised-button
          [disabled]="!hasChanges"
        >
          Guardar
        </button>
      </div>
      <div
        class="col-xs-12"
        *ngIf="!extrasAvailable || !extrasAvailable.length"
      >
        <h3>
          No tienes extras registrados, puedes agregarlos en la sección de
          Negocio -> Extras
        </h3>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #extrasLoading>
  <app-loading message="Guardando..."></app-loading>
</ng-template>
