import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import {
  GetOptionAction,
  GetOptionInitializedAction,
} from '@store/admin/option/actions/get-option.actions';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent extends BreadcrumbComponent {
  extraId: string;
  optionId: string;
  loading: boolean = false;

  constructor(public activatedRoute: ActivatedRoute, public store: Store) {
    super(activatedRoute, store);
  }

  onInit(): void {
    this.store.dispatch(new GetOptionInitializedAction());

    if (this.paramExtra) this.extraId = this.paramExtra;

    if (this.paramOption) {
      this.optionId = this.paramOption;

      this.store.dispatch(new GetOptionAction(this.extraId, this.optionId));
    }
  }

  initializeBreadcrumb(): void {
    const urlBase: string = this.paramProduct
      ? `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.paramCategory}/p/${this.paramProduct}/e/${this.extraId}`
      : `b/${this.paramBusiness}/e/${this.extraId}`;

    if (this.paramOption) {
      this.breadcrumb = {
        name: 'Opción',
        icon: 'rice_bowl',
        url: `${urlBase}/o/${this.optionId}`,
        position: this.paramProduct ? 5 : 2,
      } as Breadcrumb;
    } else {
      this.breadcrumb = {
        name: 'Opción',
        icon: 'rice_bowl',
        url: `${urlBase}/option`,
        position: this.paramProduct ? 5 : 2,
      } as Breadcrumb;
    }
  }
}
