<app-admin-conteiner>
  <section content>
    <app-admin-title *ngIf="breadcrumb"></app-admin-title>
    <ng-container
      *ngIf="!loading; then productPage; else productLoading"
    ></ng-container>
  </section>
</app-admin-conteiner>

<ng-template #productPage>
  <ng-container
    *ngIf="showTabs; then productTabs; else productForm"
  ></ng-container>
</ng-template>

<ng-template #productTabs>
  <app-admin-tabs [titles]="titles">
    <div content1>
      <app-product-form [categoryId]="categoryId"></app-product-form>
    </div>
    <div content2>
      <app-extras></app-extras>
    </div>
  </app-admin-tabs>
</ng-template>

<ng-template #productForm>
  <app-product-form [categoryId]="categoryId"></app-product-form>
</ng-template>

<ng-template #productLoading>
  <app-loading message="Cargando..."></app-loading>
</ng-template>
