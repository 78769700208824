import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { ProfileService } from '@core/https/admin/profile.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private profileSvc: ProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const segments: UrlSegment[] = route.children[0].url;
    return this.profileSvc.get().pipe(
      map((profile) => {
        if (profile.firstName && profile.lastName && profile.phone) return true;
        else if (segments[0].path === 'profile') return true;
        else {
          this.router.navigate(['admin/profile']);
          return true;
        }
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const segments: UrlSegment[] = route.url;
    return this.profileSvc.get().pipe(
      map((profile) => {
        if (profile.firstName && profile.lastName && profile.phone) return true;
        else if (segments[0].path === 'profile') return true;
        else {
          this.router.navigate(['admin/profile']);
          return true;
        }
      })
    );
  }
}
