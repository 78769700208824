import { Injectable } from '@angular/core';
import { CustomErrorHandler } from '@core/handlers/custom-error.handler';
import { ProfileService } from '@core/https/admin/profile.service';
import { Profile } from '@core/models/profile.model';
import { State, Action, StateContext, Selector, StateToken } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetProfileAction,
  GetProfileFailedAction,
  GetProfileSuccessAction,
} from '../actions/get-profile.actions';

export class GetProfileStateModel {
  public loading: boolean;
  public profile: Profile;
  public message: string;
}

const GET_PROFILE_STATE_TOKEN = new StateToken<GetProfileStateModel>('getProfile')

const defaults = {
  loading: false,
  profile: null,
  message: null,
};

@State<GetProfileStateModel>({
  name: GET_PROFILE_STATE_TOKEN,
  defaults,
})
@Injectable()
export class GetProfileState {
  constructor(private profileSvc: ProfileService) {}

  @Selector([GET_PROFILE_STATE_TOKEN])
  public static getBusiness({ profile }) {
    if (profile.businesses && profile.businesses.length > 0) {
      return profile.businesses[0];
    }

    return null;
  }

  @Selector([GET_PROFILE_STATE_TOKEN])
  public static inProgress({ loading }) {
    return loading;
  }

  @Selector([GET_PROFILE_STATE_TOKEN])
  public static getProfile({ profile }): Profile {
    return profile;
  }

  @Selector([GET_PROFILE_STATE_TOKEN])
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(GetProfileAction)
  get(ctx: StateContext<GetProfileStateModel>) {
    this.inProgress(ctx);

    return this.profileSvc.get().pipe(
      tap((profile) => {
        ctx.dispatch(new GetProfileSuccessAction(profile));
      }),
      catchError((error) => {
        ctx.dispatch(new GetProfileFailedAction(
          CustomErrorHandler.get(error)  
        ));
        return of();
      })
    );
  }

  @Action(GetProfileSuccessAction)
  success(
    ctx: StateContext<GetProfileStateModel>,
    { profile }: GetProfileSuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      profile: profile,
      message: null,
    });
  }

  @Action(GetProfileFailedAction)
  fail(
    ctx: StateContext<GetProfileStateModel>,
    { message }: GetProfileFailedAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      profile: null,
      message: message,
    });
  }

  private inProgress(ctx: StateContext<GetProfileStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: true,
      profile: null,
      message: null,
    });
  }
}
