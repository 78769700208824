import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import {
  GetProductAction,
  GetProductInitializedAction,
} from '@store/admin/product/actions/get-product.actions';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent extends BreadcrumbComponent {
  categoryId: string;
  productId: string;
  loading: boolean = false;
  showTabs: boolean = false;
  titles: string[] = ['Producto', 'Extras'];

  constructor(public activatedRoute: ActivatedRoute, public store: Store) {
    super(activatedRoute, store);
  }

  onInit(): void {
    this.store.dispatch(new GetProductInitializedAction());

    if (this.paramCategory) this.categoryId = this.paramCategory;

    if (this.paramProduct) {
      this.productId = this.paramProduct;
      this.showTabs = true;
      this.store.dispatch(new GetProductAction(this.productId));
    }
  }

  initializeBreadcrumb(): void {
    if (this.paramProduct) {
      this.breadcrumb = {
        name: 'Producto',
        icon: 'lunch_dining',
        url: `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.categoryId}/p/${this.productId}`,
        position: 3,
      } as Breadcrumb;
    } else {
      this.breadcrumb = {
        name: 'Producto',
        icon: 'lunch_dining',
        url: `b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.categoryId}/product`,
        position: 3,
      } as Breadcrumb;
    }
  }
}
