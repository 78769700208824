import { Injectable } from '@angular/core';
import { Contact } from '@core/models/contact.model';
import { ApiFacadeService } from '@core/services/api-facade.service';
import { Observable, throwError } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private _SEGMENT = 'general/api/contacts';

  constructor(private afsvc: ApiFacadeService) {}

  send(contact: Contact): Observable<Contact> {
    return this.afsvc.post({ segment: this._SEGMENT, data: contact }).pipe(
      mapTo(contact),
      catchError((error) => throwError(error))
    );
  }
}
