import { Injectable } from '@angular/core';
import { ProductService } from '@core/https/admin/product.service';
import { FormResultAddExtra } from '@core/models/extra.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddExtraAction, AddExtraFailedAction, AddExtraSuccessAction } from '@store/admin/product/actions/add-extra.actions';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class AddExtraStateModel {
    public loading: boolean;
    public result: FormResultAddExtra;
}

const defaults = {
    loading: false,
    result: {
        success: null,
        error: null,
        message: null,
        product: null,
    }
}

@State<AddExtraStateModel>({
    name: 'addExtra',
    defaults,
})
@Injectable()
export class AddExtraState {

    private _successMessage = 'Los extras fueron guardados.';

    constructor(private productSvc: ProductService) {}

    @Selector()
    public static loadInProgress({ loading }) {
        return loading;
    }

    @Selector()
    public static getResult({ result }) {
        return result;
    }

    @Selector()
    public static getErrorMessage({ result }) {
        return result.message;
    }
    
    private _loadInProgress(ctx: StateContext<AddExtraStateModel>) {
        const state = ctx.getState();
        ctx.patchState({ ...state, loading: true });
    }

    @Action(AddExtraAction)
    add(ctx: StateContext<AddExtraStateModel>, { extra }: AddExtraAction) {
        this._loadInProgress(ctx);
        return this.productSvc.addExtra(extra).pipe(
            tap(product => {
                ctx.dispatch(new AddExtraSuccessAction(product));
            }),
            catchError(error => {
                ctx.dispatch(new AddExtraFailedAction(error.message));
                return of();
            })
        );
    }

    @Action(AddExtraSuccessAction)
    getSuccess(ctx: StateContext<AddExtraStateModel>, { product }: AddExtraSuccessAction) {
        const state = ctx.getState();
        ctx.patchState({
            ...state,
            loading: false,
            result: {
                success: true,
                error: false,
                message: this._successMessage,
                product: product,
            }
        });
    }

    @Action(AddExtraFailedAction)
    getFailed(ctx: StateContext<AddExtraStateModel>, { message }: AddExtraFailedAction) {
        const state = ctx.getState();
        ctx.patchState({
            ...state,
            loading: false,
            result: {
                success: false,
                error: true,
                message: message,
                product: null
            }
        });
    }
}