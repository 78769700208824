import { Waiter } from '@core/models/waiter.model';

export class UserSaveAction {
    static readonly type = '[User] Save';
    constructor(public waiter: Waiter) {}
}

export class UserSaveSuccessAction {
    static readonly type = '[User] Save success';
    constructor(public waiter: Waiter) {}
}

export class UserSaveFailureAction {
    static readonly type = '[User] Save failure';
    constructor(public message: string) {}
}