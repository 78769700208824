import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '@core/models/list-item.model';
import { Waiter } from '@core/models/waiter.model';

@Pipe({
  name: 'fromWaitersToListItem',
})
export class FromWaitersToListItemPipe implements PipeTransform {
  transform(waiters: Waiter[], businessId: string): ListItem[] {
    if (waiters) {
      return waiters.map((waiter) => {
        return {
          id: waiter.idUser,
          text: waiter.idUser,
          url: `${businessId}/user/${waiter.idUser}`,
          active: waiter.active,
        } as ListItem;
      });
    }

    return null;
  }
}
