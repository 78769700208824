import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment,
  Router,
  CanActivateChild,
} from '@angular/router';
import { SecurityService } from '@core/services/security.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecurityGuard implements CanActivate, CanActivateChild {
  segmentChildren: string[] = ['signin', 'signup', 'recoverpassword'];

  constructor(private router: Router, private securitySvc: SecurityService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const segments: UrlSegment[] = route.url;
    return this.securitySvc.isAuthenticated().pipe(
      map((isAuth) => {
        if (isAuth && segments[0].path === 'auth') {
          this.router.navigate(['admin/profile']);
          return true;
        } else {
          return true;
        }
      }),
      first()
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const segments: UrlSegment[] = route.url;
    return this.securitySvc.isAuthenticated().pipe(
      map((isAuth) => {
        if (isAuth && this.segmentChildren.includes(segments[0].path)) {
          this.router.navigate(['admin/profile']);
          return true;
        } else {
          return true;
        }
      }),
      first()
    );
  }
}
