<app-admin-conteiner>
  <section content style="height: 82%">
    <app-admin-title
      title="Punto de venta"
      iconName="point_of_sale"
    ></app-admin-title>
    <ng-container *ngIf="loading; then isLoading; else main"></ng-container>
    <ng-template #isLoading>
      <app-loading message="Cargando punto de venta"></app-loading>
    </ng-template>
    <ng-template #main>
      <div class="row center-xs" style="height: 100%">
        <div class="col-xs-12 col-md-6 col-lg-4">
          <iframe [src]="safeUrl" style="width: 100%; height: 100%"></iframe>
        </div>
      </div>
    </ng-template>
  </section>
</app-admin-conteiner>
