import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '@core/models/category.model';
import { ListItem } from '@core/models/list-item.model';
import { ParamsModel } from '../core/params.model';

@Pipe({
  name: 'fromCategoriesToListItem',
})
export class FromCategoriesToListItemPipe implements PipeTransform {
  transform(
    categories: Category[],
    sort: string[],
    paramsModel: ParamsModel
  ): ListItem[] {
    if (!categories?.length || !sort?.length) {
      return null;
    }
    let categoriesObject = categories.reduce(
      (prev, current) => ({
        ...prev,
        [current.categoryId]: current,
      }),
      {}
    );
    return sort.map((category) => {
      let current = categoriesObject[category];
      return {
        id: current.categoryId,
        text: current.name,
        url: `b/${paramsModel.businessId}/m/${paramsModel.menuId}/c/${current.categoryId}`,
        active: current.active,
      } as ListItem;
    });
  }
}
