import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RecoverPassword } from '@store/authentication/actions/recover-password.actions';
import { RecoverPasswordState } from '@store/authentication/states/recover-password.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
  @Select(RecoverPasswordState.recovering) loading$: Observable<boolean>;
  @Select(RecoverPasswordState.recoveredPassword)
  recoveredPassword$: Observable<boolean>;
  @Select(RecoverPasswordState.recoverPasswordError)
  recoverPasswordError$: Observable<string>;

  formGroup: FormGroup;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.recoverPasswordError$.subscribe((message: string) => {
      if (message) {
        this.snackBar.open(message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
        this.formGroup.reset();
      }
    });

    this.recoveredPassword$.subscribe((success) => {
      if (success) {
        this.snackBar.open(
          'Se ha enviado un email para resetear tu contraseña.',
          'X',
          {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['success-snackbar'],
          }
        );
        this.formGroup.reset();
        this.router.navigate(['authentication/signin']);
      }
    });
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onRecoverPassword(): void {
    if (this.formGroup.invalid) return;

    const email: string = this.formGroup.get('email').value;

    this._recoverPassword(email);
  }

  private _recoverPassword(email: string): void {
    this.store.dispatch(new RecoverPassword(email));
  }
}
