<app-container>
  <div class="row" content>
    <div class="col col-xs-12">
      <p>
        NotMenu es un proyecto tecnológico que nace a partir de la necesidad que
        tienen los negocios por permanecer abiertos ante esta nueva normalidad.
      </p>
      <p>
        A través de NotMenu buscamos que tu negocio pueda cumplir al máximo con
        las medidas sanitarias entre tu cliente y tú, pero no solo eso, también
        queremos que su experiencia mejore así que tratamos de mejorar los
        tiempos en los que se pueda hacer una orden.
      </p>
      <p>
        <b>¿Cómo?</b><br />
        Tu negocio contara con un QR, el cual podrás descargar desde NotMenu
        para ponerlo en algún sitio visible para tu clientes, cuando uno de tus
        clientes escanee el código será redireccionado a un menú que
        anteriormente tu hayas configurado en NotMenu, este menú es interactivo
        ya que a través de él, tu cliente puede realizar el pedido generando un
        QR con su orden, tu personal solo necesita bajar la App de NotMenu para
        Android o IOS y podrá empezar escanear estos códigos con las ordenes de
        los clientes.
      </p>
    </div>
  </div>
</app-container>
