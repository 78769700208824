<div style="height: 300px" *ngIf="chartData.data.length">
  <canvas
    baseChart
    [data]="chartData.data"
    [labels]="chartData.labels"
    [options]="pieChartOptions"
    [chartType]="pieChartType"
    [colors]="chartColors"
  >
  </canvas>
</div>

<div class="no-data" *ngIf="!chartData.data.length">
  <div *ngIf="!chartData.data.length">No hay datos para mostrar</div>
</div>
