import { Option } from '@core/models/option.model';

export class GetOptionAction {
  static readonly type = '[GetOption]';
  constructor(public idExtra: string, public idOption: string) {}
}

export class GetOptionFailedAction {
  static readonly type = '[GetOption] Failed';
  constructor(public message: string) {}
}

export class GetOptionInitializedAction {
  static readonly type = '[GetOption] Initialized';
  constructor() {}
}

export class GetOptionSuccessAction {
  static readonly type = '[GetOption] Success';
  constructor(public option: Option) {}
}