import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { Message } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { SnackbarHelper } from '@shared/helpers/snackbar.helper';
import { MessageTemplateActions } from '@store/admin/message-template/message-template.actions';
import { MessageTemplateState } from '@store/admin/message-template/message-template.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-create-template-message-dialog',
  templateUrl: './create-template-message-dialog.component.html',
  styleUrls: ['./create-template-message-dialog.component.scss'],
})
export class CreateTemplateMessageDialogComponent extends ParamsComponent {
  @Select(MessageTemplateState.messageSaved) messageSaved$: Observable<Message>;
  @Select(MessageTemplateState.messageSavedFailure)
  messageSavedFailure$: Observable<string>;

  title: string;
  message: string;
  saving = false;

  constructor(
    public route: ActivatedRoute,
    public store: Store,
    private _bottomSheetRef: MatBottomSheetRef<CreateTemplateMessageDialogComponent>,
    private snackBar: SnackbarHelper
  ) {
    super(route);
  }

  onInit(): void {
    this.messageSaved$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message) => {
        if (!message) {
          return;
        }
        this.snackBar.showSuccess('Template guardado con éxito.');
        this.dismiss();
        this.store.dispatch(new MessageTemplateActions.SaveInit());
        this.store.dispatch(new MessageTemplateActions.Get());
      });

    this.messageSavedFailure$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (!errorMessage) {
          return;
        }
        this.saving = false;

        this.snackBar.showError(errorMessage);
      });
  }

  saveMessage(): void {
    if (!this.title?.trim() || !this.message?.trim()) {
      this.snackBar.showError('Debe ingresar titulo y mensaje');
      return;
    }
    const message: Message = {
      message: this.message.trim(),
      title: this.title.trim(),
      messageId: uuidv4(),
    };
    this.store.dispatch(new MessageTemplateActions.Save(message));
    this.saving = true;
  }

  dismiss(): void {
    this._bottomSheetRef.dismiss();
  }
}
