import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '@core/models/contact.model';
import { FormResult } from '@core/models/form-result.model';
import { Select, Store } from '@ngxs/store';
import { ContactAction } from '@store/public/actions/contact.actions';
import { ContactState } from '@store/public/states/contact.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @Select(ContactState.sending) loading$: Observable<boolean>;
  @Select(ContactState.result) result$: Observable<FormResult>;

  private loadingSubs: Subscription;
  private resultSubs: Subscription;
  private sent: boolean;

  formGroup: FormGroup;
  subjectControl: FormControl;
  loading: boolean = false;

  options = [
    { value: 'help', text: 'Ayuda' },
    { value: 'costs', text: 'Costos' },
    { value: 'qr', text: 'Quiero un QR personalizado' },
    { value: 'other', text: 'Otro' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private store: Store
  ) {}

  ngOnDestroy(): void {
    this.loadingSubs.unsubscribe();
    this.resultSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();

    this.loadingSubs = this.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.resultSubs = this.result$.subscribe((result) => {
      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
        this.sent = false;
      } else if (this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });
        this.sent = false;
      }
      this.formGroup.reset();
    });
  }

  createForm(): void {
    this.subjectControl = new FormControl(this.options[0].value);

    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      subject: this.subjectControl,
      description: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  onSend(): void {
    if (this.formGroup.invalid) return;

    const contact: Contact = {
      description: this.formGroup.get('description').value,
      email: this.formGroup.get('email').value,
      name: this.formGroup.get('name').value,
      phone: this.formGroup.get('phone').value,
      subject: this.options.find((o) => o.value === this.subjectControl.value)
        .text,
    };

    this._send(contact);
  }

  private _send(contact: Contact): void {
    this.sent = true;
    this.store.dispatch(new ContactAction(contact));
  }

}
