<app-admin-conteiner>
  <section content>
    <app-admin-title *ngIf="breadcrumb"></app-admin-title>
    <ng-container
      *ngIf="!loading; then menuPage; else menuLoading"
    ></ng-container>
  </section>
</app-admin-conteiner>

<ng-template #menuPage>
  <ng-container *ngIf="showTabs; then menuTabs; else menuForm"></ng-container>
</ng-template>

<ng-template #menuTabs>
  <app-admin-tabs [titles]="titles" [tabIndex]="tabIndex">
    <div content1>
      <app-menu-form [businessId]="businessId"></app-menu-form>
    </div>
    <div content2>
      <app-category-list></app-category-list>
    </div>
  </app-admin-tabs>
</ng-template>

<ng-template #menuForm>
  <app-menu-form [businessId]="businessId"></app-menu-form>
</ng-template>

<ng-template #menuLoading>
  <app-loading message="Cargando..."></app-loading>
</ng-template>
