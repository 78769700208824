import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '@core/models/list-item.model';
import { Menu } from '@core/models/menu.model';

@Pipe({
  name: 'fromMenusToListItem',
})
export class FromMenusToListItemPipe implements PipeTransform {
  transform(menus: Menu[], businessId: string): ListItem[] {
    if (menus) {
      return menus.map((menu) => {
        return {
          id: menu.menuId,
          text: menu.name,
          url: `b/${businessId}/m/${menu.menuId}`,
          active: menu.active,
        } as ListItem;
      });
    }

    return null;
  }
}
