import { Extra } from '@core/models/extra.model';

export class GetExtraAction {
  static readonly type = '[GetExtra]';
  constructor(public idExtra: string) {}
}

export class GetExtraFailedAction {
  static readonly type = '[GetExtra] Failed';
  constructor(public message: string) {}
}

export class GetExtraInitializedAction {
  static readonly type = '[GetExtra] Initialized';
  constructor() {}
}

export class GetExtraSuccessAction {
  static readonly type = '[GetExtra] Success';
  constructor(public extra: Extra) {}
}
