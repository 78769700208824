import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  loading: boolean = false;
  formGroup: FormGroup;

  public breadcrumb: Breadcrumb;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.breadcrumb = {
      name: 'Reportes',
      icon: 'grid_view',
      url: 'reports',
      position: 0
    } as Breadcrumb;
    this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));

    this.createForm();
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      date: ['']
    });
  }
}
