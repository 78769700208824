<app-public-container>
    <section content>
        <div class="row center-xs">
            <div class="col-xs-12 col-md-8 z-index-1">
                <div class="row">
                    <div class="col-xs-12 welcome__description">
                        <h2>Deja que la tecnología se encargue y haz de tu restaurante, un restaurante inteligente.</h2>
                    </div>
                    <div class="col-xs-12">
                        <button mat-raised-button (click)="onGoToSignUp()" appShowLoading>Comienza aquí</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</app-public-container>


