import { Category } from '@core/models/category.model';

export class CategoryAction {
  static readonly type = '[Category]';
  constructor(public category: Category) {}
}

export class CategorySuccessAction {
  static readonly type = '[Category] Success';
  constructor(public category: Category) {}
}

export class CategoryFailedAction {
  static readonly type = '[Category] Failed';
  constructor(public message: string) {}
}
