<ng-container
  *ngIf="
    this.loading;
    then inProgress;
    else this.success ? paymentStatus : paymentConfig
  "
></ng-container>
<ng-template #paymentConfig>
  <app-admin-conteiner>
    <section content>
      <app-admin-title title="Pagos" iconName="payments"></app-admin-title>
      <div class="row center-xs">
        <div class="col-xs-10">
          <p>
            ¡Hola! Bienvenido al servicio de pagos de NotMenu.<br /><br />Para
            comenzar a procesar tus pagos de forma rápida y segura, es necesario
            que vincules tu cuenta de usuario con una cuenta de Stripe. Por
            favor, sigue el enlace que te proporcionamos a continuación para
            completar este proceso.
          </p>
        </div>
        <div class="col-xs-5">
          <button
            type="button"
            class="form__button"
            mat-raised-button
            [disabled]="notInitialized"
            (click)="goToStripe()"
          >
            Ir a configuración con Stripe
          </button>
          <p>¡Gracias por confiar en nuestro servicio!</p>
        </div>
      </div>
    </section>
  </app-admin-conteiner>
</ng-template>
<ng-template #paymentStatus>
  <app-admin-conteiner>
    <section content>
      <app-admin-title title="Pagos" iconName="payments"></app-admin-title>
      <div class="row center-xs">
        <div class="col-xs-10">
          <p>¡Gracias por vincular tu cuenta de usuario de NotMenu a Stripe!</p>
          <p>
            Ahora podrás procesar pagos de manera segura y confiable a través de
            nuestra plataforma. Si tienes alguna pregunta o necesitas ayuda
            adicional, por favor no dudes en contactarnos
          </p>
          <p *ngIf="loadingLink">
            <app-loading [message]="linkInProgressMessage"></app-loading>
          </p>
          <div class="row center-xs">
            <div class="col-xs-7" *ngIf="!loadingLink">
              <button
                type="button"
                class="form__button"
                mat-raised-button
                (click)="getLink()"
              >
                Abrir dashboard de pagos
              </button>
            </div>
          </div>
          <p>¡Estamos aquí para ayudarte en todo lo que necesites!</p>
        </div>
      </div>
    </section>
  </app-admin-conteiner>
</ng-template>
<ng-template #inProgress>
  <app-admin-conteiner>
    <section content>
      <app-admin-title title="Pagos" iconName="payments"></app-admin-title>
      <app-loading [message]="inProgressMessage"></app-loading>
    </section>
  </app-admin-conteiner>
</ng-template>
