import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { CallApiService } from './call-api.service';
import { LocalDataService } from './local-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFacadeService {
  constructor(
    private callApiSvc: CallApiService,
    private localDataSvc: LocalDataService
  ) {}

  get<T>({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions?: { search?: boolean; save?: boolean; key?: string };
    useGateway?: boolean;
  }): Observable<T> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._searchAndGet<T>({
        segment,
        storageOptions: _storageOptions,
        useGateway,
      });
    }

    return this._get<T>({
      segment,
      storageOptions: _storageOptions,
      useGateway,
    });
  }

  getMany<T>({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions?: { search?: boolean; save?: boolean; key?: string };
    useGateway?: boolean;
  }): Observable<T[]> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._searchAndGetMany<T>({
        segment,
        storageOptions: _storageOptions,
        useGateway,
      });
    }

    return this._getMany<T>({
      segment,
      storageOptions: _storageOptions,
      useGateway,
    });
  }

  post<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions?: { save?: boolean; key?: string };
    useGateway?: boolean;
  }): Observable<T> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    return this.callApiSvc
      .post<T>({ urlSegment: segment, data, useGateway })
      .pipe(
        tap(() => {
          if (_storageOptions.save)
            this.localDataSvc.set<T>(_storageOptions.key, data);
        }),
        map((dataApi: T) => dataApi),
        catchError((error) => throwError(error))
      );
  }

  put<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions?: { search?: boolean; save?: boolean; key?: string };
    useGateway?: boolean;
  }): Observable<T> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._compareAndPut<T>({
        segment,
        data,
        storageOptions: _storageOptions,
        useGateway,
      });
    }

    return this._put<T>({
      segment,
      data,
      storageOptions: _storageOptions,
      useGateway,
    });
  }

  patch<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions?: { search?: boolean; save?: boolean; key?: string };
    useGateway?: boolean;
  }): Observable<void> {
    const _storageOptions = this._getStorageOptions(segment, storageOptions);

    if (_storageOptions.search) {
      return this._compareAndPatch<T>({
        segment,
        data,
        storageOptions: _storageOptions,
        useGateway,
      });
    }

    return this._patch({
      segment,
      data,
      storageOptions: _storageOptions,
      useGateway,
    });
  }

  putBoolean<T>({
    segment,
    data,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    useGateway?: boolean;
  }): Observable<Boolean> {
    return this.callApiSvc
      .putBoolean({ urlSegment: segment, data, useGateway })
      .pipe(
        map((dataApi: boolean) => dataApi),
        catchError((error) => throwError(error))
      );
  }

  delete({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions: { delete: boolean; key: string };
    useGateway?: boolean;
  }): Observable<boolean> {
    return this.callApiSvc.delete({ urlSegement: segment, useGateway }).pipe(
      tap(() => {
        if (storageOptions.delete) {
          this.localDataSvc.remove(storageOptions.key);
        }
      }),
      map(() => {
        return true;
      }),
      catchError((error) => throwError(error))
    );
  }

  private _compareAndPut<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<T> {
    return this.localDataSvc.equalsObs<T>(storageOptions.key, data).pipe(
      switchMap((isEqual) =>
        iif(
          () => isEqual,
          of(data),
          this._put<T>({ segment, data, storageOptions, useGateway })
        )
      ),
      catchError((error) => throwError(error))
    );
  }

  private _compareAndPatch<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<void> {
    return this.localDataSvc.equalsObs<T>(storageOptions.key, data).pipe(
      switchMap((isEqual) =>
        iif(
          () => isEqual,
          of(),
          this._patch({ segment, data, storageOptions, useGateway })
        )
      ),
      catchError((error) => throwError(error))
    );
  }

  private _get<T>({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<T> {
    return this.callApiSvc.get<T>({ urlSegment: segment, useGateway }).pipe(
      tap((data) => {
        if (storageOptions.save) {
          this.localDataSvc.set<T>(storageOptions.key, data);
        }
      }),
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  private _getMany<T>({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<T[]> {
    return this.callApiSvc.getMany<T>({ urlSegment: segment, useGateway }).pipe(
      tap((data) => {
        if (storageOptions.save)
          this.localDataSvc.setMany<T>(storageOptions.key, data);
      }),
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  private _getStorageOptions(
    segment: string,
    storageOptions?: {
      search?: boolean;
      save?: boolean;
      key?: string;
    }
  ): any {
    if (!storageOptions) {
      return { search: false, save: false, key: null };
    }

    return {
      search: storageOptions.search ?? false,
      save: storageOptions.save ?? false,
      key: storageOptions.key ?? segment,
    };
  }

  private _put<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<T> {
    return this.callApiSvc
      .put<T>({ urlSegment: segment, data, useGateway })
      .pipe(
        tap(() => {
          if (storageOptions.save)
            this.localDataSvc.set<T>(storageOptions.key, data);
        }),
        map((dataApi: T) => dataApi),
        catchError((error) => throwError(error))
      );
  }

  private _patch<T>({
    segment,
    data,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    data: T;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<void> {
    return this.callApiSvc
      .patch({ urlSegment: segment, data, useGateway })
      .pipe(
        tap(() => {
          if (storageOptions.save)
            this.localDataSvc.set<T>(storageOptions.key, data);
        }),
        catchError((error) => throwError(error))
      );
  }

  private _searchAndGet<T>({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<T> {
    return this.localDataSvc.getObs<T>(storageOptions.key).pipe(
      concatMap((data) => {
        return iif(
          () => data !== undefined,
          of(data),
          this._get<T>({ segment, storageOptions, useGateway })
        );
      }),
      catchError((error) => throwError(error))
    );
  }

  private _searchAndGetMany<T>({
    segment,
    storageOptions,
    useGateway = false,
  }: {
    segment: string;
    storageOptions: { save: boolean; key: string };
    useGateway?: boolean;
  }): Observable<T[]> {
    return this.localDataSvc.getManyObs<T>(storageOptions.key).pipe(
      concatMap((data) => {
        return iif(
          () => data !== undefined,
          of(data),
          this._getMany<T>({ segment, storageOptions, useGateway })
        );
      }),
      catchError((error) => throwError(error))
    );
  }
}
