import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OrdersRank,
  ProductRank,
  Rank,
  RankWithCount,
} from '@core/models/analytics.model';
import { ApiFacadeService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _SEGMENT = 'ranks';
  private _KEY = '__ranks';

  private readonly query = new HttpParams()
    .set('offset', '0')
    .set('limit', '1000')
    .toString();

  constructor(private apiFacadeService: ApiFacadeService) {}

  getProductsRank(businessId: string, kind: string): Observable<ProductRank[]> {
    return this.apiFacadeService
      .get<ProductRank[]>({
        segment: `${this._SEGMENT}/${businessId}/products/${kind}?${this.query}`,
        storageOptions: {
          search: false,
          save: false,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((ranks: ProductRank[]) => ranks),
        catchError((error) => throwError(error))
      );
  }

  getAccesses(businessId: string): Observable<Rank[]> {
    return this.apiFacadeService
      .get<Rank[]>({
        segment: `${this._SEGMENT}/${businessId}/accesses?${this.query}`,
        storageOptions: {
          search: false,
          save: false,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((ranks: Rank[]) => ranks),
        catchError((error) => throwError(error))
      );
  }

  getOrders(businessId: string): Observable<RankWithCount[]> {
    return this.apiFacadeService
      .get<RankWithCount[]>({
        segment: `${this._SEGMENT}/${businessId}/orders/requested?${this.query}`,
        storageOptions: {
          search: false,
          save: false,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((ranks: RankWithCount[]) => ranks),
        catchError((error) => throwError(error))
      );
  }

  getOrdersPerDay(businessId: string): Observable<OrdersRank> {
    const stringDate = this.getTodayDate();
    // const stringDate = '2024-06-01';
    return this.apiFacadeService
      .get<OrdersRank>({
        segment: `${this._SEGMENT}/${businessId}/orders/requested/${stringDate}?${this.query}`,
        storageOptions: {
          search: false,
          save: false,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((ranks: OrdersRank) => ranks),
        catchError((error) => throwError(error))
      );
  }

  private getTodayDate() {
    let today = new Date();
    return this.formatDate(today);
  }

  private formatDate(date: Date) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
