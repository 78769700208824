import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerBirthday',
})
export class CustomerBirthdayPipe implements PipeTransform {
  transform(name: string): string {
    if (!name) return name;
    return name.split('T')[0].split('-').reverse().join('-');
  }
}
