<app-admin-conteiner>
  <section content>
    <app-admin-title *ngIf="breadcrumb"></app-admin-title>
    <div class="content-form__wrapper">
      <ng-container
        *ngIf="!loading; then profilePage; else profileLoading"
      ></ng-container>
    </div>
  </section>
</app-admin-conteiner>

<ng-template #profilePage>
  <ng-container
    *ngIf="errorMessage; then profileError; else profileForm"
  ></ng-container>
</ng-template>

<ng-template #profileError>
  <app-error-card [errorMesage]="errorMessage"></app-error-card>
</ng-template>

<ng-template #profileForm>
  <div class="row--width">
    <form [formGroup]="formGroup" (ngSubmit)="onSave()">
      <div class="row center-xs">
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline" disabled>
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
              name="email"
              type="email"
              disabled
              required
            />
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Nombres</mat-label>
            <input
              matInput
              formControlName="firstName"
              name="firstName"
              maxlength="30"
              placeholder="Mis Nombre(s)"
              #inputFirstName
              required
            />
            <mat-hint align="end"
              >{{ inputFirstName.value?.length || 0 }}/30</mat-hint
            >
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Apellidos</mat-label>
            <input
              matInput
              formControlName="lastName"
              name="lastName"
              maxlength="30"
              placeholder="Mis Apellidos"
              #inputLastName
              required
            />
            <mat-hint align="end"
              >{{ inputLastName.value?.length || 0 }}/30</mat-hint
            >
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input
              matInput
              formControlName="phone"
              type="tel"
              name="phone"
              placeholder="Mi teléfono"
              maxlength="10"
              #inputPhone
              required
            />
            <mat-hint align="end"
              >{{ inputPhone.value?.length || 0 }}/10</mat-hint
            >
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Plan</mat-label>
            <mat-select formControlName="tier" name="tier" required>
              <mat-option value="free">Mi primer negocio - ¡GRATIS!</mat-option>
              <mat-option value="plus" disabled
                >Negocio Plus - $199/mes</mat-option
              >
              <mat-option value="profesional" disabled
                >Negocio Profesional - $399/mes</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <button
            type="submit"
            class="form__button"
            mat-raised-button
            [disabled]="formGroup.invalid"
          >
            Actualizar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #profileLoading>
  <app-loading [message]="loadingMessage"></app-loading>
</ng-template>
