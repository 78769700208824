import { Injectable } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { format } from 'date-fns';

import { OrderSummary, ProductRank, RankWithCount } from '@core/models';
import { PieChartData } from 'app/admin/components/pie-chart/pie-chart.component';

export interface ChartData {
  dataSets: ChartDataSets[];
  labels: string[];
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  getOrdersData(ranks: RankWithCount[]): ChartData {
    const ranksCopy = [...ranks];
    this.sortByLapse(ranksCopy);

    const lapses: string[] = [];
    const totals: number[] = [];
    const counts: number[] = [];
    ranksCopy.forEach((rank) => {
      lapses.push(rank.lapse);
      totals.push(rank.total);
      counts.push(rank.totalOrders);
    });

    return {
      dataSets: [
        { data: totals, label: 'Suma total ($)' },
        { data: counts, label: 'Total de órdenes' },
      ],
      labels: lapses,
    };
  }

  getProductsData(ranks: ProductRank[]): PieChartData {
    const labels: string[] = [];
    const data: number[] = [];
    ranks.forEach((rank) => {
      labels.push(rank.name);
      data.push(rank.total);
    });
    return { data, labels };
  }

  getOrdersPerDayData(ranks: OrderSummary[]) {
    const ranksCopy = [...ranks];
    ranksCopy.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateA - dateB;
    });

    const lapses: string[] = [];
    const totals: number[] = [];
    ranksCopy.forEach((rank) => {
      lapses.push(format(new Date(rank.createdAt), 'yyyy-MM-dd HH:mm'));
      totals.push(rank.total);
    });
    return { lapses, totals };
  }

  private sortByLapse(ranks: RankWithCount[]) {
    ranks.sort((a, b) => {
      const dateA = new Date(a.lapse + '-01').getTime();
      const dateB = new Date(b.lapse + '-01').getTime();
      return dateA - dateB;
    });
  }
}
