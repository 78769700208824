import { Injectable } from '@angular/core';
import { ExtraService } from '@core/https/admin/extra.service';
import { FormResultExtra } from '@core/models/extra.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  ExtraAction,
  ExtraFailedAction,
  ExtraSuccessAction,
} from '../actions/extra.actions';

export class ExtraStateModel {
  public loading: boolean;
  public result: FormResultExtra;
}

const defaults = {
  loading: false,
  result: {
    success: false,
    error: false,
    message: null,
    extra: null,
  } as FormResultExtra,
};

@State<ExtraStateModel>({
  name: 'extra',
  defaults,
})
@Injectable()
export class ExtraState {
  private _successMessage = 'Tu extra fue guardado con éxito.';

  constructor(private extraSvc: ExtraService) {}

  @Selector()
  public static saving({ loading }) {
    return loading;
  }

  @Selector()
  public static result({ result }) {
    return result;
  }

  @Action(ExtraAction)
  save(ctx: StateContext<ExtraStateModel>, { extra }: ExtraAction) {
    this._saving(ctx);

    return this.extraSvc.save(extra).pipe(
      tap((newExtra) => {
        this.extraSvc.saveCurrent(extra.extraId);
        ctx.dispatch(new ExtraSuccessAction(newExtra));
      }),
      catchError((error) => {
        ctx.dispatch(new ExtraFailedAction(error));
        return of();
      })
    );
  }

  @Action(ExtraSuccessAction)
  saveSuccess(
    ctx: StateContext<ExtraStateModel>,
    { extra }: ExtraSuccessAction
  ) {
    const state = ctx.getState();

    const result: FormResultExtra = {
      error: false,
      success: true,
      message: this._successMessage,
      extra: extra,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  @Action(ExtraFailedAction)
  saveFailed(
    ctx: StateContext<ExtraStateModel>,
    { message }: ExtraFailedAction
  ) {
    const state = ctx.getState();

    const result: FormResultExtra = {
      error: true,
      success: false,
      message: message,
      extra: null,
    };

    ctx.patchState({
      ...state,
      loading: false,
      result: result,
    });
  }

  private _saving(ctx: StateContext<ExtraStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true, result: null });
  }
}
