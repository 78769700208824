import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  @Input() barChartLabels: string[] = [];
  @Input() barChartData: ChartDataSets[] = [];
  @Input() color: string = 'secondary';
  @Input() formatCurrency = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;

  private colorMap = {
    secondary: {
      background: 'rgb(80, 188, 133)',
      border: 'rgb(80, 188, 133)',
    },
    tertiary: {
      background: 'rgb(40,180,223)',
      border: 'rgb(40,180,223)',
    },
  };

  public barChartColors: Color[] = [];

  constructor() {}

  ngOnInit(): void {
    this.barChartColors = [
      {
        backgroundColor: this.colorMap[this.color].background,
        borderColor: this.colorMap[this.color].border,
        borderWidth: 1,
      },
    ];
    if (this.formatCurrency) {
      this.barChartOptions.scales.yAxes[0] = {
        ...this.barChartOptions.scales.yAxes[0],
        ticks: {
          callback: function (value: number) {
            return '$' + value.toLocaleString('es-MX');
          },
        },
      };
    }
  }
}
