import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Business } from '@core/models';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Select, Store } from '@ngxs/store';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { GetBusinessAction, GetBusinessState } from '@store/admin/business';
import { GetProfileAction, GetProfileState } from '@store/admin/profile';
import { LoginLinkStripe } from '@store/admin/stripe/actions/login-link-stripe.action';
import { StripeRelationship } from '@store/admin/stripe/actions/relationship-stripe.actions';
import { LoginLinkStripeState } from '@store/admin/stripe/states/login-link-stripe.state';
import { StripeRelationshipState } from '@store/admin/stripe/states/relationship-stripe.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent extends ParamsComponent {
  @Select(GetProfileState.getBusiness) profileBusiness$: Observable<Business>;
  @Select(GetBusinessState.getBusiness) business$: Observable<Business>;
  @Select(GetBusinessState.getStripeUserId)
  getStripeUserId$: Observable<string>;
  @Select(StripeRelationshipState.relationshipSuccess)
  relationshipSuccess$: Observable<boolean>;
  @Select(StripeRelationshipState.getMessage) getMessage$: Observable<string>;

  @Select(LoginLinkStripeState.getLink) getLink$: Observable<string>;
  @Select(LoginLinkStripeState.getMessage) getLinkMessage$: Observable<string>;

  notInitialized: boolean = true;
  business: Business;
  code: string;
  success: boolean = false;
  loading: boolean = false;
  inProgressMessage: string =
    'Estamos trabajando en sincronizar tu cuenta de Stripe con NotMenu. Por favor, espera un momento mientras completamos el proceso.';
  connectExpress: string;

  breadcrumb: Breadcrumb;

  loadingLink: boolean = false;
  linkInProgressMessage: string = 'Abriendo dashboard Stripe';
  urlLink: string;

  constructor(private store: Store, public activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  onInit(): void {
    this.store.dispatch(new GetProfileAction());
    this.profileBusiness$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        if (business) {
          this.store.dispatch(new GetBusinessAction(business.businessId, true));
        }
      });

    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business) {
        this.business = business;
        this.success = this.business?.stripeUserId ? true : false;
        this.notInitialized = false;
        if (!this.loading && !this.business?.stripeUserId && this.code) {
          this.loading = true;
          this.store.dispatch(
            new StripeRelationship.Add(this.business.businessId, this.code)
          );
        }
      }
    });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.code = params['code'];
      });

    this.relationshipSuccess$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((success) => {
        if (success) {
          this.success = success;
          this.loading = false;
        }
      });

    this.getMessage$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
      if (error) {
        this.loading = false;
        this.success = false;
        console.error(error);
      }
    });

    this.getLink$.pipe(takeUntil(this.unsubscribe$)).subscribe((url) => {
      if (url && this.loadingLink) {
        this.urlLink = url;
        this.loadingLink = false;
        this.openDashboard();
      }
    });

    this.getStripeUserId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stripeUserId) => {
        if (stripeUserId && this.loadingLink) {
          this.getLink(stripeUserId);
        }
      });

    this.getLinkMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        if (error) {
          console.error(error);
          this.loadingLink = false;
        }
      });

    this.breadcrumb = {
      name: 'Pagos',
      icon: 'payments',
      url: 'payment',
      position: 0,
    } as Breadcrumb;
    this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));
    this.connectExpress = environment.payment.stripe.connectExpress;
  }

  goToStripe() {
    window.location.href = this.connectExpress;
  }

  getLink(stripeUserId?: string) {
    this.loadingLink = true;
    const searchStripeUserId = this.business?.stripeUserId ?? stripeUserId;
    if (searchStripeUserId) {
      this.store.dispatch(new LoginLinkStripe.Get(searchStripeUserId));
    } else {
      this.store.dispatch(
        new GetBusinessAction(this.business.businessId, true)
      );
    }
  }

  private openDashboard() {
    window.open(this.urlLink, '_blank', 'noopener');
  }
}
