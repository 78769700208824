import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Message } from '@core/models';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Customer } from '@core/models/customer.model';
import { Select, Store } from '@ngxs/store';
import { CustomerActions } from '@store/admin/customer/customer.actions';
import { CustomerState } from '@store/admin/customer/customer.state';
import { MessageTemplateState } from '@store/admin/message-template/message-template.state';
import { BreadcrumbComponent } from 'app/admin/core/breadcrumb.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent extends BreadcrumbComponent {
  @Select(CustomerState.getCustomers) getCustomers$: Observable<Customer[]>;
  @Select(CustomerState.getErrorMessage) getErrorMessage$: Observable<string>;
  @Select(MessageTemplateState.getCurrentMessage)
  getCurrentMessage$: Observable<Message>;
  tabIndex: number = 0;
  customers: Customer[];
  dataSource = new MatTableDataSource<Customer>([]);
  displayedColumns = [
    'register',
    'name',
    'phone',
    'email',
    'birthday',
    'lastMonthTotal',
    'currentMonthTotal',
    'actions',
  ];
  currentMessage;
  loading = false;

  constructor(public route: ActivatedRoute, public store: Store) {
    super(route, store);
  }

  onInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params.index) this.tabIndex = params.index;
      });

    this.getCustomers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customers) => {
        if (!customers) {
          return;
        }
        this.customers = customers;
        this.dataSource.data = this.customers;
        this.loading = false;
      });

    this.getErrorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (!errorMessage) {
          return;
        }
        this.loading = false;
        console.error('errorMessage === >>>', errorMessage);
      });

    this.getCurrentMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentMessage) => {
        if (!currentMessage) {
          return;
        }
        this.currentMessage = currentMessage;
      });
    this.loading = true;
    this.store.dispatch(new CustomerActions.Get());
  }

  initializeBreadcrumb(): void {
    this.breadcrumb = {
      name: 'Clientes',
      icon: 'groups',
      url: 'customer',
      position: 0,
    } as Breadcrumb;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
