import { Profile } from '@core/models/profile.model';

export class SaveProfileAction {
  static readonly type = '[Profile]';
  constructor(public profile: Profile) {}
}

export class SaveProfileSuccessAction {
  static readonly type = '[Profile] Success';
  constructor(public profile: Profile) {}
}

export class SaveProfileFailAction {
  static readonly type = '[Profile] Fail';
  constructor(public message: string) {}
}
