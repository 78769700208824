import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Menu } from '@core/models/menu.model';
import { Select } from '@ngxs/store';
import { GetBusinessState } from '@store/admin/business/states/get-business.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent extends ParamsComponent {
  @Input() businessId: string;
  @Select(GetBusinessState.getMenus) menus$: Observable<Menu[]>;

  buttonUrl: string;
  menus: Menu[];

  constructor(public activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  onInit(): void {
    this.paramBusiness = this.businessId;

    this.buttonUrl = `b/${this.businessId}/menu`;
    this.menus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menus) => (this.menus = menus));
  }
}
