import { IDelete, Ids } from "@core/interfaces/delete.interface";

export class EraserAction {
  static readonly type = '[Eraser]';
  constructor(public implDelete: IDelete, public ids: Ids) {}
}

export class EraserSuccessAction {
  static readonly type = '[Eraser] Success';
  constructor(public response: boolean) {}
}

export class EraserFailedAction {
  static readonly type = '[Eraser] Failed';
  constructor(public message: string) {}
}

export class EraserClearAction {
    static readonly type = '[Eraser] Clear';
    constructor() {}
}