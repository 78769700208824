<h2 mat-dialog-title>Bienvenido a NotMenu</h2>
<div mat-dialog-content>
  <p>
    Comencemos por lo más importante, <b>Tú</b>, es indispensable que primero
    llenes tus datos de contacto personales antes de crear tu negocio.
  </p>
</div>
<div class="col-xs-12 col-md-offset-6 col-md-6 div_button__align">
  <button class="form__button button__start" (click)="closeDialog()">
    Comenzar
  </button>
</div>
