import { Injectable } from '@angular/core';
import { CategoryService } from '@core/https/admin/category.service';
import { Category } from '@core/models/category.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetCategoryAction,
  GetCategoryFailedAction,
  GetCategoryInitializedAction,
  GetCategorySuccessAction,
} from '../actions/get-category.actions';

export class GetCategoryStateModel {
  public loading: boolean;
  public category: Category;
  public message: string;
}

const defaults = {
  loading: false,
  category: null,
  message: null,
};

@State<GetCategoryStateModel>({
  name: 'getCategory',
  defaults,
})
@Injectable()
export class GetCategoryState {
  constructor(private categorySvc: CategoryService) {}

  @Selector()
  public static getProducts({ category }) {
    if (category.products && category.products.length > 0) {
      return category.products;
    }

    return null;
  }

  @Selector()
  public static getting({ loading }) {
    return loading;
  }

  @Selector()
  public static getCategory({ category }) {
    return category;
  }

  @Selector()
  public static getErrorMessage({ message }) {
    return message;
  }

  @Action(GetCategoryAction)
  get(
    ctx: StateContext<GetCategoryStateModel>,
    { categoryId }: GetCategoryAction
  ) {
    this._getting(ctx);

    return this.categorySvc.get(categoryId).pipe(
      tap((category) => {
        this.categorySvc.saveCurrent(categoryId);
        ctx.dispatch(new GetCategorySuccessAction(category));
      }),
      catchError((error) => {
        ctx.dispatch(new GetCategoryFailedAction(error.message));
        return of();
      })
    );
  }

  @Action(GetCategoryFailedAction)
  getFailed(
    ctx: StateContext<GetCategoryStateModel>,
    { message }: GetCategoryFailedAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      category: null,
      message: message,
    });
  }

  @Action(GetCategoryInitializedAction)
  initialize(ctx: StateContext<GetCategoryStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      category: null,
      message: null,
    });
  }

  @Action(GetCategorySuccessAction)
  getSuccess(
    ctx: StateContext<GetCategoryStateModel>,
    { category }: GetCategorySuccessAction
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      category: category,
    });
  }

  private _getting(ctx: StateContext<GetCategoryStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
