<div class="row">
  <div class="col-xs-12">
    <mat-card>
      <mat-card-header *ngIf="title">
        <mat-card-title *ngIf="title">{{ title }}</mat-card-title>
        <mat-card-subtitle>(Opcional)</mat-card-subtitle>
      </mat-card-header>
      <img
        mat-card-xl-image
        class="img__fit"
        [hidden]="loading"
        [src]="image"
        #img
      />
      <mat-card-content>
        <mat-spinner *ngIf="loading"></mat-spinner>
        <br /><br />
        <span
          >La imagen que selecciones se comprimirá para mejorar la velocidad de
          carga de tu menú.
        </span>
        <p *ngIf="isBanner">
          Te recomendamos usar el siguiente template. [<a
            href="../../../../assets/images/template-banner.png"
            download="template-banner.png"
            >Descargar</a
          >]
        </p>
      </mat-card-content>
      <mat-card-actions>
        <div class="upload-image__container-file">
          <input
            type="file"
            accept=".jpg,.png,.jpeg"
            appShowImage
            [imgElement]="img"
            [errorElement]="error"
            (loadFile)="loadFile($event)"
            (loading)="loadingImage($event)"
            #file
          />
          <a mat-raised-button color="primary" (click)="file.click()">
            <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;<span
              >Selecciona una imagen</span
            >
          </a>
        </div>
      </mat-card-actions>
      <span class="upload-image__error" #error></span>
    </mat-card>
  </div>
</div>
