<div class="content-form__wrapper">
    <ng-container *ngIf="!loading; then optionForm else optionLoading"></ng-container>
</div>

<ng-template #optionForm>
    <div class="row--width">
        <app-eraser
            buttonText="Eliminar opción"
            question="¿Estas seguro que quieres eliminar esta opción?"
            eraserType="OPTION"
            [show]="showEraserComponent"
            [implDelete]="optionSvc">
        </app-eraser>
        <form [formGroup]="formGroup" (submit)="onSave()">
            <div class="row center-xs">
                <div class="col-xs-12">
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" name="firstName" placeholder="Nombre de la opción" required>
                    </mat-form-field>
                    <mat-checkbox class="form__checkbox" formControlName="active" name="active" color="primary">Activo</mat-checkbox>
                    <mat-form-field class="form__field" appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input type="number" matInput formControlName="price" name="price" placeholder="Precio" required>
                        <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-xs-12">
                    <button type="submit" class="form__button" mat-raised-button
                        [disabled]="formGroup.invalid">Guardar</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #optionLoading>
    <app-loading message="Guardando..."></app-loading>
</ng-template>
