<section content1>
  <div class="content-form__wrapper">
    <ng-container
      *ngIf="!loading; then extraForm; else extraLoading"
    ></ng-container>
  </div>
</section>

<ng-template #extraForm>
  <div class="row--width">
    <app-eraser
      buttonText="Eliminar extra"
      question="¿Estas seguro que quieres eliminar este extra?"
      eraserType="EXTRA"
      [implDelete]="extraSvc"
      [show]="showEraserComponent"
    ></app-eraser>
    <form [formGroup]="formGroup" (submit)="onSave()">
      <div class="row center-xs">
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input
              matInput
              maxlength="30"
              formControlName="name"
              name="name"
              placeholder="Nombre del extra"
              #inputName
              required
            />
            <mat-hint align="end"
              >{{ inputName.value?.length || 0 }}/30</mat-hint
            >
          </mat-form-field>
          <mat-slide-toggle
            class="form__checkbox"
            formControlName="active"
            name="active"
            color="primary"
            >Activo</mat-slide-toggle
          >
          <mat-slide-toggle
            class="form__checkbox"
            formControlName="optional"
            name="optional"
            color="primary"
            >Opcional</mat-slide-toggle
          >
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Tipo de extra</mat-label>
            <mat-select [formControl]="extraTypeControl" name="type">
              <mat-option
                *ngFor="let extraType of extraTypes"
                [value]="extraType.id"
              >
                {{ extraType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="form__field"
            appearance="outline"
            *ngIf="extraTypeControl.value === 'MULTIPLE'"
          >
            <mat-label>Maximo de opciones a seleccionar</mat-label>
            <input
              type="number"
              matInput
              formControlName="max"
              name="max"
              min="2"
              placeholder="Máximo de opciones a seleccionar"
              [errorStateMatcher]="matcher"
            />
            <mat-hint
              >Es el número de opciones máximas que va a poder seleccionar de
              este extra.</mat-hint
            >
            <mat-error *ngIf="formGroup.controls['max'].hasError('min')">
              <span>
                Si deseas que máximo se pueda elegir 1 opción por favor utiliza
                el extra de tipo <b>"Única opción"</b>.
              </span>
            </mat-error>
          </mat-form-field>
          <br />
          <br />
          <button
            type="submit"
            class="form__button"
            mat-raised-button
            [disabled]="formGroup.invalid"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #extraLoading>
  <app-loading message="Guardando..."></app-loading>
</ng-template>
