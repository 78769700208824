import { Option } from '@core/models/option.model';

export class OptionAction {
  static readonly type = '[Option]';
  constructor(public option: Option) {}
}

export class OptionSuccessAction {
  static readonly type = '[Option] Success';
  constructor(public option: Option) {}
}

export class OptionFailedAction {
  static readonly type = '[Option] Failed';
  constructor(public message: string) {}
}