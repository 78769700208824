import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StateClear } from 'ngxs-reset-plugin';
import { SecurityService } from '@core/services/security.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-signed-up-dialog',
  templateUrl: './signed-up-dialog.component.html',
  styleUrls: ['./signed-up-dialog.component.scss'],
})
export class SignedUpDialogComponent {
  constructor(
    private securitySvc: SecurityService,
    private store: Store,
    public dialogRef: MatDialogRef<SignedUpDialogComponent>,
    private router: Router
  ) {}

  onGoToSignIn() {
    this.securitySvc.signOut().then(() => {
      this.store.dispatch(new StateClear());
      localStorage.clear();
      this.dialogRef.close();
      this.router.navigate(['auth/signin']);
    });
  }
}
