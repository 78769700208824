import { Profile } from '@core/models/profile.model';

export class GetProfileAction {
  static readonly type = '[GetProfile]';
  constructor() {}
}

export class GetProfileSuccessAction {
  static readonly type = '[GetProfile] Success';
  constructor(public profile: Profile) {}
}

export class GetProfileFailedAction {
  static readonly type = '[GetProfile] Failed';
  constructor(public message: string) {}
}
