<app-admin-conteiner>
  <section content>
    <app-admin-title title="Clientes" iconName="groups"></app-admin-title>
    <ng-container *ngIf="loading; then isLoading; else main"></ng-container>
    <ng-template #main>
      <div class="row main">
        <div class="col-xs-11">
          <div class="row">
            <div class="col-xs-12">
              <mat-form-field>
                <mat-label>Buscador de clientes</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <table
                mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="register">
                  <th mat-header-cell *matHeaderCellDef>Registro</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.register | date : "dd-MM-yyyy" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Nombre</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                  <th mat-header-cell *matHeaderCellDef>Teléfono</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.phone }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="birthday">
                  <th mat-header-cell *matHeaderCellDef>Cumpleaños</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.birthday | date : "dd-MM-yyyy" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastMonthTotal">
                  <th mat-header-cell *matHeaderCellDef>
                    Gasto total<br />mes anterior
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.lastMonthTotal | currency }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="currentMonthTotal">
                  <th mat-header-cell *matHeaderCellDef>
                    Gasto total<br />mes actual
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.currentMonthTotal | currency }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Acciones</th>
                  <td mat-cell *matCellDef="let element">
                    <button
                      mat-icon-button
                      class="cursor__help"
                      matTooltip="Próximamente"
                      color="accent"
                      disabled
                    >
                      Ver detalles
                    </button>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let element; columns: displayedColumns"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="8">
                    No data matching the filter "{{ input.value }}"
                  </td>
                </tr>
              </table>
              <br />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #isLoading>
      <app-loading message="Cargando clientes"></app-loading>
    </ng-template>
  </section>
</app-admin-conteiner>
