<section content1>
  <div class="content-form__wrapper">
    <ng-container
      *ngIf="!loading; then menuForm; else menuLoading"
    ></ng-container>
  </div>
  <br />
</section>

<ng-template #menuForm>
  <div class="row--width">
    <app-eraser
      buttonText="Eliminar menú"
      question="¿Estas seguro que quieres eliminar este menú?"
      eraserType="MENU"
      [implDelete]="menuSvc"
      [show]="showEraserComponent"
    >
    </app-eraser>
    <form [formGroup]="formGroup" (submit)="onSave()">
      <div class="row center-xs">
        <div class="col-xs-12">
          <div class="col-background">
            <app-upload-image
              [image]="menu ? menu.backgroundUrl : image"
              (loadImage)="onLoadImage($event)"
              title="Banner del menú"
              isBanner="true"
            >
            </app-upload-image>
          </div>
        </div>
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>Nombre del menú</mat-label>
            <input
              matInput
              maxlength="30"
              formControlName="name"
              name="name"
              placeholder="Nombre del menú (Nombre que aparecera en el banner)"
              #inputName
              required
            />
            <mat-hint align="end"
              >{{ inputName.value?.length || 0 }}/30</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-checkbox
            class="form__field"
            formControlName="showName"
            name="showName"
            color="primary"
            >Mostrar nombre (sobre el banner)</mat-checkbox
          >
        </div>
        <div class="col-xs-12">
          <mat-checkbox
            class="form__field"
            formControlName="active"
            name="active"
            color="primary"
            >Activo</mat-checkbox
          >
        </div>
        <div class="col-xs-12 menu-form__allows">
          <h4>Selecciona los tipos de pedidos que maneja tu negocio</h4>
          <mat-checkbox
            class="form__field"
            formControlName="allowDelivery"
            name="allowDelivery"
            color="primary"
            >Entregas a domicilio</mat-checkbox
          >
          <mat-slide-toggle
            class="form__field"
            formControlName="enableDeliveryFee"
            name="enableDeliveryFee"
            *ngIf="deliveryOptions"
            color="primary"
            >Agregar costo de envío fijo (opcional)</mat-slide-toggle
          >
          <mat-form-field
            class="form__field"
            appearance="outline"
            [ngStyle]="{ display: enableDeliveryFee ? 'inline-block' : 'none' }"
          >
            <mat-label>Costo de envío fijo *</mat-label>
            <input
              matInput
              type="number"
              formControlName="deliveryFee"
              name="deliveryFee"
              placeholder="Costo de envío fijo"
            />
          </mat-form-field>
          <mat-checkbox
            class="form__field"
            formControlName="allowPickUp"
            name="allowPickUp"
            color="primary"
          >
            <span>Pedidos para recoger</span>
          </mat-checkbox>
        </div>
        <div class="col-xs-12">
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>WhatsApp principal para pedidos *</mat-label>
            <input
              matInput
              type="tel"
              maxlength="10"
              formControlName="phoneForOrders"
              name="phoneForOrders"
              placeholder="WhatsApp"
              #inputPhone
            />
            <mat-hint align="end"
              >{{ inputPhone.value?.length || 0 }}/10</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <p style="text-align: center">
            Aquí puedes poner hasta 2 numeros auxiliares para recibir una copia
            de los pedidos, estos no podrán contestar al cliente pero sí
            recibiran una copia de todos los pedidos.
          </p>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>WhatsApp auxiliar 1 (opcional)</mat-label>
            <input
              matInput
              type="tel"
              maxlength="10"
              formControlName="phones1"
              name="phones1"
              placeholder="WhatsApp"
              #inputPhones1
            />
            <mat-hint align="end"
              >{{ inputPhones1.value?.length || 0 }}/10</mat-hint
            >
          </mat-form-field>
          <mat-form-field class="form__field" appearance="outline">
            <mat-label>WhatsApp auxiliar 2 (opcional)</mat-label>
            <input
              matInput
              type="tel"
              maxlength="10"
              formControlName="phones2"
              name="phones2"
              placeholder="WhatsApp"
              #inputPhones2
            />
            <mat-hint align="end"
              >{{ inputPhones2.value?.length || 0 }}/10</mat-hint
            >
          </mat-form-field>
        </div>
        <button
          type="submit"
          class="form__button"
          mat-raised-button
          [disabled]="formGroup.invalid"
        >
          Guardar
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #menuLoading>
  <app-loading message="Guardando..."></app-loading>
</ng-template>
