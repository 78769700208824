export interface Rank {
  lapse: string;
  total: number;
}

export interface ProductRank {
  productId: string;
  name: string;
  total: number;
}

export interface RankWithCount extends Rank {
  totalOrders: number;
}

export interface OrderProduct {
  name: string;
  price: number;
  quantity: number;
}

export interface GroupedOrderProduct {
  name: string;
  units: number;
  total: number;
}

export interface OrderSummary {
  orderId: string;
  createdAt: Date;
  customerName: string;
  customerPhone: string;
  total: number;
  products: OrderProduct[];
}

export interface OrdersRank extends RankWithCount {
  orders: OrderSummary[];
}

export class RankGraphData {
  lapses: string[];
  totals: number[];

  constructor() {
    this.lapses = [];
    this.totals = [];
  }
}

export class RankWithCountGraphData {
  lapses: string[];
  totals: number[];
  counts: number[];

  constructor() {
    this.lapses = [];
    this.totals = [];
    this.counts = [];
  }
}
