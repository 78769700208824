import { Customer } from '@core/models/customer.model';

export namespace CustomerActions {
  export class Get {
    static readonly type = '[Customer] Get';
    constructor() {}
  }
  export class Success {
    static readonly type = '[Customer] Success';
    constructor(public customers: Customer[]) {}
  }

  export class Failure {
    static readonly type = '[Customer] Failure';
    constructor(public message: string) {}
  }
}
