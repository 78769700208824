<app-admin-list 
    buttonText="Agregar categoría"
    [buttonUrl]="buttonUrl"
    description="Selecciona una de tus categorías para acceder a sus detalles."
    icon="category"
    [list]="categories | fromCategoriesToListItem: order: params"
    noDataText="Todavía no tienes categorías agregadas."
    [implSort]="menuService"
    [id]="paramMenu"
>
</app-admin-list>
