import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Store } from '@ngxs/store';
import { PushBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  loading: boolean;

  public breadcrumb: Breadcrumb;  
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.breadcrumb = {
      name: 'Contacto',
      icon: 'email',
      url: 'contact',
      position: 0
    } as Breadcrumb;
    this.store.dispatch(new PushBreadcrumbAction(this.breadcrumb));
  }

}
