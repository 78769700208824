import { Injectable } from '@angular/core';
import { StripeService } from '@core/https/admin/stripe.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoginLinkStripe } from '../actions/login-link-stripe.action';

export class LoginLinkStripeStateModel {
  public url: string;
  public message: string;
}

const defaults = {
  url: null,
  message: null,
};

@State<LoginLinkStripeStateModel>({
  name: 'loginLinkStripe',
  defaults,
})
@Injectable()
export class LoginLinkStripeState {
  constructor(private stripeService: StripeService) {}

  @Selector()
  public static getMessage({ message }): string {
    return message;
  }

  @Selector()
  public static getLink({ url }): boolean {
    return url;
  }

  @Action(LoginLinkStripe.Get)
  get(
    { dispatch }: StateContext<LoginLinkStripeStateModel>,
    { stripeUserId }: LoginLinkStripe.Get
  ) {
    return this.stripeService.getLoginLink(stripeUserId).pipe(
      tap((response) => dispatch(new LoginLinkStripe.Success(response))),
      catchError((error) => {
        dispatch(new LoginLinkStripe.Failure(error));
        return of();
      })
    );
  }

  @Action(LoginLinkStripe.Success)
  success(
    { getState, setState }: StateContext<LoginLinkStripeStateModel>,
    { url }: LoginLinkStripe.Success
  ) {
    const state = getState();
    setState({
      ...state,
      url,
      message: null,
    });
  }

  @Action(LoginLinkStripe.Failure)
  failure(
    { getState, setState }: StateContext<LoginLinkStripeStateModel>,
    { message }: LoginLinkStripe.Failure
  ) {
    const state = getState();
    setState({
      ...state,
      message,
      url: null,
    });
  }
}
