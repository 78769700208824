import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileItem } from '@core/models/file-item.model';
import { Profile } from '@core/models/profile.model';
import { finalize } from 'rxjs/operators';
import { LocalDataService } from './local-data.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public mediaStoragePath: string;

  constructor(
    private readonly storage: AngularFireStorage,
    private localDataSvc: LocalDataService
  ) {}

  uploadFile(fileItem: FileItem) {
    fileItem.uploading = true;

    const filePath = this.generateFileName(fileItem.name);
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, fileItem.file);
    fileItem.uploadPercent = task.percentageChanges();

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            fileItem.downloadUrl.next(url);
            fileItem.downloadUrl.complete();
          });

          fileItem.uploading = false;
        })
      )
      .subscribe();
  }

  uploadFiles(files: FileItem[]) {
    for (const fileItem of files) {
      this.uploadFile(fileItem);
    }
  }

  getPath(path?: { segment: string }): string {
    const profile: Profile = this.localDataSvc.get(`__p`);

    if (path)
      return `${profile.businesses[0].businessId}/images/${path.segment}/`;

    return `${profile.businesses[0].businessId}/images/`;
  }

  private generateFileName(name: string): string {
    return `${this.mediaStoragePath}/${name}`;
  }
}
