import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  OrdersRank,
  ProductRank,
  Rank,
  RankWithCount,
} from '@core/models/analytics.model';
import { AnalyticsService } from '@core/https/admin/analytics.service';
import { BusinessService } from '@core/https/admin/business.service';
import { AnalyticsActions } from '../actions/analytics.actions';

export class AnalyticsStateModel {
  public accesses: Rank[];
  public orders: RankWithCount[];
  public productsAdded: ProductRank[];
  public productsViewed: ProductRank[];
  public productsRequested: ProductRank[];
  public ordersPerDay: OrdersRank;
  public message: string;
}

const defaults = {
  accesses: null,
  orders: null,
  productsAdded: null,
  productsViewed: null,
  productsRequested: null,
  ordersPerDay: null,
  message: null,
};

@State<AnalyticsStateModel>({
  name: 'analytics',
  defaults,
})
@Injectable()
export class AnalyticsState {
  constructor(
    private analyticsService: AnalyticsService,
    private businessService: BusinessService
  ) {}

  @Selector()
  public static getData({
    accesses,
    orders,
    productsAdded,
    productsViewed,
    productsRequested,
    ordersPerDay,
  }) {
    return {
      accesses,
      orders,
      productsAdded,
      productsViewed,
      productsRequested,
      ordersPerDay,
    };
  }

  @Action(AnalyticsActions.GetData)
  getData({ dispatch }: StateContext<AnalyticsStateModel>) {
    const businessId = this.businessService.getCurrent();
    return forkJoin([
      this.analyticsService.getAccesses(businessId),
      this.analyticsService.getOrders(businessId),
      this.analyticsService.getProductsRank(businessId, 'added'),
      this.analyticsService.getProductsRank(businessId, 'viewed'),
      this.analyticsService.getProductsRank(businessId, 'requested'),
      this.analyticsService.getOrdersPerDay(businessId).pipe(
        catchError((error: unknown) => {
          if (typeof error === 'string' && error.includes('not found')) {
            return of(null);
          }
          return throwError(error);
        })
      ),
    ]).pipe(
      tap(
        ([
          accesses,
          orders,
          productsAdded,
          productsViewed,
          productsRequested,
          ordersPerDay,
        ]) => {
          dispatch(
            new AnalyticsActions.SuccessGetData(
              accesses,
              orders,
              productsAdded,
              productsViewed,
              productsRequested,
              ordersPerDay
            )
          );
        }
      ),
      catchError((error) => {
        dispatch(new AnalyticsActions.FailureGetData(error.message));
        return of();
      })
    );
  }

  @Action(AnalyticsActions.SuccessGetData)
  getDataSuccess(
    ctx: StateContext<AnalyticsStateModel>,
    {
      accesses,
      orders,
      productsAdded,
      productsViewed,
      productsRequested,
      ordersPerDay,
    }: AnalyticsActions.SuccessGetData
  ) {
    ctx.patchState({
      accesses,
      orders,
      productsAdded,
      productsViewed,
      productsRequested,
      ordersPerDay,
      message: null,
    });
  }

  @Action(AnalyticsActions.FailureGetData)
  getDataFailure(
    ctx: StateContext<AnalyticsStateModel>,
    { message }: AnalyticsActions.FailureGetData
  ) {
    ctx.patchState({
      orders: null,
      accesses: null,
      productsAdded: null,
      productsViewed: null,
      productsRequested: null,
      ordersPerDay: null,
      message: message,
    });
  }
}
