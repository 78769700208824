<app-admin-conteiner>
  <section content>
    <app-admin-title *ngIf="breadcrumb"></app-admin-title>
    <ng-container
      *ngIf="!loading; then extraPage; else extraLoading"
    ></ng-container>
  </section>
</app-admin-conteiner>

<ng-template #extraPage>
  <ng-container *ngIf="showTabs; then extraTabs; else extraForm"></ng-container>
</ng-template>

<ng-template #extraTabs>
  <app-admin-tabs [titles]="titles" [tabIndex]="tabIndex">
    <div content1>
      <app-extra-form [businessId]="businessId"></app-extra-form>
    </div>
    <div content2>
      <app-option-list></app-option-list>
    </div>
  </app-admin-tabs>
</ng-template>

<ng-template #extraForm>
  <app-extra-form [businessId]="businessId"></app-extra-form>
</ng-template>

<ng-template #extraLoading>
  <app-loading message="Cargando..."></app-loading>
</ng-template>
