import { Message } from '@core/models';
import { MessageSend } from '@core/models/message-send';

export namespace MessageTemplateActions {
  export class Get {
    static readonly type = '[MessageTemplate] Get';
    constructor() {}
  }
  export class GetSuccess {
    static readonly type = '[MessageTemplate] GetSuccess';
    constructor(public messages: Message[]) {}
  }
  export class GetFailure {
    static readonly type = '[MessageTemplate] GetFailure';
    constructor(public message: string) {}
  }

  export class Send {
    static readonly type = '[MessageTemplate] Send';
    constructor(public message: MessageSend) {}
  }
  export class SendSuccess {
    static readonly type = '[MessageTemplate] SendSuccess';
    constructor(public message: MessageSend) {}
  }
  export class SendFailure {
    static readonly type = '[MessageTemplate] SendFailure';
    constructor(public message: string) {}
  }

  export class Save {
    static readonly type = '[MessageTemplate] Save';
    constructor(public message: Message) {}
  }
  export class SaveSuccess {
    static readonly type = '[MessageTemplate] SaveSuccess';
    constructor(public message: Message) {}
  }
  export class SaveFailure {
    static readonly type = '[MessageTemplate] SaveFailure';
    constructor(public message: string) {}
  }
  export class SaveInit {
    static readonly type = '[MessageTemplate] SaveInit';
    constructor() {}
  }

  export class SetCurrentMessage {
    static readonly type = '[MessageTemplate] SetCurrentMessage';
    constructor(public message: Message) {}
  }
}
