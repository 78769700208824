import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '@core/https/admin/product.service';
import { FileItem } from '@core/models/file-item.model';
import { FormResultProduct, Product } from '@core/models/product.model';
import { Promotion } from '@core/models/promotion.model';
import { StorageService } from '@core/services/storage.service';
import { DayType } from '@core/types/day.type';
import { Select, Store } from '@ngxs/store';
import { GetCategoryAction } from '@store/admin/category/actions/get-category.actions';
import { ProductAction } from '@store/admin/product/actions/product.actions';
import { GetProductState } from '@store/admin/product/states/get-product.state';
import { ProductState } from '@store/admin/product/states/product.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent extends ParamsComponent {
  @Input() categoryId: string;
  @Select(GetProductState.getErrorMessage) errorMessage$: Observable<string>;
  @Select(GetProductState.getting) getting$: Observable<boolean>;
  @Select(GetProductState.getProduct) product$: Observable<Product>;
  @Select(ProductState.result) result$: Observable<FormResultProduct>;
  @Select(ProductState.saving) saving$: Observable<boolean>;

  private sent: boolean = false;

  discountDays = [
    { id: 'MONDAY', name: 'Lunes' },
    { id: 'TUESDAY', name: 'Martes' },
    { id: 'WEDNESDAY', name: 'Miercoles' },
    { id: 'THURSDAY', name: 'Jueves' },
    { id: 'FRIDAY', name: 'Viernes' },
    { id: 'SATURDAY', name: 'Sabado' },
    { id: 'SUNDAY', name: 'Domingo' },
  ];
  discountDaysControl: FormControl;
  discountTypes = [
    { id: 'PERCENTAGE', name: 'Porcentaje' },
    { id: 'PRICE', name: 'Nuevo Precio' },
  ];
  discountTypesControl: FormControl;
  errorMessage: string;
  file: File;
  formGroup: FormGroup;
  getting: boolean = false;
  image: string = 'assets/images/no-product-image.png';
  loading: boolean = false;
  loadingMessage: string = 'Cargando...';
  product: Product;
  promotionTypes = [
    { id: 'ALWAYS', name: 'Siempre Activa' },
    { id: 'DATE', name: 'Fecha de Vigencia' },
    { id: 'DAYS', name: 'Por Días' },
  ];
  promotionTypesControl: FormControl;
  saving: boolean = false;
  showEraserComponent: boolean = false;
  uploadImageFirstTime: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store,
    private storageSvc: StorageService,
    public activatedRoute: ActivatedRoute,
    public productSvc: ProductService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    if (this.paramProduct) this.showEraserComponent = true;

    this.product$.pipe(takeUntil(this.unsubscribe$)).subscribe((product) => {
      if (product) {
        this.product = product;
      }

      this.createForm();
    });

    this.errorMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errorMessage) => {
        if (errorMessage) {
          this.errorMessage = errorMessage;
        }
      });

    this.getting$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.saving && !this.uploadImageFirstTime) {
        this.loading = loading;
        this.getting = loading;
        if (loading) this.loadingMessage = 'Cargando...';
      }
    });

    this.result$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });

        this.sent = false;
      } else if (result.success && this.sent) {
        this.product = { ...result.product };

        if (this.uploadImageFirstTime) {
          this._uploadAndSave(this.product);
          return;
        }

        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });

        this.sent = false;

        this.store.dispatch(new GetCategoryAction(this.categoryId));
        this.createForm();
        this.router.navigate(
          [
            `admin/b/${this.paramBusiness}/m/${this.paramMenu}/c/${this.categoryId}/p/${this.product.productId}`,
          ],
          { replaceUrl: true }
        );
      }
    });

    this.saving$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      if (!this.getting && !this.uploadImageFirstTime) {
        this._showSaving(loading);
      }
    });
  }

  onLoadImage(file: File) {
    this.file = file;
  }

  onSave(): void {
    if (this.formGroup.invalid) return;

    const product: Product = this.getProductFromForm();
    if (product.productId && this.file) {
      this._uploadAndSave(product);
    } else {
      if (this.file && !product.productId && !product.urlImage) {
        this.uploadImageFirstTime = true;
        this._showSaving(true);
      }
      this._save(product);
    }
  }

  private createForm(): void {
    let promotion;
    if (this.product) {
      if (Array.isArray(this.product.promotion)) {
        promotion = this.product.promotion[0];
      } else {
        promotion = this.product.promotion;
      }
    }
    this.promotionTypesControl = new FormControl(
      this.product
        ? promotion?.type ?? this.promotionTypes[0].id
        : this.promotionTypes[0].id
    );
    this.discountDaysControl = new FormControl(
      this.product ? promotion?.days ?? [] : []
    );
    this.discountTypesControl = new FormControl(
      this.product
        ? promotion?.discountType ?? this.discountTypes[0].id
        : this.discountTypes[0].id
    );

    this.formGroup = this.formBuilder.group({
      active: [this.product ? this.product.active : true],
      name: [
        this.product ? this.product.name : '',
        [Validators.required, Validators.minLength(2)],
      ],
      code: [this.product ? this.product.code : '', [Validators.required]],
      description: [this.product ? this.product.description : ''],
      price: [
        this.product ? this.product.price : 0,
        [Validators.required, Validators.min(0)],
      ],
      discount: [this.product ? this.product.discount : false],
      promotionType: this.promotionTypes,
      dateStart: [this.product ? promotion?.dateStart ?? '' : ''],
      dateEnd: [this.product ? promotion?.dateEnd ?? '' : ''],
      days: this.discountDaysControl,
      discountType: this.discountTypesControl,
      discountValue: [
        this.product ? promotion?.discountValue : 0,
        [Validators.min(0)],
      ],
    });
  }

  private getProductFromForm(): Product {
    const product: Product = {
      ...this.product,
      active: this.formGroup.get('active').value,
      code: this.formGroup.get('code').value,
      description: this.formGroup.get('description').value,
      categoryId: this.categoryId,
      name: this.formGroup.get('name').value,
      price: this.formGroup.get('price').value,
      urlImage: this.product?.urlImage ? this.product.urlImage : undefined,
      discount: this.formGroup.get('discount').value,
      promotion: this.product?.promotion
        ? Array.isArray(this.product.promotion)
          ? { ...this.product.promotion[0] }
          : { ...this.product.promotion }
        : undefined,
    } as Product;

    if (product.discount) {
      if (!product.promotion) {
        product.promotion = new Promotion();
      }
      if (!product.promotion.promotionId) {
        product.promotion.promotionId = uuidv4();
      }
      product.promotion.active = product.discount;
      product.promotion.type = this.promotionTypesControl.value;
      product.promotion.discountType = this.discountTypesControl.value;

      if (product.promotion.type === 'DATE') {
        product.promotion.dateStart = this.formGroup.get('dateStart').value;
        product.promotion.dateEnd = this.formGroup.get('dateEnd').value;
      }

      if (product.promotion.type === 'DAYS') {
        product.promotion.days = <DayType[]>this.discountDaysControl.value;
      }

      if (product.promotion.discountType === 'PERCENTAGE') {
        let percentage: number = this.formGroup.get('discountValue').value;
        if (percentage > 100) percentage = 100;

        product.promotion.discountValue = percentage;
      } else {
        product.promotion.discountValue =
          this.formGroup.get('discountValue').value;
      }
    }

    return product;
  }

  private _uploadAndSave(product: Product): void {
    this.loading = true;
    this.loadingMessage = 'Subiendo Imagen...';

    const fileItem: FileItem = new FileItem(this.file);
    fileItem.name = `${product.productId}.${this.file.name.split('.').pop()}`;

    this.storageSvc.mediaStoragePath = this.storageSvc.getPath({
      segment: 'products',
    });
    this.storageSvc.uploadFile(fileItem);

    fileItem.downloadUrl
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((urlImage) => {
        if (urlImage) {
          product.urlImage = urlImage;
          this._save(product);
          this.uploadImageFirstTime = false;
        }
      });
  }

  private _save(product: Product): void {
    this.sent = true;
    this.store.dispatch(new ProductAction(product));
  }

  private _showSaving(loading: boolean) {
    this.loading = loading;
    this.saving = loading;
    if (loading) this.loadingMessage = 'Guardando...';
  }
}
