<div class="admin-list__wrapper">
    <div class="row">
        <div class="col-xs-6 admin-list__column-add-button" *ngIf="buttonText">
            <button mat-fab color="primary" aria-label="Order" id="add-button" (click)="onAdd()">
                <mat-icon>add_circle</mat-icon>
            </button>
            &nbsp;<h3><label for="add-button" class="pointer">{{ buttonText }}</label></h3>
        </div>
        <div class="col-xs-{{ buttonText ? '6' : '12'}} admin-list__column-sort-button" *ngIf="implSort">
            <button mat-fab color="primary" aria-label="Sort" id="sort-button" (click)="onOpenSortDialog()">
                <mat-icon>sort</mat-icon>
            </button>
            &nbsp;<h3><label for="sort-button" class="pointer">{{ buttonSortText }}</label></h3>
        </div>
        <div class="col-xs-12" *ngIf="list">
            <span *ngIf="description" class="admin-list__description">{{ description }}<br><br></span>
            <mat-selection-list multiple="false">
                <mat-list-option *ngFor="let item of list" (click)="onGoTo(item.url)" class="{{(item.active === undefined) || item.active  ? '' : 'deactivated'}}" >
                    <mat-icon mat-list-icon>{{ icon }}</mat-icon>
                    <div mat-line><span>{{ item.text }}{{(item.active === undefined) || item.active ? '' : ' • desactivado'}}</span></div>
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div class="col-xs-12" *ngIf="!list">
            <span class="admin-list__description">{{ noDataText }}<br><br></span>
        </div>
    </div>
</div>

