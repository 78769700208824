import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '@core/https/admin/product.service';
import { Business } from '@core/models/business.model';
import {
  AddExtraDto,
  Extra,
  FormResultAddExtra,
} from '@core/models/extra.model';
import { Product } from '@core/models/product.model';
import { Select, Store } from '@ngxs/store';
import { GetBusinessAction } from '@store/admin/business/actions/get-business.actions';
import { GetBusinessState } from '@store/admin/business/states/get-business.state';
import { AddExtraAction } from '@store/admin/product/actions/add-extra.actions';
import { AddExtraState } from '@store/admin/product/states/add-extra.state';
import { GetProductState } from '@store/admin/product/states/get-product.state';
import { GetProfileAction } from '@store/admin/profile/actions/get-profile.actions';
import { GetProfileState } from '@store/admin/profile/states/get-profile.state';
import { ParamsComponent } from 'app/admin/core/params.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss'],
})
export class ExtrasComponent extends ParamsComponent {
  @Select(GetProfileState.getBusiness) business$: Observable<Business>;
  @Select(GetProductState.getProduct) product$: Observable<Product>;
  @Select(GetBusinessState.getExtras) extras$: Observable<Extra[]>;
  @Select(AddExtraState.loadInProgress)
  addExtraLoadInProgress$: Observable<boolean>;
  @Select(AddExtraState.getResult) addExtra$: Observable<FormResultAddExtra>;

  extraControl: FormControl;
  businessId: string;
  productId: string;
  extras: Extra[];
  formGroup: FormGroup;
  loading: boolean;
  extrasAvailable: Extra[];
  sent: boolean;
  hasChanges: boolean;
  sort: string[];

  constructor(
    public activatedRoute: ActivatedRoute,
    private store: Store,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public productService: ProductService
  ) {
    super(activatedRoute);
  }

  onInit(): void {
    this.store.dispatch(new GetProfileAction());

    this.product$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((product: Product) => {
        if (!product) return;
        this.extras = product.extras;
        this.productId = product.productId;
        this.sort = product.sort;
        this._createForm();
      });

    this.business$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business: Business) => {
        if (!business || !business.businessId) return;
        this.businessId = business.businessId;
        this.store.dispatch(new GetBusinessAction(this.businessId));
      });

    this.extras$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((extras: Extra[]) => {
        if (!extras) return;
        this.extrasAvailable = [...extras].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });

    this.addExtraLoadInProgress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.addExtra$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (!result) return;

      if (result.error && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
      } else if (result.success && this.sent) {
        this.snackBar.open(result.message, 'X', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });
        this.extras = result.product.extras;
        this.sort = result.product.sort;
        this._createForm();
      }
      this.sent = false;
    });
  }

  onSave(): void {
    if (!this.hasChanges) return;
    this.sent = true;
    this.store.dispatch(new AddExtraAction(this._getAddExtraDto()));
  }

  private _createForm(): void {
    this.extraControl = new FormControl(this._getIdsFromExtras());
    this.formGroup = this.formBuilder.group({
      extras: this.extraControl,
    });
    this._subscribeValueChanges();
  }

  private _getAddExtraDto(): AddExtraDto {
    const formExtras: string[] = this.formGroup.get('extras').value;
    // Add to sort the ids added
    let sortedIds = this.sort ? [...this.sort] : [];
    formExtras.forEach((i) => {
      if (!sortedIds.includes(i)) {
        sortedIds.push(i);
      }
    });
    // filter only the ids selected in the form
    sortedIds = sortedIds.filter((i) => formExtras.includes(i));
    const extras: Extra[] = sortedIds.map((v) =>
      this.extrasAvailable.find((e) => e.extraId === v)
    );

    return {
      productId: this.productId,
      extras: extras,
      menuId: null,
    };
  }

  private _isValid(form: string[]): boolean {
    const current = this._getIdsFromExtras();
    this.hasChanges =
      current.length !== form.length || !current.every((v) => form.includes(v));
    return this.hasChanges;
  }

  private _subscribeValueChanges(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((input) => {
        const form = input.extras ?? [];
        this._isValid(form);
      });
  }

  private _getIdsFromExtras(): string[] {
    if (!this.extras || this.extras.length < 1) return [];
    return this.extras.map((v) => v.extraId);
  }
}
