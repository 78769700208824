import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Select, Store } from '@ngxs/store';
import { PopBreadcrumbAction } from '@store/admin/breadcrumb/actions/breadcrumb.actions';
import { BreadcrumbState } from '@store/admin/breadcrumb/states/breadcrumb.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-title',
  templateUrl: './admin-title.component.html',
  styleUrls: ['./admin-title.component.scss'],
})
export class AdminTitleComponent implements OnInit {
  @Select(BreadcrumbState.getAll) breadcrumbs$: Observable<Breadcrumb[]>;
  @Select(BreadcrumbState.get) breadcrumb$: Observable<Breadcrumb>;

  public breadcrumbs: Breadcrumb[];
  public currentBreadcrumb: Breadcrumb;

  constructor(private router: Router, private store: Store) {}

  ngOnInit() {
    this.breadcrumb$.subscribe((currentBreadcrumb) => {
      if (currentBreadcrumb) {
        this.currentBreadcrumb = currentBreadcrumb;
      }
    });

    this.breadcrumbs$.subscribe((breadcrumbs) => {
      if (breadcrumbs) {
        this.breadcrumbs = breadcrumbs;
      }
    });
  }

  onGo(url: string, position: number): void {
    this.store.dispatch(new PopBreadcrumbAction(position));
    this.router.navigate([`admin/${url}`]);
  }
}
