<mat-tab-group #tabGroup [selectedIndex]="tabIndex">
  <mat-tab label="{{ titles[0] }}">
    <div class="tab__wrapper">
      <ng-content select="[content1]"></ng-content>
    </div>
  </mat-tab>
  <mat-tab label="{{ titles[1] }}">
    <div class="tab__wrapper">
      <ng-content select="[content2]"></ng-content>
    </div>
  </mat-tab>
  <mat-tab *ngIf="titles.length > 2" label="{{ titles[2] }}">
    <div class="tab__wrapper">
      <ng-content select="[content3]"></ng-content>
    </div>
  </mat-tab>
</mat-tab-group>
