import { Injectable } from '@angular/core';
import { SecurityService } from '@core/services/security.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  SignUp,
  SignUpFailed,
  SignUpSuccess,
} from '../actions/sign-up.actions';

export class SignUpStateModel {
  public loading: boolean;
  public success: boolean;
  public error: boolean;
  public message: string;
}

const defaults = {
  loading: false,
  success: false,
  error: false,
  message: null,
};

@State<SignUpStateModel>({
  name: 'signUp',
  defaults,
})
@Injectable()
export class SignUpState {
  constructor(private securitySvc: SecurityService) {}

  @Selector()
  public static signingUp({ loading }) {
    return loading;
  }

  @Selector()
  public static signedUp({ success }) {
    return success;
  }

  @Selector()
  public static signUpError({ message }) {
    return message;
  }

  @Action(SignUp)
  signUp(ctx: StateContext<SignUpStateModel>, { email, password }: SignUp) {
    this._signingUp(ctx);

    this.securitySvc
      .signUp(email, password)
      .then(async () => {
        ctx.dispatch(new SignUpSuccess());
      })
      .catch((error) => {
        ctx.dispatch(new SignUpFailed(error));
      });
  }

  @Action(SignUpSuccess)
  signUpSuccess(ctx: StateContext<SignUpStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      loading: false,
      success: true,
      error: false,
      message: null,
    });
  }

  @Action(SignUpFailed)
  signUpFailed(ctx: StateContext<SignUpStateModel>, { message }: SignUpFailed) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      loading: false,
      success: false,
      error: true,
      message: message,
    });
  }

  private _signingUp(ctx: StateContext<SignUpStateModel>) {
    const state = ctx.getState();

    ctx.patchState({ ...state, loading: true });
  }
}
