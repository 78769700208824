import { Injectable } from '@angular/core';
import { StripeService } from '@core/https/admin/stripe.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StripeRelationship } from '../actions/relationship-stripe.actions';

export class StripeRelationshipStateModel {
  public success: boolean;
  public message: string;
}

const defaults = {
  success: null,
  message: null,
};

@State<StripeRelationshipStateModel>({
  name: 'stripe',
  defaults,
})
@Injectable()
export class StripeRelationshipState {
  constructor(private stripeService: StripeService) {}

  @Selector()
  public static getMessage({ message }): string {
    return message;
  }

  @Selector()
  public static relationshipSuccess({ success }): boolean {
    return success;
  }

  @Action(StripeRelationship.Add)
  add(
    { dispatch }: StateContext<StripeRelationshipStateModel>,
    { businessId, stripeCode }: StripeRelationship.Add
  ) {
    return this.stripeService
      .addStripeCodeAndBusinessIdRelationship({
        businessId,
        stripeCode,
      })
      .pipe(
        tap((response) => dispatch(new StripeRelationship.Success(response))),
        catchError((error) => {
          dispatch(new StripeRelationship.Failure(error));
          return of();
        })
      );
  }

  @Action(StripeRelationship.Success)
  success(
    { getState, setState }: StateContext<StripeRelationshipStateModel>,
    { success }: StripeRelationship.Success
  ) {
    const state = getState();
    setState({
      ...state,
      success,
      message: null,
    });
  }

  @Action(StripeRelationship.Failure)
  failure(
    { getState, setState }: StateContext<StripeRelationshipStateModel>,
    { message }: StripeRelationship.Failure
  ) {
    const state = getState();
    setState({
      ...state,
      message,
      success: false,
    });
  }
}
