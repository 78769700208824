import { Injectable } from '@angular/core';
import { Business, Extra, Menu, Waiter } from '@core/models';
import {
  ApiFacadeService,
  CallApiService,
  LocalDataService,
} from '@core/services';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mapTo, tap } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private _SEGMENT = 'businesses';
  private _KEY = '__b';
  private _CURRENT_BUSINESS = '__bcurrent';

  constructor(
    private afsvc: ApiFacadeService,
    private callApiSvc: CallApiService,
    private ldsvc: LocalDataService,
    private profileSvc: ProfileService
  ) {}

  // Base methods

  get(businessId: string, force: boolean = false): Observable<Business> {
    return this.afsvc
      .get<Business>({
        segment: `${this._SEGMENT}/${businessId}`,
        storageOptions: {
          search: !force,
          save: true,
          key: `${this._KEY}${businessId}`,
        },
        useGateway: true,
      })
      .pipe(
        concatMap((_business) => {
          return iif(
            () => _business && _business.active,
            of(_business),
            this.afsvc
              .get<Business>({
                segment: `${this._SEGMENT}/${businessId}`,
                storageOptions: {
                  search: false,
                  save: true,
                  key: `${this._KEY}${businessId}`,
                },
                useGateway: true,
              })
              .pipe(
                tap((_businessActived) =>
                  this.profileSvc.updateBusinessToProfile(_businessActived)
                )
              )
          );
        }),
        map((_business: Business) => _business),
        catchError((error) => throwError(error))
      );
  }

  save(business: Business): Observable<Business> {
    if (business.businessId) {
      return this.update(business);
    } else {
      return this.create(business);
    }
  }

  // Public methods

  addExtraToBusiness(extra: Extra): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${extra.businessId}`
    );
    if (!business.extras) {
      business.extras = [];
    }
    business.extras.push(extra);
    this.ldsvc.set<Business>(`${this._KEY}${extra.businessId}`, business);
  }

  addMenuToBusiness(menu: Menu): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${menu.businessId}`
    );
    if (!business.menus) {
      business.menus = [menu];
    } else {
      business.menus.push(menu);
    }
    this.ldsvc.set<Business>(`${this._KEY}${menu.businessId}`, business);
  }

  addUserToBusiness(waiter: Waiter): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${waiter.idBusiness}`
    );
    business.users.push(waiter);
    this.ldsvc.set<Business>(`${this._KEY}${waiter.idBusiness}`, business);
  }

  removeExtraFromBusiness(businessId: string, extraId: string): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${businessId}`
    );
    const extras: Extra[] = business.extras.filter(
      (e) => e.extraId !== extraId
    );
    business.extras = [...extras];
    this.ldsvc.set<Business>(`${this._KEY}${businessId}`, business);
  }

  removeMenuFromBusiness(businessId: string, menuId: string): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${businessId}`
    );
    const menus: Menu[] = business.menus.filter((m) => m.menuId !== menuId);
    business.menus = [...menus];
    this.ldsvc.set<Business>(`${this._KEY}${businessId}`, business);
  }

  updateExtraInBusiness(extra: Extra): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${extra.businessId}`
    );
    const extras: Extra[] = business.extras.filter(
      (e) => e.extraId !== extra.extraId
    );
    business.extras = [...extras, extra];
    this.ldsvc.set<Business>(`${this._KEY}${extra.businessId}`, business);
  }

  updateOptionsSortInBusiness(extraId: string, sort: string[]): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${this.getCurrent()}`
    );
    const extraToUpdate: Extra = business.extras.find(
      (e) => e.extraId === extraId
    );
    extraToUpdate.sort = sort;
    const extras: Extra[] = business.extras.filter(
      (e) => e.extraId !== extraId
    );
    business.extras = [...extras, extraToUpdate];
    this.ldsvc.set<Business>(`${this._KEY}${this.getCurrent()}`, business);
  }

  updateMenuInBusiness(menu: Menu): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${menu.businessId}`
    );
    const menus: Menu[] = business.menus.filter(
      (m) => m.menuId !== menu.menuId
    );
    business.menus = [...menus, menu];
    this.ldsvc.set<Business>(`${this._KEY}${menu.businessId}`, business);
  }

  updateUserInBusiness(waiter: Waiter): void {
    const business: Business = this.ldsvc.get<Business>(
      `${this._KEY}${waiter.idBusiness}`
    );
    const users: Waiter[] = business.users.filter(
      (u) => u.idUser !== waiter.idUser
    );
    business.users = [...users, waiter];
    this.ldsvc.set<Business>(`${this._KEY}${waiter.idBusiness}`, business);
  }

  // Private methods
  private create(business: Business): Observable<Business> {
    business.businessId = uuidv4();
    business.active = true;
    return this.callApiSvc
      .post<Business>({
        urlSegment: this._SEGMENT,
        data: business,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.ldsvc.set<Business>(
            `${this._KEY}${business.businessId}`,
            business
          );
          this.profileSvc.addBusinessToProfile(business);
        }),
        mapTo(business),
        catchError((error) => throwError(error))
      );
  }

  private update(business: Business): Observable<Business> {
    return this.callApiSvc
      .put<Business>({
        urlSegment: `${this._SEGMENT}/${business.businessId}`,
        data: business,
        useGateway: true,
      })
      .pipe(
        tap(() => {
          this.ldsvc.set<Business>(
            `${this._KEY}${business.businessId}`,
            business
          );
          this.profileSvc.updateBusinessToProfile(business);
        }),
        mapTo(business),
        catchError((error) => throwError(error))
      );
  }

  saveCurrent = (businessId: string): void =>
    this.ldsvc.set(this._CURRENT_BUSINESS, businessId);
  getCurrent = (): string =>
    this.ldsvc.get<string>(this._CURRENT_BUSINESS) ??
    this.profileSvc.getFirstBusiness()?.businessId;
}
