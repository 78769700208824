import { Injectable } from '@angular/core';
import { Message } from '@core/models';
import { MessageSend } from '@core/models/message-send';
import { ApiFacadeService } from '@core/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private _SEGMENT = 'messages';
  private _KEY = '__messages';

  constructor(private apiFacadeService: ApiFacadeService) {}

  getMessages(businessId: string): Observable<Message[]> {
    return this.apiFacadeService
      .get<Message[]>({
        segment: `${this._SEGMENT}/${businessId}`,
        storageOptions: {
          search: false,
          save: false,
          key: this._KEY,
        },
        useGateway: true,
      })
      .pipe(
        map((messages: Message[]) => messages),
        catchError((error) => throwError(error))
      );
  }

  saveMessage(message: Message, businessId: string): Observable<Message> {
    return this.apiFacadeService
      .post<Message>({
        segment: `${this._SEGMENT}`,
        useGateway: true,
        data: { ...message, businessId } as any,
      })
      .pipe(
        map(() => message),
        catchError(() =>
          throwError('Sucedio un error al tratar de guardar el mensaje.')
        )
      );
  }

  sendMessage(message: MessageSend): Observable<MessageSend> {
    return this.apiFacadeService
      .post<MessageSend>({
        segment: `${this._SEGMENT}/customers`,
        useGateway: true,
        data: message,
      })
      .pipe(
        map(() => message),
        catchError(() => throwError('Error trying to send message'))
      );
  }
}
