import { Injectable } from '@angular/core';
import { Breadcrumb } from '@core/models/breadcrumb.model';
import { Stack } from '@core/types/stack';
import { LocalDataService } from './local-data.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private BREADCRUMB_KEY = '_b__';
  private stack: Stack<Breadcrumb> = new Stack<Breadcrumb>();

  constructor(private localDataSvc: LocalDataService) {}

  public pushAndGet(breadcrumb: Breadcrumb): Breadcrumb[] {
    if (this.stack.isEmpty()) {
      this.fill();
    }

    if (!this.exists(breadcrumb)) {
      this.stack.push(breadcrumb);
      this.localDataSvc.setMany<Breadcrumb>(
        this.BREADCRUMB_KEY,
        this.stack.get()
      );
    }

    return this.stack.get();
  }

  public pop(position: number): Breadcrumb {
    if (this.stack.isEmpty()) {
      this.fill();
    }

    const breadcrumbs: Breadcrumb[] = [...this.get()].reverse();
    for (const breadcrumb of breadcrumbs) {
      if (breadcrumb.position !== position) {
        this.stack.pop();
      } else {
        this.stack.pop();
        break;
      }
    }

    this.localDataSvc.setMany<Breadcrumb>(
      this.BREADCRUMB_KEY,
      this.stack.get()
    );
    return this.peek();
  }

  public peek(): Breadcrumb {
    if (this.stack.isEmpty()) {
      this.fill();
    }

    return this.stack.peek();
  }

  public clear(path: string): boolean {
    let breadcrumbs: Breadcrumb[] = this.get();

    this.stack.clear();
    this.localDataSvc.remove(this.BREADCRUMB_KEY);
    if (!breadcrumbs.some((b) => b.position === 0 && b.url === path)) {
      return true;
    } 

    this.stack.push(breadcrumbs[0]);
    this.localDataSvc.setMany<Breadcrumb>(
      this.BREADCRUMB_KEY,
      this.stack.get()
    );

    return false;
  }

  public get(): Breadcrumb[] {
    if (this.stack.isEmpty()) {
      this.fill();
    }

    return this.stack.get();
  }

  private fill(): void {
    let breadcrumbs: Breadcrumb[] = [];
    if (this.localDataSvc.containsKey(this.BREADCRUMB_KEY)) {
      breadcrumbs = this.localDataSvc.getMany<Breadcrumb>(this.BREADCRUMB_KEY);
      breadcrumbs.forEach((b) => this.stack.push(b));
    }
  }

  private exists(breadcrumb: Breadcrumb): boolean {
    return this.stack
      .get()
      .some(
        (b) => b.position === breadcrumb.position && b.name === breadcrumb.name
      );
  }
}
