import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IDelete, Ids } from '@core/interfaces/delete.interface';
import {
  IdsCategory,
  IdsExtra,
  IdsMenu,
  IdsOption,
  IdsProduct,
} from '@core/models';
import { EraserType } from '@core/types/eraser.type';
import { ParamsComponent } from 'app/admin/core/params.component';
import { takeUntil } from 'rxjs/operators';
import { EraserDialogComponent } from '../eraser-dialog/eraser-dialog.component';

@Component({
  selector: 'app-eraser',
  templateUrl: './eraser.component.html',
  styleUrls: ['./eraser.component.scss'],
})
export class EraserComponent extends ParamsComponent {
  @Input() buttonText: string;
  @Input() question: string = '';
  @Input() eraserType: EraserType = 'OPTION';
  @Input() implDelete: IDelete;
  @Input() show: boolean = false;

  tabIndex: number = 1;
  ids: Ids;

  constructor(public dialog: MatDialog, public activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  onInit() {
    let index: number;
    if (this.eraserType === 'OPTION') {
      this.ids = {
        extraId: this.paramExtra,
        optionId: this.paramOption,
      } as IdsOption;
    } else if (this.eraserType === 'EXTRA') {
      this.ids = {
        businessId: this.paramBusiness,
        extraId: this.paramExtra,
      } as IdsExtra;

      if (this.paramProduct) {
        index = this.url.indexOf('/e/');
        this.url = this.url.substring(0, index);
      } else {
        this.url = 'business';
        this.tabIndex = 2;
      }
    } else if (this.eraserType === 'PRODUCT') {
      this.ids = {
        categoryId: this.paramCategory,
        menuId: this.paramMenu,
        productId: this.paramProduct,
      } as IdsProduct;

      index = this.url.indexOf('/p/');
      this.url = this.url.substring(0, index);
    } else if (this.eraserType === 'CATEGORY') {
      this.ids = {
        categoryId: this.paramCategory,
        menuId: this.paramMenu,
      } as IdsCategory;

      index = this.url.indexOf('/c/');
      this.url = this.url.substring(0, index);
    } else if (this.eraserType == 'MENU') {
      this.ids = {
        businessId: this.paramBusiness,
        menuId: this.paramMenu,
      } as IdsMenu;

      this.url = '/business';
    } else {
      throw new Error('No se asignó un EraserType');
    }
  }

  onOpenDeleteDialog(): void {
    const dialogRef = this.dialog.open(EraserDialogComponent, {
      data: {
        implDelete: this.implDelete,
        ids: this.ids,
        question: this.question,
        url: this.url,
        tabIndex: this.tabIndex,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {});
  }
}
