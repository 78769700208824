import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupedOrderProduct, OrderProduct } from '@core/models';

@Component({
  selector: 'app-products-dialog',
  templateUrl: './products-dialog.component.html',
  styleUrls: ['./products-dialog.component.scss'],
})
export class ProductsDialogComponent implements OnInit {
  displayedColumns: string[] = ['name', 'quantity', 'price'];
  total: number = 0;
  tableData: GroupedOrderProduct[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public products: OrderProduct[],
    public dialogRef: MatDialogRef<ProductsDialogComponent>
  ) {}

  ngOnInit(): void {
    const { grouped, total } = this.groupProducts();
    this.total = total;
    this.tableData = this.groupedToArray(grouped);
  }

  private groupProducts() {
    const grouped: Record<string, GroupedOrderProduct> = {};
    let total = 0;
    this.products.forEach((product) => {
      const name = product.name.toLowerCase();
      total += product.price;
      if (grouped[name]) {
        grouped[name].total += product.price;
        grouped[name].units += product.quantity;
      } else {
        grouped[name] = {
          total: product.price,
          units: product.quantity,
          name: product.name,
        };
      }
    });
    return { grouped, total };
  }

  private groupedToArray(grouped: Record<string, GroupedOrderProduct>) {
    return Object.values(grouped);
  }
}
