import { Business } from '@core/models/business.model';

export class SaveBusinessAction {
  static readonly type = '[Business]';
  constructor(public business: Business) {}
}

export class SaveBusinessSuccessAction {
  static readonly type = '[Business] Success';
  constructor(public business: Business) {}
}

export class SaveBusinessFailAction {
  static readonly type = '[Business] Fail';
  constructor(public message: string) {}
}
