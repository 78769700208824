import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-admin-tabs',
  templateUrl: './admin-tabs.component.html',
  styleUrls: ['./admin-tabs.component.scss'],
})
export class AdminTabsComponent {
  @Input() titles: string[];
  @Input() tabIndex: number = 0;
}
