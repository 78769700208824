<div class="row--width">
    <mat-card>
        <mat-card-header>
          <mat-card-title>Error</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{ errorMesage }}
          </p>
        </mat-card-content>
    </mat-card>
</div>
